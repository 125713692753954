export const convertBlob = async (strUrl) => {
  const url = new URL(strUrl);
  // リクエストに付与するヘッダー設定
  const headers = new Headers();
  // リクエストオブジェクト作成
  const request = new Request(url, {
    method: 'GET',
    headers: headers,
  });
  try {
    return await (await fetch(request)).blob();
  } catch(error) {
    console.log("error", error);
    // CORSエラーが出る場合があるため、UUIDを指定して再リクエスト
    const uuid = crypto.randomUUID();
    url.searchParams.append('uuid', uuid);
    const retryRequest = new Request(url, {
      method: 'GET',
      headers: headers,
    })
    return await (await fetch(retryRequest)).blob();
  }
};

export const Mixin = {
  methods: {
    convertBlob,
  }
};