<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: File,
  },
  data() {
    return {
      advertisementName: null,
      advertisementUrl: null,
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.advertisementUrl = URL.createObjectURL(value);
        }
      },
      deep: true
    }
  },
  async created() {
    if (this.value) {
      this.advertisementUrl = URL.createObjectURL(this.value);
    }
  },
  methods: {
    open() {
      this.$refs.inputFile.click();
    },
    resetPicture() {
      this.advertisementName = null;
      this.advertisementUrl = null;
      this.$emit('input', null);
    },
    async readImageFile(inputFile) {
      return new Promise((resolve, reject) =>{
        // ファイルを読み込む
        const fileReader = new FileReader();
        fileReader.onload = function(event) {
          // 読み込んだファイルデータをデータURIに変換
          const dataURI = event.target.result;
          resolve(dataURI);
        };
        fileReader.onerror = function(error) {
          reject(error);
        };
        // ファイルを読み込み開始
        fileReader.readAsDataURL(inputFile);
        console.log("処理後",fileReader);

        this.$emit('input', inputFile);
        this.advertisementUrl = URL.createObjectURL(inputFile);
      });
    },
    handleFileChange(e) {
      if (e.target.files.length != 0) {
        const inputFile = e.target.files[0];
        this.$emit('input', inputFile);
        this.advertisementName = inputFile.name;
        this.advertisementUrl = URL.createObjectURL(inputFile);
        // 下記一行を入れないと、同じファイルを選択することが出来ない不具合がある
        e.target.value = "";
      }
    },
  },
})
</script>

<template>
  <div>
    <v-container class="imageContainer">
      <v-row>
        <v-col>
          <v-img
            class="resizableImg"
            :src="advertisementUrl ? advertisementUrl : require('@/assets/noimage.png')"
            contain
          />
          <input
            type="file"
            id="file"
            ref="inputFile"
            accept="image/png, image/jpeg"
            style="display: none"
            @change="handleFileChange"
          />
          <v-btn
            v-if="advertisementUrl"
            class="imgButtonDelete"
            elevation="0"
            color="error darken-2"
            fab
            x-small
            tile
            @click="resetPicture"
          >
            <div style="font-size: 14px;">×</div>
          </v-btn>
          <v-btn
            class="imgButtonCreate"
            elevation="0"
            fab
            x-small
            @click="open"
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.imageContainer {
  position: relative;
  border: 1.5px;
  border-style: solid;
  border-color: rgb(10, 30, 10);
  margin: 0px;
  padding: 0px;
  z-index: 0;
}
.resizableImg {
  position: relative;
  max-width: 100%;
}
.imgButtonDelete {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(35%, 35%);
  z-index: 1;
}
.imgButtonCreate {
  background-image: url('@/assets/plus.png');
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>