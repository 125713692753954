<script>
import { defineComponent } from "vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import CustomInputPdf from "./CustomInputPdf.vue";
import CustomInputImage from "./CustomInputImage.vue";
import LoadingCircle from "../LoadingCircle.vue";
import {
  convertBlob,
} from '@/mixins/imageHelper.js';
import {
  GET_LINK_SETTING,
  PUT_LINK_SETTING,
} from "@/store/action-types";
import {
} from "@/store/mutation-types";

export default defineComponent({
  components: {
    CustomInputPdf,
    CustomInputImage,
    LoadingCircle
  },
  data() {
    return {
      gridCols: {
        label: 1,
        input: 11,
        textLabel: 12,
        picture: 2
      },

      // URL設定名称リスト
      urlSettingNameList: {
        serviceGuideUrl:{
          name:"サービス内容説明書URL",
          id:"service_guide_url",
          url:"https://www.ognp.co.jp/userfiles/files/document_04_20230930.pdf"
        },
        supplyClauseUrl:{
          name:"供給約款URL",
          id:"supply_clause_url",
          url:"https://www.ognp.co.jp/userfiles/files/document_02_20230601.pdf"
        },
        personalInformationHandlingUrl:{
          name:"個人情報の取り扱いについてURL",
          id:"personal_information_handling_url",
          url:"https://ognp.co.jp/privacy/"
        },
        consignmentClauseProvisionUrl:{
          name:"託送約款規定における需要者に関する事項URL",
          id:"consignment_clause_provision_url",
          url:"https://www.okiden.co.jp/business-support/service/consignment/download/index.html"
        },
        setDiscountImportantMatterUrl:{
          name:"セット割の重要説明事項URL",
          id:"set_discount_important_matter_url",
          url:"https://entry.okinawagas.co.jp/assets/pdf/set_juyojikou.pdf"
        },
      },

      // リンク設定画面
      advertisementList: [],

      isLoadingResourceSetting: false,
      isSavingResourceSetting: false,
    }
  },
  computed: {
    ...mapState({
      linkSetting: (state) => state.linkSetting,
    }),
    ...mapGetters({
    }),
    isLoading() {
      return (
        this.isLoadingResourceSetting
      );
    },
    isSaving() {
      return (
        this.isSavingResourceSetting
      );
    }
  },
  async created() {
    this.isLoadingResourceSetting = true;
    await this.getLinkSetting();
    await this.setCurrentURL();
    await this.setCurrentImages();
    this.isLoadingResourceSetting = false;
  },
  methods: {
    ...mapActions({
      getLinkSetting: GET_LINK_SETTING,
      putLinkSetting: PUT_LINK_SETTING,
    }),
    ...mapMutations({
    }),
    reset() {
      // URL入力欄のリセット
      for (const ln of this.$refs.urlRef) {
        ln.resetURL();
      }

      // 広告画像のリセット
      let adList = [];
      for (const ad of this.$refs.adRef) {
        ad.resetPicture();
      }
      for (let i = 0; i < 5; i++) {
        const image = {
          imgName: null,
          image: null,
        }
        adList.push(image);
      }
      this.advertisementList = adList;
    },
    async save() {
      this.isSavingResourceSetting = true;

      const backURLList = {};
      const advertisementURIList = [];
      // URLサービスを保管する
      backURLList.serviceGuideUrl = this.$refs.urlRef[0].pdfUrl;
      backURLList.supplyClauseUrl = this.$refs.urlRef[1].pdfUrl;
      backURLList.personalInformationHandlingUrl = this.$refs.urlRef[2].pdfUrl;
      backURLList.consignmentClauseProvisionUrl = this.$refs.urlRef[3].pdfUrl;
      backURLList.setDiscountImportantMatterUrl = this.$refs.urlRef[4].pdfUrl;
      // URLサービスをフロント側に適用する
      this.urlSettingNameList.serviceGuideUrl.url = this.$refs.urlRef[0].pdfUrl;
      this.urlSettingNameList.supplyClauseUrl.url = this.$refs.urlRef[1].pdfUrl;
      this.urlSettingNameList.personalInformationHandlingUrl.url = this.$refs.urlRef[2].pdfUrl;
      this.urlSettingNameList.consignmentClauseProvisionUrl.url = this.$refs.urlRef[3].pdfUrl;
      this.urlSettingNameList.setDiscountImportantMatterUrl.url = this.$refs.urlRef[4].pdfUrl;
      // 広告画像のリストをdataURIに変換する
      for (let i = 0; i < this.advertisementList.length ; i++) {
        const dat = this.advertisementList[i];
        const name = `slide${i}.png`;
        if (dat.image !== null) {
          const baseDat = await this.toBase64DataUri(dat.image);
          advertisementURIList.push({
            imgName: name,
            image: baseDat,
          });
        } else {
          advertisementURIList.push({
            imgName: null,
            image: null,
          });
        }
      }
      await this.putLinkSetting({
        applicationType: "link_setting",
        // URLリスト：
        backURLList: backURLList,
        // 広告画像リスト:
        advertisementList: advertisementURIList,
      });
      this.isSavingResourceSetting = false;
    },
    async toBase64DataUri(inputFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(inputFile);

        reader.onerror = function(error) {
          reject(error);
        };
      })
    },
    async setCurrentURL() {
      const bcLink = this.linkSetting
      const fnLink = this.urlSettingNameList
      if (bcLink.service_guide_url !== "") {
        fnLink.serviceGuideUrl.url = bcLink.service_guide_url;
      }
      if (bcLink.supply_clause_url !== "") {
        fnLink.supplyClauseUrl.url = bcLink.supply_clause_url;
      }
      if (bcLink.personal_information_handling_url !== "") {
        fnLink.personalInformationHandlingUrl.url = bcLink.personal_information_handling_url;
      }
      if (bcLink.consignment_clause_provision_url !== "") {
        fnLink.consignmentClauseProvisionUrl.url = bcLink.consignment_clause_provision_url
      }
      if (bcLink.set_discount_important_matter_url !== "") {
        fnLink.setDiscountImportantMatterUrl.url = bcLink.set_discount_important_matter_url
      }
    },
    async setCurrentImages() {
      // 広告画像の情報を取得
      const imageInfos = this.linkSetting.ad_images;
      if (imageInfos) {
        for await (const imageInfo of imageInfos) {
          if (imageInfo) {
            // タイムスタンプをクエリパラメータとして追加し、キャッシュさせないようにする
            const timestamp = Date.now();
            const blobImage = await convertBlob(imageInfo.s3_url + '?time=' + timestamp);
            // File型に変換する
            const file = new File([blobImage], imageInfo.file_name);
            const image = {
              imgName: imageInfo.file_name,
              image: file,
            };
            this.advertisementList.push(image);
          }
        }
      }
      // 画像は最大5箇所分用意する
      for (let i = 0; i < 5; i++) {
        if (this.advertisementList.length < 5) {
          const image = {
            imgName: null,
            image: null,
          }
          this.advertisementList.push(image);
        }
      }
    },
  }
})
</script>

<template>
  <div v-if="isLoading">
    <v-container height="600px" class="pt-16">
      <LoadingCircle message="loading">
      </LoadingCircle>
    </v-container>
  </div>
  <div v-else-if="isSaving">
    <v-container height="600px" class="pt-16">
      <LoadingCircle message="saving">
      </LoadingCircle>
    </v-container>
  </div>
  <div v-else>
    <v-container>
      <div v-for="(lin, index) in urlSettingNameList" :key="index">
        <v-row>
          <v-col>
            <div class="blue-grey--text font-weight-bold">
              {{ lin.name }}
            </div>
          </v-col>
        </v-row>
        <v-row class="align-center" dense>
          <v-col :cols="gridCols.textLabel">
            <CustomInputPdf
              v-model="lin.url"
              :id="lin.id"
              ref="urlRef"
            />
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <div class="blue-grey--text font-weight-bold">
            広告画像
          </div>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col :cols="gridCols.label">
          <div class="blue-grey--text">
            画像
          </div>
        </v-col>
        <v-col 
          v-for="(ad, index) in advertisementList" :key="index"
          :cols="gridCols.picture"
          class="imgCol">
          <CustomInputImage
            v-model="ad.image"
            ref="adRef"
          />
        </v-col>
      </v-row>

      <v-row class="justify-end mt-8">
        <v-col cols="auto">
          <v-btn
            class="mr-2"
            elevation="0"
            outlined
            color="let(--app-color)"
            @click="reset"
          >
            <div style="font-size: 14px;">リセット</div>
          </v-btn>
          <v-btn
            elevation="0"
            dark
            color="let(--app-color)"
            @click="save"
          >
            <div style="font-size: 14px;">保存</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.imgCol{
  margin: 0px;
  padding: 0px;
}
</style>