<script>
import { defineComponent } from "vue";
import CustomInputNumber from "./CustomInputNumber.vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CustomInputNumber,
    CustomDatePicker
  },
  props: {
    applicationType: String,
    value: Boolean,
    acceptableRangeSettingDefault: Object,
  },
  data() {
    return {
      gridCols: {
        label: 3,
        content: 9,
        datePicker: 3
      },
      acceptableRangeSettingLocal: {
        acceptable_range_day_count: 1,
        acceptable_range_month_count: 1,
        acceptable_range_partial_setting: []
      },
      partialSettingDefalut: {
        "date": this.convertDateToYYYYMMDD(new Date()), // 今日の日付
        "day_count": 1
      },
      isValid: false,
      // 個別設定のdateの最小日
      // カレンダーの選択可否制限に使用
      minPrtSetDate: () => {
        // 現在の日付以降しか選択できない
        return this.getToday();
      },
      // 個別設定のday_countの最小日
      // カレンダーの選択可否制限に使用
      minPrtSetdayCount: (prtSetDate) => {
        // 個別設定のdateの1日後以降しか選択できない
        return this.convertDateToYYYYMMDD(this.calcAddDate(prtSetDate, 1));
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },
    acceptableRangeDayCount: {
      get() {
        return this.acceptableRangeSettingLocal.acceptable_range_day_count;
      },
      set(value) {
        this.acceptableRangeSettingLocal.acceptable_range_day_count = value;
      }
    },
    acceptableRangeMonthCount: {
      get() {
        return this.acceptableRangeSettingLocal.acceptable_range_month_count;
      },
      set(value) {
        this.acceptableRangeSettingLocal.acceptable_range_month_count = value;
      }
    },
    acceptableRangePartialSetting: {
      get() {
        return this.acceptableRangeSettingLocal.acceptable_range_partial_setting;
      },
      set(value) {
        this.acceptableRangeSettingLocal.acceptable_range_partial_setting = value;
      }
    },

    // 個別設定のday_countのバリデーション
    // 表示の際に日付形式に変換して表示するため、rulesによるバリデーション相当の実装が別途必要
    isValidDayCount() {
      let isValid = true;

      for (const prtSet of this.acceptableRangePartialSetting) {
        const dayCount = prtSet.day_count;
        const dayCountIsValid = (
          dayCount // dayCountは存在する
          && this.isNumber(dayCount) // dayCountは整数
          && (dayCount > 0) // dayCountは正の数値
        );
        isValid = isValid && dayCountIsValid;
      }

      return isValid;
    },
  },
  watch: {
    acceptableRangeSettingDefault: {
      handler(value) {
        if (value && (typeof value === 'object') && Object.keys(value).length > 0) {
          this.acceptableRangeSettingLocal = structuredClone(value);
        }
      },
      deep: true
    }
  },
  methods: {
    convertDayCount(date, day_count) {
      // day_countをdate_add_day_countに変換
      const date_add_day_count = this.convertDateToYYYYMMDD(this.calcAddDate(date, day_count));

      return date_add_day_count;
    },
    reverseConvertDayCount(date, date_add_day_count) {
      // date_add_day_countをday_countに変換
      const day_count = this.calcDiffDate(new Date(date), new Date(date_add_day_count));

      return day_count;
    },
    addPartialSetting() {
      const add = structuredClone(this.partialSettingDefalut);

      // 日付を重複させない
      let addDate = add.date;
      while (this.acceptableRangePartialSetting.some((p) => p.date === addDate)) {
        // 日付をインクリメント
        const date = new Date(addDate);
        date.setDate(date.getDate() + 1);
        addDate = this.convertDateToYYYYMMDD(date);
      }
      add.date = addDate;

      this.acceptableRangePartialSetting.push(add);
      this.$forceUpdate();
    },
    deletePartialSetting(partialSetting) {
      this.acceptableRangePartialSetting = this.acceptableRangePartialSetting.filter((p) => p.date !== partialSetting.date);
    },
    close() {
      this.show = false;
    },
    save() {
      this.$emit('save', this.acceptableRangeSettingLocal);
      this.close();
    }
  }
});
</script>

<template>
  <v-dialog v-if="show" v-model="show" width="1000">
    <v-card>
      <v-system-bar color="var(--app-color)" dark height="5"/>
      <v-toolbar flat>
        <v-toolbar-title>{{ convertApplicationTypeToText(applicationType) }}受付可能範囲の設定</v-toolbar-title>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-6">
        <v-form v-model="isValid">
          <v-container>
            <v-row class="align-start">
              <v-col :cols="gridCols.label">
                <div class="body-1">受付可能範囲（標準）</div>
              </v-col>
              <v-col :cols="gridCols.content">
                <v-container>
                  <v-row class="align-center">
                    <v-col cols="auto" class="mr-2 pa-0">
                      <div>申し込み日の</div>
                    </v-col>
                    <v-col cols="auto" class="mr-2 pa-0">
                      <CustomInputNumber
                        v-model="acceptableRangeDayCount"
                        :rules="[
                          REQUIRED,
                          IS_POSITIVE_NUMBER
                        ]"
                        style="width: 48px; max-height: 24px; min-height: 24px;"
                      />
                    </v-col>
                    <v-col cols="auto" class="mr-2 pa-0">
                      <div>日後から</div>
                    </v-col>
                    <v-col cols="auto" class="mr-2 pa-0">
                      <CustomInputNumber
                        v-model="acceptableRangeMonthCount"
                        :rules="[
                          REQUIRED,
                          IS_POSITIVE_NUMBER
                        ]"
                        style="width: 48px; max-height: 24px; min-height: 24px;"
                      />
                    </v-col>
                    <v-col cols="auto" class="mr-2 pa-0">
                      <div>ヶ月間を表示する</div>
                    </v-col>
                  </v-row>
                  <v-row class="justify-start">
                    <v-col cols="auto" class="mt-2 pa-0" style="margin-left: 214px;">
                      <span class="red--text">
                        ※.個別設定にも適用されます
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <v-row>
              <v-col :cols="gridCols.label">
                <div class="body-1">受付可能範囲（個別）</div>
              </v-col>
              <v-col :cols="gridCols.content">
                <v-container v-if="acceptableRangePartialSetting.length > 0">
                  <v-row v-for="(partialSetting, idx) in acceptableRangePartialSetting" :key="partialSetting.date" class="pb-4">
                    <v-col>
                      <v-row class="align-center">
                        <v-col :cols="gridCols.datePicker" class="d-flex align-center mr-2 pa-0">
                          <div class="mr-2">
                            {{ idx + 1 }}.
                          </div>
                          <CustomDatePicker
                            v-model="partialSetting.date"
                            :rules="[
                              REQUIRED
                            ]"
                            :min="minPrtSetDate()"
                          />
                        </v-col>
                        <v-col cols="auto" class="mr-2 pa-0">
                          <div>に</div>
                        </v-col>
                        <v-col cols="auto" class="mr-2 pa-0">
                          <CustomDatePicker
                            :value="convertDayCount(partialSetting.date, partialSetting.day_count)"
                            @input="(v) => partialSetting.day_count = reverseConvertDayCount(partialSetting.date, v)"
                            :rules="[
                              REQUIRED
                            ]"
                            :min="minPrtSetdayCount(partialSetting.date)"
                          />
                        </v-col>
                        <v-col cols="auto" class="mr-2 pa-0">
                          <div>
                            までを非表示
                          </div>
                        </v-col>
                        <v-col cols="auto" class="mr-2 pa-0">
                          <v-btn
                            elevation="0"
                            color="error darken-2"
                            @click="deletePartialSetting(partialSetting)"
                          >
                            削除
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn
                  text
                  color="var(--app-color)"
                  class="pa-0"
                  @click="addPartialSetting"
                >
                  <v-icon>mdi-plus</v-icon>
                  追加
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider/>
      <v-card-actions class="px-3 py-6">
        <v-row>
          <v-col>
            <v-btn
              width="100%"
              elevation="1"
              color="var(--app-color)"
              class="white--text"
              :disabled="(!isValid || !isValidDayCount)"
              @click="save"
            >
              保存
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              width="100%"
              elevation="1"
              color="grey lighten-2"
              @click="close"
            >
              閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
::v-deep .v-input input {
  text-align: center;
}
::v-deep .v-text-field--outlined fieldset {
  left: 2px;
}
::v-deep .v-text-field .v-input__control {
  height: 24px;
}
::v-deep .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 0;
}
</style>