<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    title: String,
    required: Boolean,
    rules: Array
  },
  computed: {
    input: {
      get() {
        if (this.value) {
          return this.value;
        } else {
          return "";
        }
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
});
</script>

<template>
  <v-container class="mt-3">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-text-field
          v-model="input"
          :rules="rules"
          dense
          color="var(--app-color)"
          class="pa-0"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
