<script>
import { defineComponent } from "vue";
import SendVerifyCode from "./SendVerifyCode.vue";
import ResetPassword from "./ResetPassword.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
} from "@/store/action-types";
export default defineComponent({
  components: {
    SendVerifyCode,
    ResetPassword
  },
  data() {
    return {
      // 画面
      tab: 'send-verify-code',

      // 認証コードのpayload保持
      email: ''
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
    }),
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    backToSignIn() {
      this.$emit('backToSignIn');
    },
    verifyCodeSent(payload) {
      this.email = payload.email;
      this.tab = 'reset-password';
    }
  }
});
</script>

<template>
  <v-card width="600" class="mx-auto">
    <v-tabs-items v-model="tab">
      <v-tab-item value="send-verify-code">
        <SendVerifyCode @backToSignIn="backToSignIn" @verifyCodeSent="verifyCodeSent"/>
      </v-tab-item>
      <v-tab-item value="reset-password">
        <ResetPassword :email="email" @passwordReset="backToSignIn"/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
