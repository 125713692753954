<script>
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  CREATE_USER,
  GET_USER_GROUPS,
} from "@/store/action-types";
export default defineComponent({
  props: {
    value: Boolean
  },
  data() {
    return {
      gridCols: {
        label: 3,
        input: 12,
      },
      valid: true,
      user: {
        username: null,
        tempPassword: null,
        email: null,
      },
      groups: [],
      selectedGroup: null,
      invitation: false,
      usernameRules: [
        (v) => !!v || "ユーザ名は必須入力です。",
        (v) => (v && v.length >= 5) || "ユーザ名は5文字以上で入力してください。",
        (v) =>
          (
            v
            && v.length > 0
            && v.match(/[\u0000-\u00ff]/g) !== null
            && v.length === v.match(/[\u0000-\u00ff]/g).length
          ) || "ユーザ名は半角の英数文字を入力してください。",
      ],
      tempPasswordRules: [
        (v) => !!v || "一時パスワードは必須入力です。",
      ],
      emailRules: [
        (v) => !!v || "メールアドレスは必須入力です。",
        (v) => {
          const pattern = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "メールアドレスが正しくありません。";
        },
      ],
      groupRules: [
        (v) => !!v || "権限は必須入力です。"
      ],
      showPassword: false,
      errorCreateUser: ""
    }
  },
  computed: {
    ...mapState({
      cognitoUserGroups: (state) => state.cognitoUserGroups,
    }),
    ...mapGetters({
      userGroups: 'userGroups',
      isLoading: 'isLoadingUser',
    }),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },
    isValid() {
      return this.valid;
    },
    hasError() {
      return !this.isEmpty(this.errorCreateUser);
    },
  },
  watch: {
    userGroups: {
      handler(value) {
        this.groups = structuredClone(value);
      },
      deep: true
    }
  },
  async created() {
    await this.getUserGroups();
    this.groups = structuredClone(this.userGroups);
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getUserGroups: GET_USER_GROUPS,
      createUser: CREATE_USER,
    }),
    async handleCreateUser() {
      this.errorCreateUser = await this.createUser({
        userName: this.user.username,
        password: this.user.tempPassword,
        email: this.user.email,
        group: this.selectedGroup
      });
      if (!this.hasError) {
        this.close();
      }
    },
    close() {
      this.show = false;
    }
  }
});
</script>

<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="mt-2 px-2 text-h5 blue-grey--text text--darken-4 font-weight-bold">
          ユーザーを作成
        </v-toolbar-title>
      </v-toolbar>

      <v-container>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col :cols="gridCols.label">
              <v-subheader class="subtitle-1 blue-grey--text font-weight-bold">
                ユーザー名
              </v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-3 my-1">
            <v-col :cols="gridCols.input">
              <v-text-field
                v-model="user.username"
                :rules="usernameRules"
                solo
                single-line
                outlined
                dense
                flat
                hide-details="auto"
                placeholder="ユーザ名"
                autocomplete="off"
                color="var(--app-color)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col :cols="gridCols.label">
              <v-subheader class="subtitle-1 blue-grey--text font-weight-bold">
                一時パスワード
              </v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-3 my-1">
            <v-col :cols="gridCols.input">
              <v-text-field
                v-model="user.tempPassword"
                :rules="tempPasswordRules"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                solo
                single-line
                outlined
                dense
                flat
                hide-details="auto"
                placeholder="一時パスワード"
                @click:append="showPassword = !showPassword"
                autocomplete="off"
                color="var(--app-color)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col :cols="gridCols.label">
              <v-subheader class="subtitle-1 blue-grey--text font-weight-bold">
                メールアドレス
              </v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-3 my-1">
            <v-col :cols="gridCols.input">
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                solo
                single-line
                outlined
                dense
                flat
                hide-details="auto"
                placeholder="メールアドレス"
                color="var(--app-color)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col :cols="gridCols.label">
              <v-subheader class="subtitle-1 blue-grey--text font-weight-bold">
                権限
              </v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-3 my-1">
            <v-col :cols="gridCols.input">
              <v-autocomplete
                v-model="selectedGroup"
                :rules="groupRules"
                :items="groups"
                item-text="groupName"
                item-value="groupName"
                flat
                outlined
                dense
                placeholder="権限"
                color="var(--app-color)"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters>
            <v-subheader class="subtitle-1 blue-grey--text font-weight-bold">
              招待通知
            </v-subheader>
          </v-row>
          <v-row no-gutters class="px-3 my-1">
            <v-radio-group v-model="invitation" row height="30" dense hide-details class="ma-0 pa-0">
              <v-radio :value="true" :ripple="false" color="var(--app-color)">
                <template v-slot:label>
                  <v-subheader class="ml-n2 px-0 body-2 blue-grey--text text--darken-4">
                    送信する
                  </v-subheader>
                </template>
              </v-radio>
              <v-radio :value="false" :ripple="false" color="var(--app-color)">
                <template v-slot:label>
                  <v-subheader class="ml-n2 px-0 body-2 blue-grey--text text--darken-4">
                    送信しない
                  </v-subheader>
                </template>
              </v-radio>
            </v-radio-group>
          </v-row> -->
        </v-form>
        <v-alert v-model="hasError" class="body-2" color="red" type="error" text>
          {{ errorCreateUser }}
        </v-alert>
      </v-container>
      <v-card-actions class="pb-3 d-flex justify-end">
        <template>
          <v-btn
            outlined
            elevation="0"
            color="var(--app-color)"
            class="mb-2 cancel-btn"
            @click="close"
          >キャンセル</v-btn>
          <v-btn
            elevation="1"
            color="var(--app-color)"
            class="mb-2 ml-2 mr-2 white--text"
            :disabled="(isLoading || !isValid)"
            :loading="isLoading"
            @click="handleCreateUser"
          >作成</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
