<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: {
      type: String,
      default: "編集"
    }
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
});
</script>

<template>
  <v-btn elevation="0" dark color="var(--app-color)" @click="click">
    <div style="font-size: 14px;">{{ label }}</div>
  </v-btn>
</template>
