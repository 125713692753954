<script>
import { defineComponent } from "vue";
import amplifyAuth from "@/mixins/amplify-auth.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_SIGN_IN_USER_SESSION,
} from "@/store/mutation-types";
import {
  SEND_VERIFY_CODE,
  RESET_PASSWORD,
} from "@/store/action-types";
export default defineComponent({
  mixins: [amplifyAuth],
  props: {
    email: String
  },
  data() {
    return {
      // 入力
      code: '',
      password: '',
      showPassword: false,

      // バリデーション
      valid: false,
      codeRules: [
        (v) => !!v || "認証コードは必須です。",
      ],
      passwordRules: [
        (v) => !!v || "パスワードは必須入力です。",
        (v) =>
          (
            v
            && v.length > 0
            && v.match(/[\u0000-\u00ff]/g) !== null
            && v.length === v.match(/[\u0000-\u00ff]/g).length
          ) || "パスワードは半角の英数文字を入力してください。",
      ],
    };
  },
  computed: {
    ...mapState({
      isLoadingSendVerifyCode: (state) => state.isLoadingSendVerifyCode,
      isLoadingResetPassword: (state) => state.isLoadingResetPassword,
      errorSendVerifyCode: (state) => state.errorSendVerifyCode,
      errorResetPassword: (state) => state.errorResetPassword,
    }),
    ...mapGetters({
    }),
    hasError() {
      return !this.isEmpty(this.errorResetPassword);
    },
    errorMessage() {
      return (this.hasError) ? this.convertAuthErrorMessages(this.errorResetPassword) : '';
    },
  },
  methods: {
    ...mapMutations({
      setSignInUserSession: SET_SIGN_IN_USER_SESSION,
    }),
    ...mapActions({
      sendVerifyCode: SEND_VERIFY_CODE,
      resetPassword: RESET_PASSWORD,
    }),
    async sendAgain() {
      await this.sendVerifyCode({ email: this.email });
    },
    async handleResetPassword() {
      await this.resetPassword({ email: this.email, code: this.code, password: this.password });
      if (!this.hasError) {
        this.$emit('passwordReset');
      }
    }
  }
});
</script>

<template>
  <v-container class="pa-6">
    <v-row>
      <v-col>
        <div class="text-center text-h5">
          パスワードリセット
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="code"
            outlined
            dense
            prepend-inner-icon="mdi-shield-check-outline"
            placeholder="認証コード"
            color="var(--app-color)"
            :rules="codeRules"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            outlined
            dense
            prepend-inner-icon="mdi-shield-key-outline"
            placeholder="新しいパスワード"
            color="var(--app-color)"
            :rules="passwordRules"
          ></v-text-field>
        </v-form>
        <v-alert v-model="hasError" class="body-2" color="red" type="error" text>
          {{ errorMessage }}
        </v-alert>
      </v-container>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="auto">
        <v-btn
          text
          color="blue"
          class="pa-0"
          :loading="isLoadingSendVerifyCode"
          @click="sendAgain"
        >
          もう一度認証コードを送る
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="var(--app-color)"
          class="white--text"
          :loading="isLoadingResetPassword"
          :disabled="!valid"
          @click="handleResetPassword"
        >
          <v-icon left>mdi-login-variant</v-icon>
          登録
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
