<script>
import { defineComponent } from "vue";
import amplifyAuth from "@/mixins/amplify-auth.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  SEND_VERIFY_CODE,
} from "@/store/action-types";
export default defineComponent({
  mixins: [amplifyAuth],
  data() {
    return {
      // 入力
      email: '',

      // バリデーション
      valid: false,
      emailRules: [
        (v) => !!v || "メールアドレスは必須入力です。",
        (v) => {
          const pattern = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || "メールアドレスが正しくありません。";
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoadingSendVerifyCode,
      errorSendVerifyCode: (state) => state.errorSendVerifyCode,
    }),
    ...mapGetters({
    }),
    hasError() {
      return !this.isEmpty(this.errorSendVerifyCode);
    },
    errorMessage() {
      return (this.hasError) ? this.convertAuthErrorMessages(this.errorSendVerifyCode) : '';
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      sendVerifyCode: SEND_VERIFY_CODE,
    }),
    backToSignIn() {
      this.$emit('backToSignIn');
    },
    async handleSendVerifyCode() {
      const payload = {
        email: this.email
      };
      await this.sendVerifyCode(payload);
      if (!this.hasError) {
        this.$emit('verifyCodeSent', payload);
      }
    }
  }
});
</script>

<template>
  <v-container class="pa-6">
    <v-row>
      <v-col>
        <div class="text-center text-h5">
          パスワードリセット
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="email"
            outlined
            dense
            prepend-inner-icon="mdi-account-circle-outline"
            placeholder="メールアドレス"
            color="var(--app-color)"
            :rules="emailRules"
          ></v-text-field>
        </v-form>
        <v-alert v-model="hasError" class="body-2" color="red" type="error" text>
          {{ errorMessage }}
        </v-alert>
      </v-container>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="auto">
        <v-btn
          text
          color="blue"
          class="pa-0"
          @click="backToSignIn"
        >
          サインインに戻る
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          color="var(--app-color)"
          class="white--text"
          :loading="isLoading"
          :disabled="!valid"
          @click="handleSendVerifyCode"
        >
          <v-icon left>mdi-email-outline</v-icon>
          認証コードを送信
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
