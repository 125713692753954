<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
});
</script>

<template>
  <v-chip
    outlined
    color="var(--app-color)"
  >
    <div style="font-size: 10px; color: var(--app-color)">
      {{ value }}
    </div>
    <v-avatar>
      <v-icon
        small
        color="var(--app-color)"
        @click="close"
      >mdi-close</v-icon>
    </v-avatar>
  </v-chip>
</template>
