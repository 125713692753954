import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'

import mixins from './mixins/index.js'

import vuetify from '@/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import './assets/main.css'

import Dialog from '@/plugins/dialog'
import OkigasAxiosPlugin from '@/plugins/okigasAxios'

Vue.mixin(mixins);
Vue.use(Dialog);
Vue.use(OkigasAxiosPlugin);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

// イベント設定用関数
function noScroll (event) { event.preventDefault() }

// スクロール禁止
Vue.prototype.$noScroll = function () {
  // SP
  document.addEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.addEventListener('mousewheel', noScroll, { passive: false })
}

// スクロール禁止を解除
Vue.prototype.$returnScroll = function () {
  // SP
  document.removeEventListener('touchmove', noScroll, { passive: false })
  // PC
  document.removeEventListener('mousewheel', noScroll, { passive: false })
}