import {
  isEmpty,
  isNumber
} from "@/mixins/index.js";
import {
  convertReceptionNoToNumeric
} from "@/mixins/application.js";

// 存在チェック
export const REQUIRED = (v) => (!isEmpty(v)) || "必須入力です";

// 文字列の種別チェック
export const IS_EMAIL = (v) => (isEmpty(v) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v)) || "メールアドレス形式で入力して下さい";
export const IS_NUMBER_STRING = (v) => (isEmpty(v) || /^\d+$/.test(v)) || "数字を入力して下さい";
export const IS_ONLY_CHAR = (v) => (isEmpty(v) || /^[^0-9０-９]*$/.test(v)) || "文字を入力して下さい";
export const IS_KATAKANA = (v) => (isEmpty(v) || /^[ァ-ヶー　\s]+$/.test(v)) || "口座名義はカタカナのみで入力して下さい";
export const IS_FURIGANA = (v) => (isEmpty(v) || /^[ァ-ヶー0-9０-９]+$/.test(v)) || "フリガナはカタカナもしくは数字で入力して下さい";

// 文字列の長さチェック
export const ONLY_LENGTH_3 = (v) => (isEmpty(v) || v.length === 3) || "3文字で入力して下さい";
export const ONLY_LENGTH_5 = (v) => (isEmpty(v) || v.length === 5) || "5文字で入力して下さい";
export const ONLY_LENGTH_7 = (v) => (isEmpty(v) || v.length === 7) || "7文字で入力して下さい";
export const ONLY_LENGTH_7_OR_8 = (v) => (isEmpty(v) || v.length === 7 || v.length === 8) || "7文字または8文字で入力して下さい";
export const ONLY_LENGTH_10_OR_11 = (v) => (isEmpty(v) || v.length === 10 || v.length === 11) || "10文字または11文字で入力して下さい";
export const ONLY_LENGTH_13 = (v) => (isEmpty(v) || v.length === 13) || "13文字で入力して下さい";
export const MIN_LENGTH_1 = (v) => (isEmpty(v) || v.length >= 1) || "1文字以上で入力して下さい";
export const MIN_LENGTH_3 = (v) => (isEmpty(v) || v.length >= 3) || "3文字以上で入力して下さい";
export const MAX_LENGTH_4 = (v) => (isEmpty(v) || v.length <= 4) || "4文字以内で入力して下さい";
export const MAX_LENGTH_8 = (v) => (isEmpty(v) || v.length <= 8) || "8文字以内で入力して下さい";
export const MAX_LENGTH_9 = (v) => (isEmpty(v) || v.length <= 9) || "9文字以内で入力して下さい";
export const MAX_LENGTH_10 = (v) => (isEmpty(v) || v.length <= 10) || "10文字以内で入力して下さい";
export const MAX_LENGTH_11 = (v) => (isEmpty(v) || v.length <= 11) || "11文字以内で入力して下さい";
export const MAX_LENGTH_32 = (v) => (isEmpty(v) || v.length <= 32) || "32文字以内で入力して下さい";
export const MAX_LENGTH_320 = (v) => (isEmpty(v) || v.length <= 320) || "320文字以内で入力して下さい";
export const MAX_LENGTH_20_NO_ERR_MSG = (v) => (isEmpty(v) || v.length <= 20) || "";
export const ZEROUME_LENGTH_8 = (v) => (isEmpty(v) || v.length >= 8) || "8文字になるよう右づめにして先頭に0を記入して下さい";

// 数値の種別チェック
export const IS_NUMBER = (v) => (isEmpty(v) || isNumber(v)) || "数値を入力して下さい";
export const IS_POSITIVE_NUMBER = (v) => (isEmpty(v) || (isNumber(v) && v > 0)) || "正の数値を入力して下さい";

// 数値の桁数チェック
export const ONLY_NUMBER_LENGTH_13 = (v) => (isEmpty(v) || !isNumber(v) || (1e12 <= v && v < 1e13)) || "13桁で入力して下さい";

// applicationオブジェクトの型定義チェック
export const IS_RECEPTION_NO = (v) => (isEmpty(v) || (isNumber(convertReceptionNoToNumeric(v)))) || "受付番号の形式で入力してください";

export default {
  REQUIRED,
  IS_EMAIL,
  IS_NUMBER_STRING,
  IS_ONLY_CHAR,
  IS_KATAKANA,
  IS_FURIGANA,
  ONLY_LENGTH_3,
  ONLY_LENGTH_5,
  ONLY_LENGTH_7,
  ONLY_LENGTH_7_OR_8,
  ONLY_LENGTH_10_OR_11,
  ONLY_LENGTH_13,
  MIN_LENGTH_1,
  MIN_LENGTH_3,
  MAX_LENGTH_4,
  MAX_LENGTH_8,
  MAX_LENGTH_9,
  MAX_LENGTH_10,
  MAX_LENGTH_11,
  MAX_LENGTH_32,
  MAX_LENGTH_320,
  MAX_LENGTH_20_NO_ERR_MSG,
  ZEROUME_LENGTH_8,
  IS_NUMBER,
  IS_POSITIVE_NUMBER,
  ONLY_NUMBER_LENGTH_13,
  IS_RECEPTION_NO,
};