<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: [Number, String],
    rules: Array
  },
  computed: {
    number: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
});
</script>

<template>
  <v-text-field
    v-model.number="number"
    inputmode="numeric"
    hide-details
    outlined
    dense
    color="var(--app-color)"
    class="pa-0"
    :rules="rules"
  ></v-text-field>
</template>
