<script>
import { defineComponent } from "vue";
import SubHeader from "../components/Header/SubHeader.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_USERS,
  GET_USER_GROUPS,
  ENABLE_USER,
  DISABLE_USER,
  DELETE_USER,
  UPDATE_USERS_GROUP,
} from "@/store/action-types";
export default defineComponent({
  components: {
    SubHeader
  },
  data() {
    return {
      // 定数
      userStatusList: [
        { text: "未承認", value: "UNCONFIRMED", color: "grey" },
        { text: "承認済み", value: "CONFIRMED", color: "green" },
        { text: "パスワード設定必要", value: "RESET_REQUIRED", color: "red" },
        { text: "パスワード更新必要", value: "FORCE_CHANGE_PASSWORD", color: "orange" }
      ],
      userEnabledList: [
        { text: "有効", value: true, color: "var(--app-color)" },
        { text: "無効", value: false, color: "red" }
      ],
      dataTextDefault: '--',

      // ユーザー詳細
      gridCols: {
        label: 2,
        input: 10
      },
      userLocal: null,
    };
  },
  computed: {
    ...mapState({
      cognitoUsers: (state) => state.cognitoUsers,
      cognitoUserGroups: (state) => state.cognitoUserGroups,
    }),
    ...mapGetters({
      users: 'users',
      userGroups: 'userGroups',
      isLoading: 'isLoadingUser',
    }),
    user() {
      const userName = this.$route.params.userName; // パスパラメータからuserNameを取得
      return this.users.find((u) => u.userName === userName);
    },
    isEnabledUser() {
      return this.user?.enabled;
    },
    isSignedInUser() {
      return false;
    }
  },
  watch: {
    user: {
      handler(value) {
        this.userLocal = structuredClone(value);
      },
      deep: true
    }
  },
  async created() {
    await this.getUsers();
    await this.getUserGroups();
    this.userLocal = structuredClone(this.user);
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getUsers: GET_USERS,
      getUserGroups: GET_USER_GROUPS,
      enableUser: ENABLE_USER,
      disableUser: DISABLE_USER,
      deleteUser: DELETE_USER,
      updateUsersGroup: UPDATE_USERS_GROUP,
    }),
    isUsersGroup(value) {
      return (this.userLocal?.group.groupName === value);
    },
    getStatusColor(value) {
      const find = this.userStatusList.find((item) => item.value === value);
      return (find) ? find.color : this.dataColotDefault;
    },
    getStatusName(value) {
      const find = this.userStatusList.find((item) => item.value === value);
      return (find) ? find.text : this.dataTextDefault;
    },
    getEnabledColor(value) {
      const find = this.userEnabledList.find((item) => item.value === value);
      return (find) ? find.color : this.dataColotDefault;
    },
    getEnabledName(value) {
      const find = this.userEnabledList.find((item) => item.value === value);
      return (find) ? find.text : this.dataTextDefault;
    },
    async handleUpdateUsersGroup(value) {
      this.userLocal.group = structuredClone(this.userGroups.find((g) => g.groupName === value));
      await this.updateUsersGroup({
        userName: this.user.userName,
        oldGroup: this.user.group.groupName,
        newGroup: this.userLocal.group.groupName
      });
    },
    async handleDisableUser() {
      (this.userLocal.enabled)
        ? await this.disableUser({ userName: this.userLocal.userName })
        : await this.enableUser({ userName: this.userLocal.userName });
    },
    async handleDeleteUser() {
      await this.deleteUser({ userName: this.userLocal.userName });
      this.$router.push('/UserSetting');
    }
  }
});
</script>

<template>
  <div>
    <SubHeader>
      <template>
        <div class="d-flex justify-start align-center">
          <router-link :to="{ name: 'UserSetting' }">
            <v-btn text color="var(--app-color)">
              ユーザー一覧に戻る
            </v-btn>
          </router-link>
        </div>
      </template>
    </SubHeader>
    <v-container class="pa-0">
      <v-card outlined class="mt-4 pa-4">
        <v-row no-gutters class="mb-6">
          <v-col>
            <v-toolbar-title class="text-h5 blue-grey--text text--darken-4 font-weight-bold">
              ユーザー編集
            </v-toolbar-title>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              権限
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              <v-chip
                v-for="item in userGroups"
                :key="item.groupName"
                small
                :outlined="!isUsersGroup(item.groupName)"
                :color="isUsersGroup(item.groupName) ? 'var(--app-color)' : ''"
                :class="isUsersGroup(item.groupName) ? 'white--text' : ''"
                class="mr-2"
                @click="handleUpdateUsersGroup(item.groupName)"
              >
                {{ item.groupName }}
              </v-chip>
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              ユーザー名
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              {{ userLocal?.userName }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              メールアドレス
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              {{ userLocal?.email }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              ステータス
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              {{ getStatusName(userLocal?.status) }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              有効
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              <v-chip small :color="getEnabledColor(userLocal?.enabled)" text-color="white">
                {{ getEnabledName(userLocal?.enabled) }}
              </v-chip>
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              更新日
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              {{ userLocal?.updatedAt }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-1">
          <v-col :cols="gridCols.label">
            <v-subheader class="pa-0 body-2 blue-grey--text font-weight-bold">
              作成日
            </v-subheader>
          </v-col>
          <v-col :cols="gridCols.input">
            <v-subheader>
              {{ userLocal?.createdAt }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-row class="justify-end">
          <v-col cols="auto">
            <v-btn
              outlined
              elevation="0"
              dark
              color="var(--app-color)"
              :loading="isLoading"
              @click="handleDisableUser()"
            >
              <div v-if="isEnabledUser">
                <v-icon left>mdi-account-cancel-outline</v-icon>
                アカウント無効化
              </div>
              <div v-else>
                <v-icon left>mdi-account-check-outline</v-icon>
                アカウント有効化
              </div>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              elevation="0"
              color="error"
              :loading="isLoading"
              :disabled="isEnabledUser"
              @click="handleDeleteUser()"
            >
              <v-icon left>mdi-delete-forever-outline</v-icon>
              削除
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>
