<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: [String, Number],
    options: Array,
    title: String,
    required: Boolean,
    rules: Array
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
});
</script>

<template>
  <v-container class="mt-3">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-radio-group v-model="selected" :rules="rules" dense class="mt-0">
          <v-radio
            v-for="option in options"
            :key="option.value"
            :label="option.text"
            :value="option.value"
            color="var(--app-color)"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>
