<script>
import { defineComponent } from "vue";
import SearchArea from "../components/ApplicationList/SearchArea/index.vue";
import ValidApplicationTable from "../components/ApplicationList/TableArea/ValidApplicationTable.vue";
import InvalidApplicationTable from "../components/ApplicationList/TableArea/InvalidApplicationTable.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_APPLICATIONS,
} from "@/store/action-types";
export default defineComponent({
  components: {
    SearchArea,
    ValidApplicationTable,
    InvalidApplicationTable
  },
  data() {
    return {
      tab: "valid-application-table"
    };
  },
  computed: {
    ...mapState({
      applications: (state) => state.applications,
    }),
    ...mapGetters({
      isLoading: 'isLoadingApplication',
    }),
  },
  async created() {
    await this.getApplications();
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getApplications: GET_APPLICATIONS,
    }),
  }
});
</script>

<template>
  <div>
    <SearchArea/>

    <v-container>
      <v-row class="justify-start">
        <v-col>
          <v-btn-toggle v-model="tab" dense mandatory color="var(--app-color)">
            <v-btn value="valid-application-table">
              申し込み一覧
            </v-btn>
            <v-btn value="invalid-application-table">
              削除済み一覧
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-card outlined class="mt-1" :disabled="isLoading">
        <v-tabs-items v-model="tab">
          <v-tab-item value="valid-application-table">
            <ValidApplicationTable/>
          </v-tab-item>
          <v-tab-item value="invalid-application-table">
            <InvalidApplicationTable/>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>
