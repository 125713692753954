<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    placeholder: String,
    numeric: Boolean,
    rules: Array
  },
  computed: {
    inputText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    type() {
      if (this.numeric) return 'number';
      return 'text';
    }
  }
});
</script>

<template>
  <v-text-field
    v-model="inputText"
    :placeholder="placeholder"
    :type="type"
    :rules="rules"
    outlined
    dense
    color="var(--app-color)"
  ></v-text-field>
</template>
