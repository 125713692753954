<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    rules: Array,
    min: String,
    max: String
  },
  data() {
    return {
      isOpenMenu: false
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
});
</script>

<template>
  <v-menu
    v-model="isOpenMenu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-on="on"
        v-bind="attrs"
        v-model="selectedDate"
        placeholder="YYYY/MM/DD"
        readonly
        outlined
        dense
        hide-details
        color="var(--app-color)"
        style="max-width: 124px;"
        :rules="rules"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      locale="ja"
      no-title
      color="var(--app-color)"
      :min="min"
      :max="max"
    />
  </v-menu>
</template>
