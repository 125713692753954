export const LOGIN = "LOGIN";
export const NEW_PASSWORD = "NEW_PASSWORD";
export const SEND_VERIFY_CODE = "SEND_VERIFY_CODE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT = "LOGOUT";
export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATIONS_BY_APPLICATION_IDS = "GET_APPLICATIONS_BY_APPLICATION_IDS";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const EDIT_OPEN_FAUCET = "EDIT_OPEN_FAUCET";
export const EDIT_CLOSE_FAUCET = "EDIT_CLOSE_FAUCET";
export const CANCEL_CLOSE_FAUCET = "CANCEL_CLOSE_FAUCET";
export const CANCEL_OPEN_FAUCET = "CANCEL_OPEN_FAUCET";
export const VALIDATE_APPLICATIONS = "VALIDATE_APPLICATIONS";
export const INVALIDATE_APPLICATIONS = "INVALIDATE_APPLICATIONS";
export const CHANGE_DELETING_STATE_APPLICATIONS = "CHANGE_DELETING_STATE_APPLICATIONS";
export const UPDATE_DOWNLOAD_AT_APPLICATIONS = "UPDATE_DOWNLOAD_AT_APPLICATIONS";
export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const ENABLE_USER = "ENABLE_USER";
export const DISABLE_USER = "DISABLE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USERS_GROUP = "UPDATE_USERS_GROUP";
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_RECEPTION_SETTING = "GET_RECEPTION_SETTING";
export const ACTION_SET_CALENDAR_TARGET_DATE = "ACTION_SET_CALENDAR_TARGET_DATE";
export const PUT_RECEPTION_SETTING = "PUT_RECEPTION_SETTING";
export const BULK_REGISTRATION_RECEPTION_SETTING = "BULK_REGISTRATION_RECEPTION_SETTING";
export const GET_RESERVABLE_DATA = "GET_RESERVABLE_DATA";
export const GET_ACCEPTABLE_RANGE_SETTING = "GET_ACCEPTABLE_RANGE_SETTING";
export const PUT_ACCEPTABLE_RANGE_SETTING = "PUT_ACCEPTABLE_RANGE_SETTING";
export const GET_MAIL_SETTING = "GET_MAIL_SETTING";
export const UPDATE_MAIL_SETTING = "UPDATE_MAIL_SETTING";
export const GET_LINK_SETTING = "GET_LINK_SETTING";
export const PUT_LINK_SETTING = "PUT_LINK_SETTING";
