import {
  isOpenFaucet,
  isCloseFaucet,
} from "@/mixins/application.js";

export default {
  startDate: (state) => (applicationType) => {
    let startDate = null;
    if (isOpenFaucet(applicationType)) {
      startDate = state.startDateOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      startDate = state.startDateCloseFaucet;
    }
    return startDate;
  },
  endDate: (state) => (applicationType) => {
    let endDate = null;
    if (isOpenFaucet(applicationType)) {
      endDate = state.endDateOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      endDate = state.endDateCloseFaucet;
    }
    return endDate;
  },
  receptionSetting: (state) => (applicationType) => {
    let receptionSetting = null;
    if (isOpenFaucet(applicationType)) {
      receptionSetting = state.receptionSettingOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      receptionSetting = state.receptionSettingCloseFaucet;
    }
    return receptionSetting;
  },
  isLoadingGetReceptionSetting: (state) => (applicationType) => {
    let isLoadingGetReceptionSetting = false;
    if (isOpenFaucet(applicationType)) {
      isLoadingGetReceptionSetting = state.isLoadingGetReceptionSettingOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      isLoadingGetReceptionSetting = state.isLoadingGetReceptionSettingCloseFaucet;
    }
    return isLoadingGetReceptionSetting;
  },
  requestIdGetReceptionSetting: (state) => (applicationType) => {
    let requestIdGetReceptionSetting = false;
    if (isOpenFaucet(applicationType)) {
      requestIdGetReceptionSetting = state.requestIdGetReceptionSettingOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      requestIdGetReceptionSetting = state.requestIdGetReceptionSettingCloseFaucet;
    }
    return requestIdGetReceptionSetting;
  },
  currentTimezone: (state) => (applicationType) => {
    let currentTimezone = null;
    if (isOpenFaucet(applicationType)) {
      currentTimezone = state.currentTimezoneOpenFaucet;
    } else if (isCloseFaucet(applicationType)) {
      currentTimezone = state.currentTimezoneCloseFaucet;
    }
    return currentTimezone;
  }
};
