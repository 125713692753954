<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    id: String,
    rows: Number,
    maxlength: Number
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
});
</script>

<template>
  <v-textarea
    ref="textarea"
    v-model="localValue"
    dense
    grow
    :id="id"
    :rows="rows"
    :maxlength="maxlength"
    hide-details
    no-resize
    outlined
    color="var(--app-color)"
  ></v-textarea>
</template>
