import { Auth } from 'aws-amplify';
const awsConfig = {
  region: import.meta.env.VITE_COGNITO_REGION,
  userPoolId: import.meta.env.VITE_POOL_ID,
  userPoolWebClientId: import.meta.env.VITE_CLIENT_ID,
};
Auth.configure(awsConfig);

// ログインユーザー情報(セッション)確認
export const confirmSession = async () => {
  try {
    const session = await Auth.currentSession();
    // セッションが有効な場合
    console.log('session is valid')
    return session;
  } catch (error) {
    // セッションが無効な場合
    console.log('session is invalid')
    return false;
  }
};

// ログアウト
export const login = async (userName, password) => {
  return Auth.signIn(userName, password);
};

// ログアウト
export const logout = async () => {
  return Auth.signOut();
};

// 新規作成
export const signUp = async (userName, password, email) => {
  return Auth.signUp({
    username: userName,
    password: password,
    attributes: {
      email: email
    },
    autoSignIn: {
      enabled: false
    }
  });
};

// パスワード更新
export const setNewPassword = async (user, newPassword) => {
  return await Auth.completeNewPassword(user, newPassword, {});
}

// パスワード変更認証コード発行
export const forgotPassword = async (userName) => {
  const res = await Auth.forgotPassword(userName)
  .then((data) => {
    return {
      data : data,
      statusCode : 200
    };
  })
  .catch((err) => {
    return {
      data : err,
      statusCode : 500
    }
  });
  return res
};

// パスワード検証
export const confirmForgotPassword = async (userName, code, newPassword) =>  {
  await Auth.forgotPasswordSubmit(userName, code, newPassword);
};

// エラーをエラーメッセージに変換
export const convertAuthErrorMessages = (error) => {
  const code = (error.code) ? error.code : null;
  let message = "";

  switch (code) {
    case "NotAuthorizedException":
    case "InvalidParameterException":
      if (error.message.includes("disabled")) {
        message = "アカウントが無効化されています。";
      } else {
        message = "ユーザ名またはパスワードが間違っているか登録されていません。";
      }
      break;
    case "InvalidPasswordException":
      message = "認証コードが間違っているか、パスワードポリシーを満たしていません。";
      break;
    case "CodeMismatchException":
      message = "認証コードが正しくありません。";
      break;
    case "LimitExceededException":
    case "TooManyRequestsException":
      message = "リクエストの上限に達しました。しばらく時間を置いてもう一度お試しください";
      break;
    case "PasswordResetRequiredException":
      message = "パスワードはリセットされました。";
      break;
    case "UserNotFoundException":
      message = "ユーザーが見つかりません";
      break;
    default:
      message = "不明なエラーが発生しました。";
      break;
  }

  return message;
}

export default {
  methods: {
    confirmSession,
    login,
    logout,
    signUp,
    setNewPassword,
    forgotPassword,
    confirmForgotPassword,
    convertAuthErrorMessages,
  }
}
