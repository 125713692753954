<script>
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_SIGN_IN_USER_SESSION,
} from "@/store/mutation-types";
import {
  LOGOUT,
} from "@/store/action-types";
export default defineComponent({
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoadingLogout,
    }),
    ...mapGetters({
      signInUser: 'signInUser',
    }),
  },
  methods: {
    ...mapMutations({
      setSignInUserSession: SET_SIGN_IN_USER_SESSION,
    }),
    ...mapActions({
      logout: LOGOUT,
    }),
    async handleSignOut() {
      await this.logout();
      this.setSignInUserSession(null);
      this.$router.push('/SignIn');
    }
  }
});
</script>

<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    left
    nudge-bottom="0"
    transition="scroll-y-transition"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-card elevation="2" outlined max-width="320" flat ripple class="mx-auto">
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="title font-weight-bold">
                {{ signInUser.username }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ signInUser.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
    </template>

    <v-card tile max-width="320">
      <v-list class="py-0" dense>
        <v-divider/>
        <v-list-item @click="handleSignOut">
          <v-list-item-content>
            <v-list-item-title>
              サインアウト
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-shield-lock-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>