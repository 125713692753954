<script>
import { defineComponent } from "vue";
export default defineComponent({
});
</script>

<template>
  <div>
    <v-toolbar
      elevation="0"
      min-width="100%"
      width="100%"
      min-height="68px"
      height="auto"
      style="flex-direction: column;"
    >
      <v-container class="px-0">
        <slot></slot>
      </v-container>
    </v-toolbar>
  </div>
</template>
