<script>
import { defineComponent } from "vue";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  props: {
    value: String,
    min: String,
    max: String,
    receptionSetting: Object,
    title: String,
    required: Boolean,
    rules: Array,
  },
  data() {
    return {
      isOpenMenu: false,
      uniqueClass: '',
    };
  },
  computed: {
    // propsをラップするcomputed
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    reservableDate() {
      let result = {};
      for (const key in this.receptionSetting?.schedule) {
        let maxYoyakuKazu = 0;
        let nowYoyakuKazu = 0;
        for (const time in this.receptionSetting?.schedule[key]) {
          maxYoyakuKazu += this.receptionSetting?.schedule[key][time][0];
          nowYoyakuKazu += this.receptionSetting?.schedule[key][time][1];
        }
        result[key] = maxYoyakuKazu - nowYoyakuKazu;
      }
      return result;
    },
  },
  watch: {
    selectedDate: {
      handler() {
        this.isOpenMenu = false;
      }
    }
  },
  async created() {
    // 一意のクラス生成
    this.uniqueClass = 'menu-patent-' + this.createUniqueStr();
  },
  methods:{
    isAllowedDates(date) {
      const allowedDates = [];
      for (const key in this.reservableDate) {
        if (this.reservableDate[key] > 0) {
          allowedDates.push(String(key));
        }
      }

      return allowedDates.includes(date);
    },
    dayFormat(date) {
      if (this.reservableDate[date] !== undefined) {
        const retValue = this.reservableDate[date];
        return new Date(date).getDate() +"("+ retValue +")";
      } else {
        return new Date(date).getDate() +"("+ '0' +")";
      }
    },
  }
});
</script>

<template>
  <v-container class="mt-3">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" :class="uniqueClass">
        <v-menu
          v-model="isOpenMenu"
          :rules="rules"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="400px"
          :attach="`.${uniqueClass}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-on="on"
              v-bind="attrs"
              v-model="selectedDate"
              :rules="rules"
              placeholder="YYYY-MM-DD"
              readonly
              dense
              color="var(--app-color)"
            ></v-text-field>
          </template>
          <v-date-picker
            class="LastUseDate"
            v-model="selectedDate"
            :min="min"
            :max="max"
            :allowed-dates="isAllowedDates"
            :day-format="dayFormat"
            locale="jp-ja"
            no-title
            full-width
            color="var(--app-color)"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
::v-deep .LastUseDate .v-picker__body div .v-date-picker-table--date table tbody tr .v-btn {
  width: 50px;
}
</style>