import {
  convertRFC3339ToDateTime,
} from "./index.js";

// Cognitoのuserをuserに変換
export const convertCognitoToUser = (cognitoUser) => {
  return {
    userName: cognitoUser.Username,
    email: cognitoUser.Attributes.find((a) => a.Name === 'email').Value,
    status: cognitoUser.UserStatus,
    enabled: cognitoUser.Enabled,
    createdAt: convertRFC3339ToDateTime(cognitoUser.UserCreateDate),
    updatedAt: convertRFC3339ToDateTime(cognitoUser.UserLastModifiedDate),
    group: convertCognitoToGroup(cognitoUser.Groups[0])
  };
};

// Cognitoのgroupをgroupに変換
export const convertCognitoToGroup = (cognitoGroup) => {
  return {
    groupName: cognitoGroup.GroupName,
    createdAt: convertRFC3339ToDateTime(cognitoGroup.CreationDate),
    updatedAt: convertRFC3339ToDateTime(cognitoGroup.LastModifiedDate)
  };
};

// groupの順序を権限順にソート
export const groupSort = (a, b) => {
  const order = [
    "管理者",
    "一般"
  ];

  let result = 0;
  for (let value of order) {
    if (a.groupName === value) {
      result = -1;
      break;
    } else if (b.groupName === value) {
      result = 1;
      break;
    }
  }

  return result;
};
