<script>
import { defineComponent } from "vue";
import amplifyAuth from "@/mixins/amplify-auth.js";
import NewPassword from "../components/SignIn/NewPassword.vue";
import ForgetPassword from "../components/SignIn/ForgetPassword/index.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_SIGN_IN_USER_SESSION,
} from "@/store/mutation-types";
import {
  LOGIN,
} from "@/store/action-types";
import img from "@/assets/okinawagas-logo.png";
export default defineComponent({
  mixins: [amplifyAuth],
  components: {
    NewPassword,
    ForgetPassword
  },
  data() {
    return {
      // 画面
      user: null,
      currentCard: 'signin',

      // 入力
      userName: '',
      password: '',
      showPassword: false,

      // バリデーション
      valid: false,
      userNameRules: [
        (v) => !!v || "ユーザ名は必須入力です。",
        (v) => (v && v.length >= 5) || "ユーザ名は5文字以上で入力してください。",
        (v) =>
          (
            v
            && v.length > 0
            && v.match(/[\u0000-\u00ff]/g) !== null
            && v.length === v.match(/[\u0000-\u00ff]/g).length
          ) || "ユーザ名は半角の英数文字を入力してください。",
      ],
      passwordRules: [
        (v) => !!v || "パスワードは必須入力です。",
        (v) =>
          (
            v
            && v.length > 0
            && v.match(/[\u0000-\u00ff]/g) !== null
            && v.length === v.match(/[\u0000-\u00ff]/g).length
          ) || "パスワードは半角の英数文字を入力してください。",
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoadingLogin,
      errorLogin: (state) => state.errorLogin,
    }),
    ...mapGetters({
    }),
    logoSrc() {
      return img;
    },
    hasError() {
      return !this.isEmpty(this.errorLogin);
    },
    errorMessage() {
      return (this.hasError) ? this.convertAuthErrorMessages(this.errorLogin) : '';
    }
  },
  methods: {
    ...mapMutations({
      setSignInUserSession: SET_SIGN_IN_USER_SESSION,
    }),
    ...mapActions({
      login: LOGIN,
    }),
    async signIn() {
      const user = await this.login({ userName: this.userName, password: this.password });
      if (this.hasError) {
        // エラー
        return;
      } else if (user.signInUserSession) {
        // ログイン
        console.log('login succeeded')
        this.setSignInUserSession(user.signInUserSession);
        this.$router.push('/');
      } else {
        this.user = user;
        switch (user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            //TODO 強制パスワード変更
            console.log('new password required')
            this.currentCard = "newpassword";
            break;
        
          case 'MFA_REQUIRED':
          case 'SMS_MFA':
            //TODO ２段階認証が必要
            console.log('mfa required')
            break;
        
          default:
            break;
        }
      }
    }
  }
});
</script>

<template>
  <div class="screen">
    <v-container class="content">
      <v-img
        max-height="150"
        max-width="250"
        :src="logoSrc"
        class="mx-auto"
      />
      <transition
        name="login-transition"
        enter-active-class="animate__animated animate__zoomInDown"
        :leave-active-class="`animate__animated ${
          (currentCard === 'signin') ? 'animate__zoomOutRight' : 'animate__zoomOutLeft'
        }`"
      >
        <v-card
          v-if="currentCard === 'signin'"
          width="600"
          class="mx-auto"
        >
          <v-container class="pa-6">
            <v-row>
              <v-col>
                <div class="text-center text-h5">
                  サインイン
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-container>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    v-model="userName"
                    outlined
                    dense
                    prepend-inner-icon="mdi-account-circle-outline"
                    placeholder="ユーザー名"
                    color="var(--app-color)"
                    :rules="userNameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    autocomplete="off"
                    outlined
                    dense
                    prepend-inner-icon="mdi-shield-key-outline"
                    placeholder="パスワード"
                    color="var(--app-color)"
                    :rules="passwordRules"
                  ></v-text-field>
                </v-form>
                <v-alert v-model="hasError" class="body-2" color="red" type="error" text>
                  {{ errorMessage }}
                </v-alert>
              </v-container>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="auto">
                <v-btn
                  text
                  color="blue"
                  class="pa-0"
                  @click="currentCard = 'forgetpassword'"
                >
                  パスワードをお忘れの方
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  color="var(--app-color)"
                  class="white--text"
                  :loading="isLoading"
                  :disabled="!valid"
                  @click="signIn"
                >
                  <v-icon left>mdi-login-variant</v-icon>
                  サインイン
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <NewPassword
          v-if="currentCard === 'newpassword'"
          :user="user"
        />
        <ForgetPassword
          v-if="currentCard === 'forgetpassword'"
          @backToSignIn="currentCard = 'signin'"
        />
      </transition>
    </v-container>
  </div>
</template>

<style scoped>
.screen {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
}
</style>
