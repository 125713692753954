<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: [
    "totalCount",
    "page",
    "perOnPage",
    "loading"
  ],
  computed: {
    pageLocal: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit('changePage', value);
      }
    },
    paginationText() {
      const start = 1 + (this.perOnPage * (this.pageLocal - 1));
      const end = ((this.perOnPage * this.pageLocal) < this.totalCount) ? (this.perOnPage * this.pageLocal) : this.totalCount;
      return `${start} - ${end} / ${this.totalCount}`;
    },
    isPreviousDisabled() {
      return this.page <= 1;
    },
    isNextDisabled() {
      return (this.perOnPage * this.pageLocal) >= this.totalCount;
    }
  },
  methods: {
    handlePrevious() {
      this.pageLocal = (this.pageLocal - 1);
    },
    handleNext() {
      this.pageLocal = (this.pageLocal + 1);
    },
  }
});
</script>

<template>
  <div>
    <v-divider/>
    <v-container fluid class="py-0">
      <v-row no-gutters class="justify-space-between">
        <v-col cols="auto" class="align-self-center">
          <span class="body-2 grey--text text--darken-2">
            {{ paginationText }}
          </span>
        </v-col>
        <div class="d-flex">
          <v-col cols="auto" class="align-self-center">
            <v-icon left color="grey--text text--darken-1" :disabled="(isPreviousDisabled || loading)" @click="handlePrevious">
              mdi-chevron-left
            </v-icon>
          </v-col>
          <v-col cols="auto" class="align-self-center">
            <v-icon left color="grey--text text--darken-1" :disabled="(isNextDisabled || loading)" @click="handleNext">
              mdi-chevron-right
            </v-icon>
          </v-col>
        </div>
      </v-row>
    </v-container>
    <v-divider/>
  </div>
</template>
