<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: String
  },
  data() {
    return {
      textMessage: ""
    };
  },
  async created() {
    if (this.message === "loading") {
      this.textMessage = "読み込み中です...";
    } else if (this.message === "saving") {
      this.textMessage = "保存中です...";
    }
  }
})
</script>

<template>
  <div class="text-center">
    <v-container>
      <v-row>
        <v-col>
          <v-progress-circular
            :size="50"
            indeterminate
            color="green"
          ></v-progress-circular>
          <br>
          {{ textMessage }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>