import Vue from 'vue'
import VueRouter from 'vue-router'
import SignIn from '../views/SignIn.vue'
import ApplicationList from '../views/ApplicationList.vue'
import ApplicationDetail from '../views/ApplicationDetail.vue'
import FormSetting from '../views/FormSetting.vue'
import UserSetting from '../views/UserSetting.vue'
import UserDetail from '../views/UserDetail.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/SignIn',
      name: 'SignIn',
      component: SignIn
    },
    {
      path: '/',
      name: 'ApplicationList',
      component: ApplicationList
    },
    {
      path: '/ApplicationDetail/:application_id',
      name: 'ApplicationDetail',
      component: ApplicationDetail
    },
    {
      path: '/FormSetting',
      name: 'FormSetting',
      component: FormSetting
    },
    {
      path: '/UserSetting',
      name: 'UserSetting',
      component: UserSetting
    },
    {
      path: "/UserDetail/:userName",
      name: "UserDetail",
      component: UserDetail
    }
  ]
})

import { confirmSignIn } from '@/mixins/index.js'
router.beforeEach(async (to, from, next) => {
  const isSignIn = await confirmSignIn();
  if (to.path === '/SignIn') {
    next();
  } else if (isSignIn) {
    next();
  } else {
    next('/SignIn');
  }
});

export default router
