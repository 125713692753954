<script>
import { defineComponent } from "vue";
import CustomDatePicker from "./CustomDatePicker.vue";
export default defineComponent({
  components: {
    CustomDatePicker
  },
  props: {
    value: Boolean,
    title: String,
    message: String,
    labelCloseBtn: String,
    labelSubmitBtn: String
  },
  data() {
    return {
      // レイアウト
      gridCols: {
        six: 6
      },

      // 入力
      startDate: '',
      endDate: '',

      // バリデーション
      isValidationError: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },
    currentDateList() {
      const dateList = [];

      const date1 = new Date(this.startDate);
      const date2 = new Date(this.endDate);
      const currentDate = new Date(date1);
      while (currentDate <= date2) {
        dateList.push(this.convertDateToYYYYMMDD(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateList;
    },
    isDisabled() {
      // 非活性制御
    },
  },
  methods: {
    validationError() {
      this.isValidationError = true;
    },
    clearValidationError() {
      this.isValidationError = false;
    },
    submit() {
      // 入力された値をsubmit
      this.$emit('submit', this.currentDateList);

      // ダイアログを閉じる
      this.close();
    },
    close() {
      this.show = false;
    },
  }
});
</script>

<template>
  <div>
    <v-dialog v-if="show" v-model="show" width="600">
      <v-card>
        <v-system-bar color="var(--app-color)" dark height="5"/>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col>
                <CustomDatePicker
                  v-model="startDate"
                  type="from"
                  :targetDate="endDate"
                  @validation-error="validationError"
                  @clear-validation-error="clearValidationError"
                />
              </v-col>
              <v-col :cols="1">
                <v-subheader class="px-0 justify-center">〜</v-subheader>
              </v-col>
              <v-col>
                <CustomDatePicker
                  v-model="endDate"
                  type="to"
                  :targetDate="startDate"
                  @validation-error="validationError"
                  @clear-validation-error="clearValidationError"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider/>
        <v-card-actions class="px-3 py-6">
          <v-row>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="var(--app-color)"
                class="white--text"
                :disabled="isDisabled"
                @click="submit"
              >
                {{ labelSubmitBtn }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="grey lighten-2"
                @click="close"
              >
                {{ labelCloseBtn }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
