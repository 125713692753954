<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    src: String,
    title: String,
    required: Boolean
  }
})
</script>

<template>
  <v-container class="mt-3 mb-4">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-img
          :src="src"
          contain
          max-height="300"
        />
        <v-divider class="mt-2" color="black"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
::v-deep .v-image__image--contain {
  background-position: left center !important;
}
</style>
