import state from "@/store/modules/calendar/calendar.state";
import mutations from "@/store/modules/calendar/calendar.mutations";
import actions from "@/store/modules/calendar/calendar.actions";
import getters from "@/store/modules/calendar/calendar.getters";

export default {
  namespaced: false,
  state,
  mutations,
  actions,
  getters,
};
