import {
  SET_CURRENT_DATE_LIST,
  SET_IS_LOADING_GET_RECEPTION_SETTING,
  SET_REQUEST_ID_GET_RECEPTION_SETTING,
  SET_RECEPTION_SETTING_RESULT,
  SET_CURRENT_CURRENT_TIMEZONE,
} from "@/store/mutation-types";
import {
  isOpenFaucet,
  isCloseFaucet,
} from "@/mixins/application.js";

export default {
  [SET_CURRENT_DATE_LIST]: (state, payload) => {
    // 表示日時情報をセット
    const applicationType = payload.applicationType;
    const startDate = payload.startDate;
    const endDate = payload.endDate;

    if (isOpenFaucet(applicationType)) {
      state.startDateOpenFaucet = startDate;
      state.endDateOpenFaucet = endDate;
    } else if (isCloseFaucet(applicationType)) {
      state.startDateCloseFaucet = startDate;
      state.endDateCloseFaucet = endDate;
    }
  },
  [SET_IS_LOADING_GET_RECEPTION_SETTING]: (state, payload) => {
    // 受付設定取得処理のローディングフラグをセット
    const applicationType = payload.applicationType;
    const isLoadingGetReceptionSetting = payload.isLoadingGetReceptionSetting;

    if (isOpenFaucet(applicationType)) {
      state.isLoadingGetReceptionSettingOpenFaucet = isLoadingGetReceptionSetting;
    } else if (isCloseFaucet(applicationType)) {
      state.isLoadingGetReceptionSettingCloseFaucet = isLoadingGetReceptionSetting;
    }
  },
  [SET_REQUEST_ID_GET_RECEPTION_SETTING]: (state, payload) => {
    // 受付設定取得処理のリクエストIDをセット
    const applicationType = payload.applicationType;
    const requestIdGetReceptionSetting = payload.requestIdGetReceptionSetting;

    if (isOpenFaucet(applicationType)) {
      state.requestIdGetReceptionSettingOpenFaucet = requestIdGetReceptionSetting;
    } else if (isCloseFaucet(applicationType)) {
      state.requestIdGetReceptionSettingCloseFaucet = requestIdGetReceptionSetting;
    }
  },
  [SET_RECEPTION_SETTING_RESULT]: (state, payload) => {
    // 受付設定情報をセット
    const applicationType = payload.applicationType;
    const receptionSetting = payload.receptionSetting;

    if (isOpenFaucet(applicationType)) {
      state.receptionSettingOpenFaucet = receptionSetting;
    } else if (isCloseFaucet(applicationType)) {
      state.receptionSettingCloseFaucet = receptionSetting;
    }
  },
  [SET_CURRENT_CURRENT_TIMEZONE]: (state, payload) => {
    // 表示時間枠をセット
    const applicationType = payload.applicationType;
    const currentTimezone = payload.currentTimezone;

    if (isOpenFaucet(applicationType)) {
      state.currentTimezoneOpenFaucet = currentTimezone;
    } else if (isCloseFaucet(applicationType)) {
      state.currentTimezoneCloseFaucet = currentTimezone;
    }
  }
}
