import {
  GET_RECEPTION_SETTING,
  PUT_RECEPTION_SETTING,
  BULK_REGISTRATION_RECEPTION_SETTING,
} from "@/store/action-types";
import {
  SET_IS_LOADING_GET_RECEPTION_SETTING,
  SET_REQUEST_ID_GET_RECEPTION_SETTING,
  SET_RECEPTION_SETTING_RESULT,
} from "@/store/mutation-types";
import {
  isBeforeDate,
  createUniqueStr,
} from "@/mixins/index.js";
import Vue from "vue";

export default {
  [GET_RECEPTION_SETTING]: async ({ getters, commit }, payload) => {
    // ローディング開始
    let payloadSetIsLoadingGetReceptionSetting = {
      applicationType: payload.applicationType,
      isLoadingGetReceptionSetting: true
    };
    commit(SET_IS_LOADING_GET_RECEPTION_SETTING, payloadSetIsLoadingGetReceptionSetting);

    // リクエストIDをセット
    const selfRequestId = createUniqueStr(); // ユニークな文字列を生成
    let payloadSetRequestIdGetReceptionSetting = {
      applicationType: payload.applicationType,
      requestIdGetReceptionSetting: selfRequestId
    };
    commit(SET_REQUEST_ID_GET_RECEPTION_SETTING, payloadSetRequestIdGetReceptionSetting);

    // 受付設定を取得
    const apiUrl = "api/okigas_reception_setting";
    const headers = {
      'content-type': 'application/json'
    };
    const params = {
      application_type: payload.applicationType,
      start_date: payload.startDate,
      end_date: payload.endDate
    };
    await Vue.prototype.$okigasAxios.get(apiUrl, { params: params }, { headers: headers })
      .then((response) => {
        // 通信中に別のAPIが走ってるなら、Stateを更新しない
        const currentRequestId = getters.requestIdGetReceptionSetting(payload.applicationType);
        if (currentRequestId !== selfRequestId) return;

        // State変数にセット
        const receptionSetting = response.data.okigas_reception_setting;
        const comaList = receptionSetting.coma_list;
        const schedule = receptionSetting.schedule;
        const isDisplayUnspecified = receptionSetting.is_display_unspecified;
        if (
          (comaList && Object.keys(comaList).length !== 0) // comaListチェック
          && (schedule && Object.keys(schedule).length !== 0) // scheduleチェック
          && (typeof isDisplayUnspecified === 'boolean') // isDisplayUnspecifiedチェック
        ) {
          let payloadSetReceptionSettingResult = {
            applicationType: payload.applicationType,
            receptionSetting: {
              comaList: comaList,
              schedule: schedule,
              isDisplayUnspecified: isDisplayUnspecified
            },
          };
          commit(SET_RECEPTION_SETTING_RESULT, payloadSetReceptionSettingResult);
        } else {
          // 正しくない値は初期値でリセット
          let payloadSetReceptionSettingResult = {
            applicationType: payload.applicationType,
            receptionSetting: {
              comaList: null,
              schedule: null
            },
            isDisplayUnspecified: true
          };
          commit(SET_RECEPTION_SETTING_RESULT, payloadSetReceptionSettingResult);
        }
      })
      .catch((error) => {
        console.log("error", error)

        // 正しくない値は初期値でリセット
        let payloadSetReceptionSettingResult = {
          applicationType: payload.applicationType,
          receptionSetting: {
            comaList: null,
            schedule: null
          }
        };
        commit(SET_RECEPTION_SETTING_RESULT, payloadSetReceptionSettingResult);

        let title = "";
        let message = "";
        if (error.response.status === 500) {
          title = "システムエラーが発生しました。";
          message = "しばらく時間をおいてから再度お試しください。";
        } else {
          title = "リクエストが失敗しました。";
          message = error.response.data.items.messages[0];
        }
        // TODO ダイアログの出し方検討
        // Vue.prototype.$dialog.show({
        //   title: title,
        //   text: message,
        //   type: "error",
        //   hideBtnCancel: true,
        //   btnConfirmTitle: "閉じる",
        // });
      })
      .finally(() => {
        // 通信中に別のAPIが走ってるなら、Stateを更新しない
        const currentRequestId = getters.requestIdGetReceptionSetting(payload.applicationType);
        if (currentRequestId !== selfRequestId) return;

        // リクエストIDをリセット
        let payloadSetRequestIdGetReceptionSetting = {
          applicationType: payload.applicationType,
          requestIdGetReceptionSetting: null
        };
        commit(SET_REQUEST_ID_GET_RECEPTION_SETTING, payloadSetRequestIdGetReceptionSetting);

        // ローディング終了
        let payloadSetIsLoadingGetReceptionSetting = {
          applicationType: payload.applicationType,
          isLoadingGetReceptionSetting: false
        };
        commit(SET_IS_LOADING_GET_RECEPTION_SETTING, payloadSetIsLoadingGetReceptionSetting);
      });
  },
  [PUT_RECEPTION_SETTING]: async ({ rootState, getters, dispatch }, payload) => {
    const apiUrl = "api/okigas_reception_setting/" + payload.applicationType;
    const headers = {
      'content-type': 'application/json'
    };

    // 過去のスケジュールは自動的に排除
    const onlyAfterSchedule = payload.schedule.filter((scheduleItem) => !isBeforeDate(scheduleItem.date));
    const data = {
      okigas_reception_setting: {
        coma_list: payload.comaList,
        schedule: onlyAfterSchedule,
        is_display_unspecified: payload.isDisplayUnspecified,
        updated_by: rootState.signInUserSession.idToken.payload['cognito:username'] // ログイン中のユーザー名
      }
    };
    await Vue.prototype.$okigasAxios.put(apiUrl, data, { headers: headers })
      .then((response) => {
        // 完了ダイアログを表示
        Vue.prototype.$dialog.show({
          title: "CSVインポート",
          text: "CSVインポートに成功しました。",
          type: "info",
          hideBtnCancel: true,
          btnConfirmTitle: "閉じる",
        });

        let payloadGetReceptionSetting = {
          applicationType: payload.applicationType,
          startDate: getters.startDate(payload.applicationType),
          endDate: getters.endDate(payload.applicationType)
        };
        dispatch(GET_RECEPTION_SETTING, payloadGetReceptionSetting);
      })
      .catch((error) => {
        console.log("error", error)
        Vue.prototype.$dialog.show({
          title: "CSVインポート",
          text: "CSVインポートに失敗しました。",
          type: "error",
          hideBtnCancel: true,
          btnConfirmTitle: "閉じる",
        });
      })
      .finally(() => {});
  },
  [BULK_REGISTRATION_RECEPTION_SETTING]: async ({ rootState, getters, dispatch }, { application_type, term_setting, exclude_setting, reception_setting }) => {
    let result = false;
    const apiUrl = "api/okigas_reception_setting/bulk_registration/" + application_type;
    const headers = {
      'content-type': 'application/json'
    };

    const data = {
      term_setting: term_setting,
      exclude_setting: exclude_setting,
      reception_setting: reception_setting,
      updated_by: rootState.signInUserSession.idToken.payload['cognito:username'] // ログイン中のユーザー名
    };
    await Vue.prototype.$okigasAxios.put(apiUrl, data, { headers: headers })
      .then(() => {
        result = true;

        // 完了ダイアログを表示
        Vue.prototype.$dialog.show({
          title: "スケジュール登録",
          text: "スケジュール登録に成功しました。",
          type: "info",
          hideBtnCancel: true,
          btnConfirmTitle: "閉じる",
        });

        let payloadGetReceptionSetting = {
          applicationType: application_type,
          startDate: getters.startDate(application_type),
          endDate: getters.endDate(application_type)
        };
        dispatch(GET_RECEPTION_SETTING, payloadGetReceptionSetting);
      })
      .catch((error) => {
        result = false;

        let errorMessage = "スケジュール登録に失敗しました。";
        if (error && error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }

        Vue.prototype.$dialog.show({
          title: "スケジュール登録",
          text: errorMessage,
          type: "error",
          hideBtnCancel: true,
          btnConfirmTitle: "閉じる",
        });
      })
      .finally(() => {});

    return result;
  },
}