export default {
  startDateOpenFaucet: null,
  startDateCloseFaucet: null,
  endDateOpenFaucet: null,
  endDateCloseFaucet: null,
  receptionSettingOpenFaucet: null,
  receptionSettingCloseFaucet: null,
  isLoadingGetReceptionSettingOpenFaucet: false,
  isLoadingGetReceptionSettingCloseFaucet: false,
  requestIdGetReceptionSettingOpenFaucet: null,
  requestIdGetReceptionSettingCloseFaucet: null,
  currentTimezoneOpenFaucet: null,
  currentTimezoneCloseFaucet: null,
};
