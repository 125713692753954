<script>
import { defineComponent } from "vue";
import amplifyAuth from "@/mixins/amplify-auth.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_SIGN_IN_USER_SESSION,
} from "@/store/mutation-types";
import {
  NEW_PASSWORD,
} from "@/store/action-types";
export default defineComponent({
  mixins: [amplifyAuth],
  props: {
    user: Object
  },
  data() {
    return {
      // 入力
      password: '',
      showPassword: false,

      // バリデーション
      valid: false,
      passwordRules: [
        (v) => !!v || "パスワードは必須入力です。",
        (v) =>
          (
            v
            && v.length > 0
            && v.match(/[\u0000-\u00ff]/g) !== null
            && v.length === v.match(/[\u0000-\u00ff]/g).length
          ) || "パスワードは半角の英数文字を入力してください。",
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoadingNewPassword,
      errroNewPassword: (state) => state.errroNewPassword,
    }),
    ...mapGetters({
    }),
    hasError() {
      return !this.isEmpty(this.errroNewPassword);
    },
    errorMessage() {
      return (this.hasError) ? this.convertAuthErrorMessages(this.errroNewPassword) : '';
    }
  },
  methods: {
    ...mapMutations({
      setSignInUserSession: SET_SIGN_IN_USER_SESSION,
    }),
    ...mapActions({
      newPassword: NEW_PASSWORD,
    }),
    async handleNewPassword() {
      const user = await this.newPassword({
        user: this.user,
        password: this.password
      });
      console.log('user', user)
      // ログイン
      console.log('login succeeded')
      this.setSignInUserSession(user.signInUserSession);
      this.$router.push('/');
    }
  }
});
</script>

<template>
  <v-card width="600" class="mx-auto">
    <v-container class="pa-6">
      <v-row>
        <v-col>
          <div class="text-center text-h5">
            新しいパスワードを入力してください
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-container>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              autocomplete="off"
              outlined
              dense
              prepend-inner-icon="mdi-shield-key-outline"
              placeholder="パスワード"
              color="var(--app-color)"
              :rules="passwordRules"
            ></v-text-field>
          </v-form>
          <v-alert v-model="hasError" class="body-2" color="red" type="error" text>
            {{ errorMessage }}
          </v-alert>
        </v-container>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="auto">
          <v-btn
            color="var(--app-color)"
            class="white--text"
            :loading="isLoading"
            :disabled="!valid"
            @click="handleNewPassword"
          >
            <v-icon left>mdi-login-variant</v-icon>
            パスワードを変更
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
