<script>
import { defineComponent } from "vue";
import Calendar from "./Calendar.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  PUT_RECEPTION_SETTING,
} from "@/store/action-types";
import {
  UNSPECIFIED_COMA,
  COMA_ID_CSV_HEADER_NAME,
  START_CSV_HEADER_NAME,
  END_CSV_HEADER_NAME,
} from "@/store/modules/calendar/calendar.constants";
import applicationMixins from "@/mixins/application.js";
import Papa from 'papaparse';
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    Calendar,
  },
  props: {
    applicationType: String,
    value: Boolean
  },
  data() {
    return {
      nodata: true,

      // 時間指定なし表示フラグ
      isDisplayUnspecified: true
    };
  },
  computed: {
    // ストア
    ...mapState({
    }),
    ...mapGetters({
      startDateState: 'startDate',
      endDateState: 'endDate',
      receptionSettingState: 'receptionSetting',
      currentTimezoneState: 'currentTimezone'
    }),
    startDate() {
      return this.startDateState(this.applicationType);
    },
    endDate() {
      return this.endDateState(this.applicationType);
    },
    receptionSetting() {
      return this.receptionSettingState(this.applicationType);
    },
    currentTimezone() {
      return this.currentTimezoneState(this.applicationType);
    },

    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },
    currentDateList() {
      const dateList = [];

      const date1 = new Date(this.startDate);
      const date2 = new Date(this.endDate);
      const currentDate = new Date(date1);
      while (currentDate <= date2) {
        dateList.push(this.convertDateToYYYYMMDD(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateList;
    },
    comaList: {
      cache: false,
      get() {
        return this.receptionSetting?.comaList || null;
      }
    },
    schedule: {
      cache: false,
      get() {
        return this.receptionSetting?.schedule || null;
      }
    },
    isAllBeforeDate() {
      // currentDateListの要素が全て過去かどうかを返却
      return (this.isBeforeDate(this.currentDateList[0]) && this.isBeforeDate(this.currentDateList[this.currentDateList.length - 1]));
    },
    isDisabledAddComa() {
      // 「受付可能時間を追加」ボタンの非活性制御
      return (
        this.isAllBeforeDate
      );
    },
    isDisabledExportCsv() {
      // 「CSVダウンロード」ボタンの非活性制御
      return (
        this.nodata
        || this.isAllBeforeDate
      );
    },
    isDisabledSave() {
      // 「保存」ボタンの非活性制御
      return (
        this.nodata
        || this.isAllBeforeDate
      );
    },
  },
  watch: {
    receptionSetting: {
      handler() {
        // 時間指定なし表示フラグを更新
        if (
          !this.isEmpty(this.receptionSetting)
          && typeof this.receptionSetting.isDisplayUnspecified === 'boolean'
        ) {
          this.isDisplayUnspecified = this.receptionSetting.isDisplayUnspecified;
        }
      },
      deep: true
    },
  },
  created() {
    // 時間指定なし表示フラグを更新
    if (
      !this.isEmpty(this.receptionSetting)
      && typeof this.receptionSetting.isDisplayUnspecified === 'boolean'
    ) {
      this.isDisplayUnspecified = this.receptionSetting.isDisplayUnspecified;
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      putReceptionSetting: PUT_RECEPTION_SETTING,
    }),

    // 受付可能時間を追加
    addComa() {
      this.$refs.calendar.addComa();
    },

    // CSVインポート
    handleImportCsv() {
      // ファイル選択をリセット
      this.$refs.inputFile.value = '';
      this.$refs.inputFile.click();
    },
    confirmImportCsv(e) {
      this.$dialog.show({
        title: "CSVインポート",
        text: "インポートされたCSVデータを保存してもよろしいですか？",
        type: "info",
        onConfirm: () => {
          this.importCsv(e);
        },
        btnCancelTitle: "キャンセル",
        btnConfirmTitle: "保存",
      });
    },
    async importCsv(e) {
      const inputFile = e.target.files[0];
      if (inputFile === undefined) return; // ファイル選択されていなければ処理終了

      // CSVファイル読み込み
      const data = await this.readCsv(inputFile);

      // 受付設定オブジェクトに変換
      let comaList = [];
      let schedule = [];
      try {
        if (this.isOpenFaucet(this.applicationType)) {
          // オブジェクトをAPIに投げる形に整形
          comaList = data.map((d) => {
            const comaId = parseInt(d[COMA_ID_CSV_HEADER_NAME]);
            const start = d[START_CSV_HEADER_NAME].replace(/^(\d):(\d\d)$/, '0$1:$2');
            const end = d[END_CSV_HEADER_NAME].replace(/^(\d):(\d\d)$/, '0$1:$2');
            return {
              id: comaId,
              items: {
                start: (comaId === UNSPECIFIED_COMA.comaId) ? UNSPECIFIED_COMA.start : start, // 時間指定なしコマIDなら時間指定なしコマのstart
                end: (comaId === UNSPECIFIED_COMA.comaId) ? UNSPECIFIED_COMA.end : end // 時間指定なしコマIDなら時間指定なしコマのend
              }
            };
          });
          Object.keys(data[0])
            .filter((key) => (key !== COMA_ID_CSV_HEADER_NAME && key !== START_CSV_HEADER_NAME && key !== END_CSV_HEADER_NAME))
            .forEach((date) => {
              const scheduleItem = {
                date: date.replace(/(\d{4})\/(\d{1,2})\/(\d{1,2})/, (match, year, month, day) => `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`), // 日付のフォーマット変換,
                slots: {}
              };
              data.forEach((row) => scheduleItem.slots[row.id] = parseInt(row[date])); // 文字列から数値に変換
              schedule.push(scheduleItem);
            });

        } else if (this.isCloseFaucet(this.applicationType)) {
          // オブジェクトをAPIに投げる形に整形
          comaList = [{ // 閉栓の場合は、時間指定なしコマ一つのみ
            id: UNSPECIFIED_COMA.comaId,
            items: {
              start: UNSPECIFIED_COMA.start,
              end: UNSPECIFIED_COMA.end
            }
          }];
          Object.keys(data[0])
            .filter((key) => (key !== COMA_ID_CSV_HEADER_NAME && key !== START_CSV_HEADER_NAME && key !== END_CSV_HEADER_NAME))
            .forEach((date) => {
              const scheduleItem = {
                date: date.replace(/(\d{4})\/(\d{1,2})\/(\d{1,2})/, (match, year, month, day) => `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`), // 日付のフォーマット変換,
                slots: {}
              };
              scheduleItem['slots'][UNSPECIFIED_COMA.comaId] = parseInt(data[0][date]);
              schedule.push(scheduleItem);
            });
        }

        // 受付設定を保存
        await this.putReceptionSetting({
          applicationType: this.applicationType,
          comaList: comaList,
          schedule: schedule
        });
      } catch (error) {
        console.log(error)
        this.$dialog.show({
          title: "CSVインポート",
          text: "CSVインポートに失敗しました。",
          type: "error",
          hideBtnCancel: true,
          btnConfirmTitle: "閉じる",
        });
      }
    },
    async readCsv(inputFile) {
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          const csv = reader.result;
          const results = Papa.parse(csv, { header: true });
          const csvData = results.data;
          resolve(csvData);
        };
        reader.readAsText(inputFile, 'Shift-JIS'); // Shift-JIS形式で読み込む
      })
    },

    // CSVダウンロード
    exportCsv() {
      // 過去のスケジュールは自動的に排除
      const { comaList, schedule } = this.$refs.calendar.getEditedItem();
      const onlyAfterDateList = schedule.map((s) => s.date).filter((date) => !this.isBeforeDate(date));

      // CSVデータを作成
      let csv = '';
      if (this.isOpenFaucet(this.applicationType)) {
        const csvHeader = `${COMA_ID_CSV_HEADER_NAME},${START_CSV_HEADER_NAME},${END_CSV_HEADER_NAME},` + onlyAfterDateList.map((date) => this.convertYYYYMMDDtoYYYYMD(date)).join(',');
        const csvBody = this.currentTimezone.map((comaId) => {
          const coma = comaList.find((c) => c.id === parseInt(comaId));
          const slots = onlyAfterDateList.map((date) => schedule.find((s) => s.date === date).slots[coma.id]);
          return coma.id + ',' + coma.items.start + ',' + coma.items.end + ',' + slots.join(',');
        }).join('\n');
        csv = csvHeader + '\n' + csvBody;
      } else if (this.isCloseFaucet(this.applicationType)) {
        // 閉栓の場合、id,開始時間,終了時間を表示しない
        const csvHeader = onlyAfterDateList.map((date) => this.convertYYYYMMDDtoYYYYMD(date)).join(',');
        const csvBody = this.currentTimezone.map((comaId) => {
          const coma = comaList.find((c) => c.id === parseInt(comaId));
          const slots = onlyAfterDateList.map((date) => schedule.find((s) => s.date === date).slots[coma.id]);
          return slots.join(',');
        }).join('\n');
        csv = csvHeader + '\n' + csvBody;
      }

      // CSVをダウンロード
      const downloadAt = this.convertDateToYYYYMMDDhhmmss(new Date());
      const filename = `schedule_${downloadAt}.csv`;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8を指定
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      // 完了ダイアログを表示
      this.$dialog.show({
        title: "CSVダウンロード",
        text: "CSVダウンロードが完了しました。",
        type: "info",
        btnCancelTitle: "閉じる",
        hideBtnConfirm: true,
      });
    },

    // 受付設定の更新
    async save() {
      // オブジェクトをAPIに投げる形に整形
      const { comaList, schedule } = this.$refs.calendar.getEditedItem();

      // 受付設定を保存
      await this.putReceptionSetting({
        applicationType: this.applicationType,
        comaList: comaList,
        schedule: schedule,
        isDisplayUnspecified: this.isDisplayUnspecified
      });

      // ダイアログを閉じる
      this.close();
    },
    close() {
      this.show = false;
    },
  }
});
</script>

<template>
  <div>
    <v-dialog v-if="show" v-model="show" width="1000">
      <v-card>
        <v-system-bar color="var(--app-color)" dark height="5"/>
        <v-toolbar flat>
          <v-toolbar-title>受付の設定</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row class="mt-6 align-center">
              <v-col class="pa-0">
                <v-row class="align-center">
                  <v-col class="pa-0">
                    <Calendar
                      ref="calendar"
                      :applicationType="applicationType"
                      :editMode="true"
                      :nodata.sync="nodata"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="isOpenFaucet(applicationType)">
              <v-col class="px-0">
                <v-btn
                  text
                  color="var(--app-color)"
                  class="pa-0"
                  :disabled="isDisabledAddComa"
                  @click="addComa"
                >
                  <v-icon small>mdi-plus</v-icon>
                  受付可能時間を追加
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="isOpenFaucet(applicationType)">
              <v-col class="px-0">
                <v-checkbox v-model="isDisplayUnspecified">
                  <template v-slot:label>
                    時間帯に「時間指定なし」を含める
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-0">
                <v-btn
                  elevation="0"
                  color="var(--app-color)"
                  class="mr-2 white--text"
                  style="width: 180px;"
                  @click="handleImportCsv"
                >
                  <v-icon left>mdi-file-import-outline</v-icon>
                  CSVインポート
                  <input
                    type="file"
                    ref="inputFile"
                    accept=".csv"
                    style="display: none;"
                    @change="confirmImportCsv"
                  />
                </v-btn>
                <v-btn
                  outlined
                  color="var(--app-color)"
                  class="mx-2"
                  style="width: 180px;"
                  :disabled="isDisabledExportCsv"
                  @click="exportCsv"
                >
                  <v-icon left>mdi-file-export-outline</v-icon>
                  CSVダウンロード
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider/>
        <v-card-actions class="px-3 py-6">
          <v-row>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="var(--app-color)"
                class="white--text"
                :disabled="isDisabledSave"
                @click="save"
              >
                保存
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="grey lighten-2"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
