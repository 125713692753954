<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: Array,
    options: Array,
    label: String
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    isSelectedAll() {
      return (this.selected.length === this.options.length);
    },
  },
  watch: {
    value: {
      handler(value) {
        this.selected = value;
      },
      deep: true
    },
    selected: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true
    }
  },
  created() {
    this.selected = this.value;
  },
  methods: {
    isActiveItem(key) {
      return this.selected && this.selected.includes(key);
    },
    toggleSelectAll() {
      this.selected = (this.isSelectedAll) ? [] : this.options.map((o) => o.value);
    }
  }
});
</script>

<template>
  <div>
    <span class="body-2 grey--text text--darken-3">
      {{ label }}
    </span>
    <v-card outlined class="common-search-selector__container">
      <v-list dense class="py-2">
        <v-list-item-group
          v-model="selected"
          multiple
          color="var(--app-color)"
        >
          <v-list-item
            v-for="option in options"
            :key="option.value"
            :value="option.value"
            :class="{ 'common-search-selector__active': isActiveItem(option) }"
          >
            <v-list-item-content>
              <v-list-item-title v-text="option.text"/>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <div class="d-flex justify-end">
      <div class="my-2 body-2 app-color--text" @click="toggleSelectAll">
        {{ (isSelectedAll) ? "全解除" : "全選択" }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.common-search-selector__container {
  height: 100px;
  overflow-y: auto;
}
.common-search-selector__active {
  border-left: 2px solid var(--app-color);
}
</style>
