<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: [String, Number],
    selectDate: String,
    receptionSetting: Object,
    title: String,
    required: Boolean,
    rules: Array,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    reserveQuotaMap() {
      const reserveQuotaMap = {};
      if (this.receptionSetting) {
        const schedule = this.receptionSetting.schedule;
        const comaList = this.receptionSetting.coma_list;

        for (const dateKey in schedule) {
          reserveQuotaMap[dateKey] = [];
          const scheduleDate = schedule[dateKey];

          for (const comaId in comaList) {
            if (scheduleDate[comaId]) {
              const coma = comaList[comaId];
              const reserveTotal = parseInt(scheduleDate[comaId][0], 10);
              const reservedCount = parseInt(scheduleDate[comaId][1], 10);

              reserveQuotaMap[dateKey].push({
                text: (parseInt(comaId) === 1) ? "時間指定なし" : (coma['start'] + '～' + coma['end']), // 時間指定なしのコマであるかチェックする,
                value: coma['start'] + '～' + coma['end'],
                disabled: ((reserveTotal - reservedCount) === 0)
              });
            }
          }
        }
      }
      return reserveQuotaMap;
    },
    selectableTimes() {
      return this.reserveQuotaMap[this.selectDate];
    },
  }
});
</script>

<template>
  <v-container class="mt-3">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <v-select
          v-model="selected"
          :rules="rules"
          :items="selectableTimes"
          item-value="value"
          item-text="text"
          color="var(--app-color)"
          class="pt-0"
          attach
        />
      </v-col>
    </v-row>
  </v-container>
</template>
