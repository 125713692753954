<script>
import { defineComponent } from "vue";
import AddUserDialog from "./AddUserDialog.vue";
import Pagination from "./Pagination.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_USER_SERCH,
} from "@/store/mutation-types";
import {
  GET_USERS,
  GET_USER_GROUPS,
} from "@/store/action-types";
export default defineComponent({
  components: {
    AddUserDialog,
    Pagination
  },
  data() {
    return {
      // 定数
      headers: [
        { text: "ユーザー名", value: "userName", width: "18%" },
        { text: "メールアドレス", value: "email", width: "18%" },
        { text: "ステータス", value: "status", width: "17%" },
        { text: "有効", value: "enabled", width: "7%" },
        { text: "更新日", value: "updatedAt", width: "17%" },
        { text: "作成日", value: "createdAt", width: "17%" },
        { text: "", value: "editButton", sortable: false, width: "6%" }
      ],
      searchAttributeList: [
        { text: "ユーザー名", value: "userName" },
        { text: "メールアドレス", value: "email" },
        { text: "ステータス", value: "status" },
        { text: "有効", value: "enabled" },
        { text: "権限", value: "groupName" }
      ],
      userStatusList: [
        { text: "未承認", value: "UNCONFIRMED", color: "grey" },
        { text: "承認済み", value: "CONFIRMED", color: "green" },
        { text: "パスワード設定必要", value: "RESET_REQUIRED", color: "red" },
        { text: "パスワード更新必要", value: "FORCE_CHANGE_PASSWORD", color: "orange" }
      ],
      userEnabledList: [
        { text: "有効", value: true, color: "var(--app-color)" },
        { text: "無効", value: false, color: "red" }
      ],
      dataColorDefault: '#000000DE',
      dataTextDefault: '--',

      // ユーザー一覧
      items: [],
      perOnPage: 10,
      page: 1,
      selected: [],
      groups: [],

      // ユーザー検索
      searchAttribute: "",
      searchValue: "",

      // ユーザー追加ダイアログ
      addUserDialog: false,
    };
  },
  computed: {
    ...mapState({
      cognitoUsers: (state) => state.cognitoUsers,
      cognitoUserGroups: (state) => state.cognitoUserGroups,
    }),
    ...mapGetters({
      users: 'users',
      userGroups: 'userGroups',
      isLoading: 'isLoadingUser',
    }),
    paginatedItems() {
      const start = (this.page - 1) * this.perOnPage;
      return this.items.slice(start, start + this.perOnPage);
    },
  },
  watch: {
    users: {
      handler(value) {
        this.items = structuredClone(value);
      },
      deep: true
    },
    userGroups: {
      handler(value) {
        this.groups = structuredClone(value);
      },
      deep: true
    }
  },
  async created() {
    await this.getUsers();
    this.items = structuredClone(this.users);
    await this.getUserGroups();
    this.groups = structuredClone(this.userGroups);
  },
  methods: {
    ...mapMutations({
      setUserSearch: SET_USER_SERCH,
    }),
    ...mapActions({
      getUsers: GET_USERS,
      getUserGroups: GET_USER_GROUPS,
    }),
    async refresh() {
      await this.getUsers();
    },
    handleAddUserDialog() {
      this.addUserDialog = !this.addUserDialog;
    },
    search() {
      this.setUserSearch({
        searchAttribute: this.searchAttribute,
        searchValue: this.searchValue
      });
    },
    clearSearch() {
      this.searchAttribute = "";
      this.searchKeyword = "";
    },
    changePage(page) {
      this.page = page;
    },
    getStatusColor(value) {
      const find = this.userStatusList.find((item) => item.value === value);
      return (find) ? find.color : this.dataColotDefault;
    },
    getStatusName(value) {
      const find = this.userStatusList.find((item) => item.value === value);
      return (find) ? find.text : this.dataTextDefault;
    },
    getEnabledColor(value) {
      const find = this.userEnabledList.find((item) => item.value === value);
      return (find) ? find.color : this.dataColotDefault;
    },
    getEnabledName(value) {
      const find = this.userEnabledList.find((item) => item.value === value);
      return (find) ? find.text : this.dataTextDefault;
    },
    goToUserDetail(user) {
      this.$router.push('/UserDetail/' + user.userName);
    }
  }
});
</script>

<template>
  <div>
    <v-toolbar elevation="0">
      <div class="d-flex justify-space-between" style="width: 100%;">
        <v-toolbar-title class="font-weight-bold">
          ユーザー一覧
        </v-toolbar-title>
        <div>
          <v-btn elevation="0" color="blue-grey" class ="mx-3 white--text" :disabled="isLoading" @click="refresh">
            <v-icon left>{{ (isLoading) ? "mdi-cached mdi-spin" : "mdi-cached" }}</v-icon>
            データ更新
          </v-btn>
          <v-btn elevation="0" color="var(--app-color)" class="white--text" @click="handleAddUserDialog">
            <v-icon left>mdi-plus</v-icon>
            新規作成
          </v-btn>
        </div>
      </div>
    </v-toolbar>

    <v-toolbar elevation="0">
      <v-autocomplete
        v-model="searchAttribute"
        :items="searchAttributeList"
        clearable
        outlined
        flat
        dense
        hide-details
        placeholder="検索項目"
        color="var(--app-color)"
        class="mr-3"
      ></v-autocomplete>
      <v-row v-if="searchAttribute === 'status'" no-gutters>
        <v-autocomplete
          v-model="searchValue"
          :items="userStatusList"
          outlined
          dense
          clearable
          hide-details
          placeholder="ステータス"
          color="var(--app-color)"
        ></v-autocomplete>
      </v-row>
      <v-chip-group
        v-else-if="searchAttribute === 'enabled'"
        v-model="searchValue"
        mandatory
        active-class="app-color--text"
        class="mx-2"
      >
        <v-chip
          v-for="item in userEnabledList"
          :key="item.text"
          :value="item.value"
          active
          small
          class="mr-2"
        >
          {{ item.text }}
        </v-chip>
      </v-chip-group>
      <v-row v-else-if="searchAttribute === 'groupName'" no-gutters>
        <v-autocomplete
          v-model="searchValue"
          :items="groups"
          item-text="groupName"
          outlined
          dense
          clearable
          hide-details
          placeholder="権限"
          color="var(--app-color)"
        ></v-autocomplete>
      </v-row>
      <v-text-field
        v-else
        v-model="searchValue"
        hide-details
        outlined
        dense
        clearable
        placeholder="キーワード"
        color="var(--app-color)"
        :disabled="!searchAttribute"
      ></v-text-field>
      <v-btn
        height="40"
        elevation="0"
        color="var(--app-color)"
        class="mx-3 white--text"
        :loading="isLoading"
        @click="search"
      >
        検索
      </v-btn>
      <v-btn
        height="40"
        outlined
        color="blue-grey"
        @click="clearSearch"
      >
        条件クリア
      </v-btn>
    </v-toolbar>

    <div class="elevation-0 mx-3">
      <Pagination
        :totalCount="items.length"
        :page="page"
        :perOnPage="perOnPage"
        :loading="isLoading"
        @changePage="changePage"
      />
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="paginatedItems"
        :height="(paginatedItems.length >= 10) ? 550 : undefined"
        :items-per-page="perOnPage"
        fixed-header
        hide-default-footer
        class="user-list-table"
      >
        <template v-slot:item.status="{ item }">
          <div :style="{ color: getStatusColor(item.status) }">
            {{ getStatusName(item.status) }}
          </div>
        </template>
        <template v-slot:item.enabled="{ item }">
          <div :style="{ color: getEnabledColor(item.enabled)}">
            {{ getEnabledName(item.enabled) }}
          </div>
        </template>
        <template v-slot:item.editButton="{ item }">
          <v-btn width="42" height="24" elevation="0" color="var(--app-color)" class="pa-0" @click="goToUserDetail(item)">
            <div class="white--text" style="font-size:12px;">
              編集
            </div>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <div class="text-left body-2">データはありません。</div>
        </template>
      </v-data-table>
      <Pagination
        :totalCount="items.length"
        :page="page"
        :perOnPage="perOnPage"
        :loading="isLoading"
        @changePage="changePage"
      />
    </div>

    <AddUserDialog v-model="addUserDialog"/>
  </div>
</template>

<style scoped>
::v-deep>.user-list-table>.v-data-table__wrapper>table>.v-data-table-header>tr>th>span {
  font-size: 14px;
}
</style>
