export const SET_SIGN_IN_USER_SESSION = "SET_SIGN_IN_USER_SESSION";
export const SET_ERROR_LOGIN = "SET_ERROR_LOGIN";
export const SET_ERROR_NEW_PASSWORD = "SET_ERROR_NEW_PASSWORD";
export const SET_ERROR_SEND_VERIFY_CODE = "SET_ERROR_SEND_VERIFY_CODE";
export const SET_ERROR_RESET_PASSWORD = "SET_ERROR_RESET_PASSWORD";
export const SET_IS_LOADING_LOGIN = "SET_IS_LOADING_LOGIN";
export const SET_IS_LOADING_NEW_PASSWORD = "SET_IS_LOADING_NEW_PASSWORD";
export const SET_IS_LOADING_SEND_VERIFY_CODE = "SET_IS_LOADING_SEND_VERIFY_CODE";
export const SET_IS_LOADING_RESET_PASSWORD = "SET_IS_LOADING_RESET_PASSWORD";
export const SET_IS_LOADING_LOGOUT = "SET_IS_LOADING_LOGOUT";
export const SET_APPLICATIONS = "SET_APPLICATIONS";
export const ADD_APPLICATIONS = "ADD_APPLICATIONS";
export const SET_APPLICATION_SEARCH = "SET_APPLICATION_SEARCH";
export const SET_IS_LOADING_GET_APPLICATIONS = "SET_IS_LOADING_GET_APPLICATIONS";
export const SET_IS_LOADING_UPDATE_APPLICATION = "SET_IS_LOADING_UPDATE_APPLICATION";
export const SET_IS_LOADING_VALIDATE_APPLICATIONS = "SET_IS_LOADING_VALIDATE_APPLICATIONS";
export const SET_IS_LOADING_INVALIDATE_APPLICATIONS = "SET_IS_LOADING_INVALIDATE_APPLICATIONS";
export const SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS = "SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS";
export const SET_COGNITO_USERS = "SET_COGNITO_USERS";
export const SET_USER_SERCH = "SET_USER_SERCH";
export const SET_IS_LOADING_GET_USERS = "SET_IS_LOADING_GET_USERS";
export const SET_IS_LOADING_CREATE_USER = "SET_IS_LOADING_CREATE_USER";
export const SET_IS_LOADING_ENABLE_USER = "SET_IS_LOADING_ENABLE_USER";
export const SET_IS_LOADING_DISABLE_USER = "SET_IS_LOADING_DISABLE_USER";
export const SET_IS_LOADING_DELETE_USER = "SET_IS_LOADING_DELETE_USER";
export const SET_IS_LOADING_UPDATE_USERS_GROUP = "SET_IS_LOADING_UPDATE_USERS_GROUP";
export const SET_COGNITO_USER_GROUPS = "SET_COGNITO_USER_GROUPS";
export const SET_IS_LOADING_GET_USER_GROUPS = "SET_IS_LOADING_GET_USER_GROUPS";
export const SET_CURRENT_DATE_LIST = "SET_CURRENT_DATE_LIST";
export const SET_IS_LOADING_GET_RECEPTION_SETTING = "SET_IS_LOADING_GET_RECEPTION_SETTING";
export const SET_REQUEST_ID_GET_RECEPTION_SETTING = "SET_REQUEST_ID_GET_RECEPTION_SETTING";
export const SET_RECEPTION_SETTING_RESULT = "SET_RECEPTION_SETTING_RESULT";
export const SET_CURRENT_CURRENT_TIMEZONE = "SET_CURRENT_CURRENT_TIMEZONE";
export const SET_IS_LOADING_GET_RESERVABLE_DATA = "SET_IS_LOADING_GET_RESERVABLE_DATA";
export const SET_ACCEPTABLE_RANGE_SETTING = "SET_ACCEPTABLE_RANGE_SETTING";
export const SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING = "SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING";
export const SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING = "SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING";
export const SET_MAIL_SETTING = "SET_MAIL_SETTING";
export const SET_IS_LOADING_GET_MAIL_SETTING = "SET_IS_LOADING_GET_MAIL_SETTING";
export const SET_IS_LOADING_UPDATE_MAIL_SETTING = "SET_IS_LOADING_UPDATE_MAIL_SETTING";
export const SET_LINK_SETTING = "SET_LINK_SETTING";
export const SET_IS_LOADING_GET_LINK_SETTING = "SET_IS_LOADING_GET_LINK_SETTING";
export const SET_IS_LOADING_PUT_LINK_SETTING = "SET_IS_LOADING_PUT_LINK_SETTING";