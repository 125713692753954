<script>
import { defineComponent } from "vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import CustomChip from "./CustomChip.vue";
import AddExcludeDateFragment from "./AddExcludeDateFragment.vue";
import CustomTimePicker from "./CustomTimePicker.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  BULK_REGISTRATION_RECEPTION_SETTING,
} from "@/store/action-types";
import {
} from "@/store/modules/calendar/calendar.constants";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CustomDatePicker,
    CustomChip,
    AddExcludeDateFragment,
    CustomTimePicker,
  },
  props: {
    applicationType: String,
    value: Boolean
  },
  data() {
    return {
      gridCols: {
        label: 3,
        input: 9
      },

      // 期間設定
      termSetting: {
        start: "",
        end: ""
      },

      // 除外日設定
      excludeSetting: {
        dateList: [],
        excludeHoliday: false
      },

      // 受付設定
      receptionSetting: {
        comaList: [],
        editableStart: null,
        editableEnd: null,
        editableSlots: null,
      },

      // 各値の初期値
      initialValue: {
        termSetting: {
          start: "",
          end: ""
        },

        // 除外日設定
        excludeSetting: {
          dateList: [],
          excludeHoliday: false
        },

        // 受付設定
        receptionSetting: {
          comaList: [],
          editableStart: null,
          editableEnd: null,
          editableSlots: null,
        },
      },
    };
  },
  computed: {
    // store
    ...mapState({
    }),
    ...mapGetters({
    }),

    // props
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },

    // バリデーション
    threeYearsLater() {
      // 3年後の日付を計算
      return this.getYearsLater(3);
    },
    termSettingStartIsRequired() {
      // 期間設定の開始日が入力されているか
      return !this.isEmpty(this.termSetting.start);
    },
    termSettingEndIsRequired() {
      // 期間設定の終了日が入力されているか
      return !this.isEmpty(this.termSetting.end);
    },
    termSettingStartIsEarlyThanEnd() {
      // 期間設定が開始日<終了日になっているか
      return (
        this.termSettingStartIsRequired
        && this.termSettingEndIsRequired
        && (new Date(this.termSetting.start) <= new Date(this.termSetting.end))
      );
    },
    termSettingIsNotIncludePastDate() {
      // 期間設定に過去の日付が含まれていないか
      const todayStart = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      return (
        this.termSettingStartIsRequired
        && this.termSettingEndIsRequired
        && (todayStart <= new Date(this.termSetting.start))
      );
    },
    excludeSettingIsNotDuplicate() {
      // 除外日設定に重複する日付がないか
      return (new Set(this.excludeSetting.dateList)).size === this.excludeSetting.dateList.length;
    },
    excludeSettingIsWithinTermSettingRange() {
      // 除外日設定が全て期間設定内の日付か
      return (
        this.termSettingStartIsRequired
        && this.termSettingEndIsRequired
        && this.excludeSetting.dateList.every((date) => (new Date(this.termSetting.start) <= new Date(date) && new Date(date) <= new Date(this.termSetting.end)))
      );
    },
    receptionSettingComaIsExist() {
      // 受付設定にコマが一つ以上あるか
      return this.receptionSetting.comaList.length > 0;
    },
    receptionSettingComaStartIsEarlyThanComaEnd() {
      // 受付設定のコマが全て、開始時間<終了時間になっているか
      return this.receptionSetting.comaList.every((coma) => {
        // 時間指定なしコマはチェックしない
        if (coma.isUnspecifiedComa) return true;

        const startTime = coma.start;
        const endTime = coma.end;

        // 時間と分に分割して数値として比較
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        // 時間の比較
        console.log('結果', (startHour !== endHour), '?', (startHour < endHour), ':', (startMinute < endMinute))
        if (startHour !== endHour) {
          return startHour < endHour; // 時間が異なる場合は時間で比較
        } else {
          return startMinute < endMinute; // 同じ時間なら分で比較
        }
      });
    },
    receptionSettingSlotsIsExist() {
      // 受付設定の枠数が全て0以上か(負の値ではないか)
      return this.receptionSetting.comaList.every((coma) => (coma.slots >= 0));
    },

    // エラーメッセージの表示
    errorMessage() {
      if (!this.excludeSettingIsNotDuplicate) {
        return "除外日設定の日付が重複しています";
      } else if (!this.excludeSettingIsWithinTermSettingRange) {
        // 期間設定が入力されていないならエラー表示しない
        if (!this.termSettingStartIsRequired || !this.termSettingEndIsRequired) {
          return null;
        }
        return "除外日設定の日付に期間外の日付が含まれています";
      } else if (!this.receptionSettingComaIsExist) {
        return "受付設定の受付可能時間を一つ以上設定して下さい"
      } else if (!this.receptionSettingComaStartIsEarlyThanComaEnd) {
        return "受付設定の受付可能時間の開始時間は終了時間より前になるように設定してください"
      } else if (!this.receptionSettingSlotsIsExist) {
        return "受付設定の枠数は0以上を設定してください"
      } else if (
        // これらのエラーは別途表示しているため、ここでは表示しなくても良い
        !this.termSettingStartIsEarlyThanEnd
        || !this.termSettingIsNotIncludePastDate
      ) {
        return null;
      } else {
        return null;
      }
    },

    // 保存ボタンの非活性制御
    isDisabledSave() {
      return (
        !this.termSettingStartIsRequired
        || !this.termSettingEndIsRequired
        || !this.termSettingStartIsEarlyThanEnd
        || !this.termSettingIsNotIncludePastDate
        || !this.excludeSettingIsNotDuplicate
        || !this.excludeSettingIsWithinTermSettingRange
        || !this.receptionSettingComaIsExist
        || !this.receptionSettingComaStartIsEarlyThanComaEnd
        || !this.receptionSettingSlotsIsExist
      );
    },
  },
  methods: {
    // store
    ...mapMutations({
    }),
    ...mapActions({
      bulkRegistrationReceptionSetting: BULK_REGISTRATION_RECEPTION_SETTING,
    }),

    // 期間設定
    getYearsLater(num) {
      // 今日の日付を取得
      let today = new Date();

      // n年後の日付を計算
      let threeYearsLater = new Date(today);
      threeYearsLater.setFullYear(today.getFullYear() + num);

      // 年、月、日を取得
      let year = threeYearsLater.getFullYear();
      let month = String(threeYearsLater.getMonth() + 1).padStart(2, '0'); // 月は0-indexedなので+1する
      let day = String(threeYearsLater.getDate()).padStart(2, '0');

      // YYYY-MM-DD形式の文字列を作成
      let formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },

    // 除外日設定
    deleteExcludeDate(deleteDate) {
      this.excludeSetting.dateList = this.excludeSetting.dateList.filter((date) => date !== deleteDate);
    },

    // 受付設定
    addComa() {
      // １つ目のコマなら時間指定なしコマ
      const isUnspecifiedComa = (this.receptionSetting.comaList.length === 0);
      const start = "00:00";
      const end = "00:00";
      const slots = 0;

      const newComa = {
        isUnspecifiedComa: isUnspecifiedComa,
        start: start,
        end: end,
        slots: slots
      };
      this.receptionSetting.comaList.push(newComa);
    },
    deleteComa(index) {
      this.receptionSetting.comaList.splice(index, 1);
    },
    isEditableStart(index) {
      return this.receptionSetting.editableStart === index;
    },
    isEditableEnd(index) {
      return this.receptionSetting.editableEnd === index;
    },
    isEditableSlots(index) {
      return this.receptionSetting.editableSlots === index;
    },
    enableToEditStart(index) {
      this.disableToEditAll();
      this.receptionSetting.editableStart = index;
    },
    enableToEditEnd(index) {
      this.disableToEditAll();
      this.receptionSetting.editableEnd = index;
    },
    enableToEditSlots(index) {
      this.disableToEditAll();
      this.receptionSetting.editableSlots = index;
    },
    disableToEditAll() {
      this.receptionSetting.editableStart = null;
      this.receptionSetting.editableEnd = null;
      this.receptionSetting.editableSlots = null;
    },

    // 保存
    async save() {
      // 一括登録APIを呼び出す
      const result = await this.bulkRegistrationReceptionSetting({
        application_type: this.applicationType,
        term_setting: {
          start: this.termSetting.start,
          end: this.termSetting.end
        },
        exclude_setting: {
          date_list: this.excludeSetting.dateList,
          exclude_holiday: this.excludeSetting.excludeHoliday
        },
        reception_setting: this.receptionSetting.comaList.map((coma) => {
          return {
            is_unspecified: coma.isUnspecifiedComa,
            start: coma.start,
            end: coma.end,
            slots: coma.slots
          };
        }),
      });

      // 一括登録APIの実行結果がによって挙動を変える
      if (typeof result === 'boolean' && result === true) {
        // ダイアログを閉じる
        this.close();

        // ダイアログの値を初期化
        this.initialize();
      }
    },

    // 各値を初期値に変更する
    initialize() {
      const initialValue = structuredClone(this.initialValue);

      this.termSetting = initialValue.termSetting;
      this.excludeSetting = initialValue.excludeSetting;
      this.receptionSetting = initialValue.receptionSetting;

      this.addComa();
    },

    // 閉じる
    close() {
      this.show = false;
    },
  },
  created() {
    // 初期表示で１コマ目は追加しておく
    this.addComa();
  },
});
</script>

<template>
  <div>
    <v-dialog v-if="show" v-model="show" width="1000">
      <v-card>
        <v-system-bar color="var(--app-color)" dark height="5"/>
        <v-toolbar flat>
          <v-toolbar-title>受付設定 スケジュール登録</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <!-- 期間設定 -->
            <v-row>
              <v-col :cols="gridCols.label">
                <div>
                  期間（開始日〜終了日）
                </div>
              </v-col>
              <v-col :cols="gridCols.input" class="pb-0">
                <v-container>
                  <v-row class="align-items">
                    <v-col class="pa-0">
                      <CustomDatePicker
                        v-model="termSetting.start"
                        type="from"
                        :targetDate="termSetting.end"
                        :max="threeYearsLater"
                        :hide-details="(termSettingStartIsEarlyThanEnd && termSettingIsNotIncludePastDate)"
                      />
                    </v-col>
                    <v-col cols="auto" class="align-self-center pa-0 mx-4 mb-7">
                      <div>~</div>
                    </v-col>
                    <v-col class="pa-0">
                      <CustomDatePicker
                        v-model="termSetting.end"
                        type="to"
                        :targetDate="termSetting.start"
                        :max="threeYearsLater"
                        :hide-details="(termSettingStartIsEarlyThanEnd && termSettingIsNotIncludePastDate)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <div>
                        期間（開始日〜終了日）は、当日から3年後までの期間で指定してください。
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <!-- 除外日設定 -->
            <v-row>
              <v-col :cols="gridCols.label">
                <div>
                  除外日設定
                </div>
              </v-col>
              <v-col :cols="gridCols.input" class="pb-0">
                <v-container>
                  <v-row>
                    <v-col
                      v-for="date in excludeSetting.dateList"
                      :key="date"
                      cols="auto"
                      class="pa-0 mr-2 mb-2"
                    >
                      <CustomChip
                        :value="date"
                        style="width: 108px;"
                        @close="deleteExcludeDate(date)"
                      />
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                      <AddExcludeDateFragment
                        v-model="excludeSetting.dateList"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pa-0">
                      <v-checkbox
                        v-model="excludeSetting.excludeHoliday"
                        small
                      >
                        <template v-slot:label>
                          <div style="font-size: 14px;">
                            土日・祝日を除く
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <!-- 受付設定 -->
            <v-row>
              <v-col :cols="gridCols.label">
                <div>
                  受付設定
                </div>
              </v-col>
              <v-col :cols="gridCols.input" class="pb-0">
                <v-container>
                  <v-row class="align-center">
                    <v-col class="pa-0">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="data-control"><!-- 空白 --></th>
                              <th class="text-center data-head pt-3 pb-3">開始</th>
                              <th class="data-control"><!-- 空白 --></th>
                              <th class="text-center data-head pt-3 pb-3">終了</th>
                              <th class="text-center data-cell pt-3 pb-3">枠数</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(row, index) in receptionSetting.comaList" :key="index">
                              <div v-if="row.isUnspecifiedComa" class="not-exist">
                                <td class="data-control"><!-- 空白 --></td>
                                <td class="text-center data-head" :colspan="3">
                                  <!-- 時間指定なしの場合 -->
                                  <div>時間指定なし</div>
                                </td>
                              </div>
                              <div v-else class="not-exist">
                                <!-- 時間指定なしではない場合 -->
                                <td class="text-center data-control">
                                  <v-icon color="error" @click="deleteComa(index)">mdi-close-circle</v-icon>
                                </td>
                                <td class="text-center data-head">
                                  <div v-if="isEditableStart(index)">
                                    <!-- 編集時 -->
                                    <CustomTimePicker v-model="row.start"/>
                                  </div>
                                  <div v-else @click="enableToEditStart(index)">
                                    <!-- 閲覧時 -->
                                    {{ row.start }}
                                  </div>
                                </td>
                                <td class="text-center data-head">〜</td>
                                <td class="text-center data-head">
                                  <div v-if="isEditableEnd(index)">
                                    <!-- 編集時 -->
                                    <CustomTimePicker v-model="row.end"/>
                                  </div>
                                  <div v-else @click="enableToEditEnd(index)">
                                    <!-- 閲覧時 -->
                                    {{ row.end }}
                                  </div>
                                </td>
                              </div>
                              <td class="text-center data-cell pt-3 pb-3">
                                <div v-if="isEditableSlots(index)">
                                  <!-- 編集時 -->
                                  <v-text-field
                                    type="number"
                                    v-model.number="row.slots"
                                    max="9999"
                                    single-line
                                    hide-details
                                    dense
                                    class="mx-auto"
                                    style="max-width: 50px; font-size: 15px;"
                                    color="var(--app-color)"
                                  ></v-text-field>
                                </div>
                                <div v-else>
                                  <!-- 閲覧時 -->
                                  <p @click="enableToEditSlots(index)">
                                    {{ row.slots }}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row v-if="isOpenFaucet(applicationType)">
                    <v-col class="px-0">
                      <v-btn
                        text
                        color="var(--app-color)"
                        class="pa-0"
                        @click="addComa"
                      >
                        <v-icon small>mdi-plus</v-icon>
                        受付可能時間を追加
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>

            <!-- エラーメッセージ -->
            <v-row v-if="errorMessage">
              <v-col class="pt-0">
                <div class="error--text">
                  {{ errorMessage }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider/>
        <v-card-actions class="px-3 py-6">
          <v-row>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="var(--app-color)"
                class="white--text"
                :disabled="isDisabledSave"
                @click="save"
              >
                保存
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                width="100%"
                elevation="1"
                color="grey lighten-2"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
p {
  text-align: center !important;
  vertical-align: middle !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.not-exist {
  display: contents;
  vertical-align: middle;
}

tr > th:first-child,
tr > td:first-child,
tr > div.not-exist > td:first-child {
  border-left: solid 1px lightgray !important;
}
tr > th:last-child,
tr > td:last-child {
  border-right: solid 1px lightgray !important;
}

th.data-control {
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
}
th.data-head {
  border-top: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
  width: 25%;
}
th.data-cell {
  border-top: solid 1px lightgray !important;
  border-left: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
  width: 30%;
  cursor: default;
}

td.data-control {
  border-bottom: solid 1px lightgray !important;
}
td.data-head {
  border-bottom: solid 1px lightgray !important;
}
td.data-cell {
  border-left: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
  vertical-align: middle !important;
}
</style>
