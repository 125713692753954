import {
  STATUS_ENUM,
  STATUS_ENUM_DISP,
  APPLICATION_TYPE_ENUM,
  GAS_KIND_ENUM,
  ACCOUNT_TYPE_ENUM,
  BANK_TYPE_ENUM,
  IS_BANK_DESIGNATION_ENUM,
  IS_LESS_THAN_3MONTH_ENUM,
  IS_USE_ELECTRICITY_ENUM,
  ELECTRICITY_START_SELECT_ENUM,
  IS_ELECTRICITY_NEWS_ENUM,
  IS_SET_DISCOUNT_ENUM,
  WEB_ITEMIZED_CHARGES_1,
  WEB_ITEMIZED_CHARGES_2,
  GAS_PAYMENT_ENUM_1,
  GAS_PAYMENT_ENUM_2,
  IS_USED_OKIGAS_ELECTRICITY_ENUM,
  IS_SUSPEND_ELECTRICITY_ENUM,
  IS_CONTINUOUS_USE_ELECTRICITY_ENUM,
  LAST_GAS_PAYMENT_TYPE_ENUM,
  OKIGAS_ELECTRICITY_NEW_USE_ENUM,
  IS_ELECTRICITY_CHARGE_NOTIFICATION_ENUM,
  EMAIL_SEND_STATE_ENUM,
  STATUS_COLOR_DEFAULT,
  OKIGAS_ELECTRICITY_ENUM,
} from "@/constants/application.js";
import {
  NON_DATA_TEXT,
} from "@/constants/index.js";
import {
  isEmpty,
  isArrayContainElem,
  isStringContainString,
  isDateAfterFromDate,
  isDateAfterToDate,
  isDateTimeInRange,
} from "@/mixins/index.js";

// 開栓のapplication_typeを返却
export const openFaucet = () => {
  return APPLICATION_TYPE_ENUM[0].value;
};

// 開栓のapplication_typeを返却
export const closeFaucet = () => {
  return APPLICATION_TYPE_ENUM[1].value;
};

// electricity_start_selectのでんきとガスご利用開始日は同じの値を返却
export const samePreferredDate = () => {
  return ELECTRICITY_START_SELECT_ENUM[0].value;
};

// application_typeが開栓か判定
export const isOpenFaucet = (application_type) => {
  return (application_type === openFaucet());
};

// application_typeが閉栓か判定
export const isCloseFaucet = (application_type) => {
  return (application_type === closeFaucet());
};

// electricity_start_selectがでんきとガスご利用開始日は同じか判定
export const isSamePreferredDate = (electricity_start_select) => {
  return (electricity_start_select === samePreferredDate());
};

// reception_noを数値に変換
export const convertReceptionNoToNumeric = (reception_no) => {
  // "-" を除去して数値部分を取得
  const numbersOnly = reception_no.replace("-", "");

  // 数値に変換
  const numericValue = parseInt(numbersOnly);

  return numericValue;
};

// statusを色に変換
export const convertStatusToColor = (status) => {
  const find = STATUS_ENUM.find((obj) => obj.value === status);
  return (find) ? find.color : STATUS_COLOR_DEFAULT;
};

// statusをテキストに変換
export const convertStatusToText = (status) => {
  const find = STATUS_ENUM.find((obj) => obj.value === status);
  return (find) ? find.text : NON_DATA_TEXT;
};

// application_typeをテキストに変換
export const convertApplicationTypeToText = (application_type) => {
  const find = APPLICATION_TYPE_ENUM.find((obj) => obj.value === application_type);
  return (find) ? find.text : NON_DATA_TEXT;
};

// gas_kindをテキストに変換
export const convertGasKindToText = (gas_kind) => {
  const find = GAS_KIND_ENUM.find((obj) => obj.value === gas_kind);
  return (find) ? find.text : '';
};

// is_less_than_3monthsをテキストに変換
export const convertIsLessThan3monthsToText = (is_less_than_3months) => {
  const find = IS_LESS_THAN_3MONTH_ENUM.find((obj) => obj.value === is_less_than_3months);
  return (find) ? find.text : '';
};

// is_use_electricityをテキストに変換
export const convertIsUseElectricityToText = (is_use_electricity) => {
  const find = IS_USE_ELECTRICITY_ENUM.find((obj) => obj.value === is_use_electricity);
  return (find) ? find.text : '';
};

// web_itemized_chargesをテキストに変換
export const convertWebItemizedChargesToText = (web_itemized_charges) => {
  const find = WEB_ITEMIZED_CHARGES_2.find((obj) => obj.value === web_itemized_charges);
  return (find) ? find.text : '';
};

// electricity_start_selectをテキストに変換
export const convertElectricityStartSelectToText = (electricity_start_select) => {
  const find = ELECTRICITY_START_SELECT_ENUM.find((obj) => obj.value === electricity_start_select);
  return (find) ? find.text : '';
};

// is_electricity_newsをテキストに変換
export const convertIsElectricityNewsToText = (is_electricity_news) => {
  const find = IS_ELECTRICITY_NEWS_ENUM.find((obj) => obj.value === is_electricity_news);
  return (find) ? find.text : '';
};

// is_set_discountをテキストに変換
export const convertIsSetDiscountToText = (is_set_discount) => {
  const find = IS_SET_DISCOUNT_ENUM.find((obj) => obj.value === is_set_discount);
  return (find) ? find.text : '';
};

// last_gas_payment_typeをテキストに変換
export const convertLastGasPaymentTypeToText = (last_gas_payment_type) => {
  const find = LAST_GAS_PAYMENT_TYPE_ENUM.find((obj) => obj.value === last_gas_payment_type);
  return (find) ? find.text : '';
};

// bank_typeをテキストに変換
export const convertBankTypeToText = (bank_type) => {
  const find = BANK_TYPE_ENUM.find((obj) => obj.value === bank_type);
  return (find) ? find.text : '';
};

// account_typeをテキストに変換
export const convertAccountTypeToText = (account_type) => {
  const find = ACCOUNT_TYPE_ENUM.find((obj) => obj.value === account_type);
  return (find) ? find.text : '';
};

// 検索条件とapplication型の配列を受け取り、検索結果を返却する
export const applicationSearch = (applications, applicationSearch) => {
  return applications.filter((a) => {
    // 全ての項目が選択されていない状態の絞り込み
    // 全ての項目が選択されていない状態
    const isDefault = (
      (applicationSearch.status.length === 0)
      && (applicationSearch.gas_kind.length === 0)
      && (applicationSearch.application_type.length === 0)
      && (applicationSearch.okigas_electricity.length === 0)
      && (applicationSearch.is_set_discount.length === 0)
      && (applicationSearch.email_send_state.length === 0)
      && (applicationSearch.reception_no.min === "")
      && (applicationSearch.reception_no.max === "")
      && (applicationSearch.applicant_last_name === "")
      && (applicationSearch.applicant_first_name === "")
      && (applicationSearch.last_name === "")
      && (applicationSearch.first_name === "")
      && (applicationSearch.last_name_kana === "")
      && (applicationSearch.first_name_kana === "")
      && (isEmpty(applicationSearch.created_at_start))
      && (isEmpty(applicationSearch.created_at_end))
      && (isEmpty(applicationSearch.preferred_date_start))
      && (isEmpty(applicationSearch.preferred_date_end))
      && (isEmpty(applicationSearch.from_updated_at))
      && (isEmpty(applicationSearch.to_updated_at))
      && (isEmpty(applicationSearch.from_download_at))
      && (isEmpty(applicationSearch.to_download_at))
      && (isEmpty(applicationSearch.charge_number))
    );
    // ステータスが完了もしくはダウンロード済みキャンセル以外のお申し込みを表示
    const defaultFilter = (!isDefault) ? true
      : (a.status === undefined) ? false
        : (a.status !== "complete" && a.status !== "cancel_downloaded");

    // 受付状態
    const status = (a.status === "cancel_downloaded") ? "cancel" : a.status; // ダウンロード済みキャンセルをキャンセルとして扱う
    const statusFilter = (applicationSearch.status.length === 0) ? true
      : (status === undefined) ? false
        : isArrayContainElem(applicationSearch.status, status);

    // ガス種
    const gasKindFilter = (applicationSearch.gas_kind.length === 0) ? true
      : (a.gas_kind === undefined) ? false
        : isArrayContainElem(applicationSearch.gas_kind, a.gas_kind);

    // 申し込み内容
    const contentTypeFilter = (applicationSearch.application_type.length === 0) ? true
      : (a.application_type === undefined) ? false
        : isArrayContainElem(applicationSearch.application_type, a.application_type);

    // 沖縄ガスのでんき申し込み
    const searchValueIsUseElectricity = applicationSearch.okigas_electricity.filter((v) => v === 0 || v === 1).map((v) => (v === 0) ? 1 : 0);
    const isUseElectricityFilter = (a.is_use_electricity === undefined) ? false
      : isArrayContainElem(searchValueIsUseElectricity, a.is_use_electricity);

    const searchValueIsContinuousUseElectricity = applicationSearch.okigas_electricity.filter((v) => v === 2 || v === 3).map((v) => (v === 2) ? 1 : 0);
    const isContinuousUseElectricityFilter = (a.is_continuous_use_electricity === undefined) ? false
      : isArrayContainElem(searchValueIsContinuousUseElectricity, a.is_continuous_use_electricity);

    const searchValueOkigasElectricityNewUse = applicationSearch.okigas_electricity.filter((v) => v === 4 || v === 5).map((v) => (v === 4) ? 1 : 0);
    const okigasElectricityNewUseFilter = (a.okigas_electricity_new_use === undefined) ? false
      : isArrayContainElem(searchValueOkigasElectricityNewUse, a.okigas_electricity_new_use);

    const okigasElectricityFilter = (applicationSearch.okigas_electricity.length === 0) ? true
      : (isUseElectricityFilter || isContinuousUseElectricityFilter || okigasElectricityNewUseFilter);

    // セット割の申し込み
    const isSetDiscountFilter = (applicationSearch.is_set_discount.length === 0) ? true
      : (a.is_set_discount === undefined) ? false
        : isArrayContainElem(applicationSearch.is_set_discount, a.is_set_discount);

    // 申し込み完了メール
    const completionMailStatusFilter = (applicationSearch.email_send_state.length === 0) ? true
      : (a.email_send_state === undefined) ? false
        : isArrayContainElem(applicationSearch.email_send_state, a.email_send_state);

    // 受付番号
    let receptionNoMinFilter = false;
    let receptionNoMaxFilter = false;
    if (applicationSearch.reception_no.min === "" && applicationSearch.reception_no.max === "") {
      receptionNoMinFilter = true;
      receptionNoMaxFilter = true;
    } else if (a.reception_no !== undefined) {
      // 受付番号を数値に変換
      const numericReceptionNo = convertReceptionNoToNumeric(a.reception_no);
      const numericMin = convertReceptionNoToNumeric(applicationSearch.reception_no.min);
      const numericMax = convertReceptionNoToNumeric(applicationSearch.reception_no.max);
      receptionNoMinFilter = (applicationSearch.reception_no.min === "") ? true
        : (numericMin <= numericReceptionNo); // 最小値検索
      receptionNoMaxFilter = (applicationSearch.reception_no.max === "") ? true
        : (numericReceptionNo <= numericMax); // 最大値検索
    }
    const receptionNoFilter = (receptionNoMinFilter && receptionNoMaxFilter);

    // 申込者(姓)
    const applicantLastNameFilter = (applicationSearch.applicant_last_name === "") ? true
      : (a.applicant_last_name === undefined) ? false
        : isStringContainString(a.applicant_last_name, applicationSearch.applicant_last_name);

    // 申込者(名)
    const applicantFirstNameFilter = (applicationSearch.applicant_first_name === "") ? true
      : (a.applicant_first_name === undefined) ? false
        : isStringContainString(a.applicant_first_name, applicationSearch.applicant_first_name);

    // 契約者(姓)
    const lastNameFilter = (applicationSearch.last_name === "") ? true
      : (a.last_name === undefined) ? false
        : isStringContainString(a.last_name, applicationSearch.last_name);

    // 契約者(名)
    const firstNameFilter = (applicationSearch.first_name === "") ? true
      : (a.first_name === undefined) ? false
        : isStringContainString(a.first_name, applicationSearch.first_name);

    // 契約者(セイ)
    const lastNameKanaFilter = (applicationSearch.last_name_kana === "") ? true
      : (a.last_name_kana === undefined) ? false
        : isStringContainString(a.last_name_kana, applicationSearch.last_name_kana);

    // 契約者(メイ)
    const firstNameKanaFilter = (applicationSearch.first_name_kana === "") ? true
      : (a.first_name_kana === undefined) ? false
        : isStringContainString(a.first_name_kana, applicationSearch.first_name_kana);

    // お申込日範囲検索
    const created_at_start = applicationSearch.created_at_start;
    const created_at_end = applicationSearch.created_at_end;
    const created_at = a.created_at;

    let createdAtRangeFilter;
    if (isEmpty(created_at_start) && isEmpty(created_at_end)) {
      // 開始日と終了日が設定されていない場合
      createdAtRangeFilter = true;
    } else if (!isEmpty(created_at_start) && isEmpty(created_at_end)) {
      // 開始日のみ設定されている場合
      createdAtRangeFilter = isEmpty(created_at) ? false : isDateAfterFromDate(created_at, created_at_start);
    } else if (isEmpty(created_at_start) && !isEmpty(created_at_end)) {
      // 終了日のみ設定されている場合
      createdAtRangeFilter = isEmpty(created_at) ? false : isDateAfterToDate(created_at, created_at_end);
    } else {
      // 開始日と終了日が設定されている場合
      createdAtRangeFilter = isEmpty(created_at) ? false : isDateTimeInRange(created_at, created_at_start, created_at_end);
    }

    // 希望日
    const preferred_date_start = applicationSearch.preferred_date_start;
    const preferred_date_end = applicationSearch.preferred_date_end;
    let preferred_date;
    // 開栓 or 閉栓か判定する
    if (isOpenFaucet(a.application_type)) {
      // ガスのご利用開始希望日を指定
      preferred_date = a.preferred_date;
    } else if (isCloseFaucet(a.application_type)) {
      // ガスのご利用停止希望日日を指定
      preferred_date = a.gas_last_use_date;
    }

    let preferredDateRangeFilter;
    if (isEmpty(preferred_date_start) && isEmpty(preferred_date_end)) {
      // 開始日と終了日が設定されていない場合
      preferredDateRangeFilter = true;
    } else if(!isEmpty(preferred_date_start) && isEmpty(preferred_date_end)) {
      // 開始日のみ設定されている場合
      preferredDateRangeFilter = isEmpty(preferred_date) ? false : isDateAfterFromDate(preferred_date, preferred_date_start);
    } else if(isEmpty(preferred_date_start) && !isEmpty(preferred_date_end)) {
      // 終了日のみ設定されている場合
      preferredDateRangeFilter = isEmpty(preferred_date) ? false : isDateAfterToDate(preferred_date, preferred_date_end);
    } else {
      // 開始日と終了日が設定されている場合
      preferredDateRangeFilter = isEmpty(preferred_date) ? false : isDateTimeInRange(preferred_date, preferred_date_start, preferred_date_end);
    }

    // 更新日時
    const from_updated_at = applicationSearch.from_updated_at;
    const to_updated_at = applicationSearch.to_updated_at;
    const updated_at = a.updated_at;

    let updatedAtRangeFilter;
    if (isEmpty(from_updated_at) && isEmpty(to_updated_at)) {
      // 開始日と終了日が設定されていない場合
      updatedAtRangeFilter = true;
    } else if (!isEmpty(from_updated_at) && isEmpty(to_updated_at)) {
      // 開始日のみ設定されている場合
      updatedAtRangeFilter = isEmpty(updated_at) ? false : isDateAfterFromDate(updated_at, from_updated_at);
    } else if (isEmpty(from_updated_at) && !isEmpty(to_updated_at)) {
      // 終了日のみ設定されている場合
      updatedAtRangeFilter = isEmpty(updated_at) ? false : isDateAfterToDate(updated_at, to_updated_at);
    } else {
      // 開始日と終了日が設定されている場合
      updatedAtRangeFilter = isEmpty(updated_at) ? false : isDateTimeInRange(updated_at, from_updated_at, to_updated_at);
    }

    // 最終ダウンロード日時
    const from_download_at = applicationSearch.from_download_at;
    const to_download_at = applicationSearch.to_download_at;
    const download_at = a.download_at;

    let downloadAtRangeFilter;
    if (isEmpty(from_download_at) && isEmpty(to_download_at)) {
      // ダウンロード開始日とダウンロード終了日が設定されていない場合
      downloadAtRangeFilter = true
    } else if (!isEmpty(from_download_at) && isEmpty(to_download_at)) {
      // ダウンロード開始日のみ設定されている場合
      downloadAtRangeFilter = isEmpty(download_at) ? false : isDateAfterFromDate(download_at, from_download_at);
    } else if (isEmpty(from_download_at) && !isEmpty(to_download_at)) {
      // ダウンロード終了日のみ設定されている場合
      downloadAtRangeFilter = isEmpty(download_at) ? false : isDateAfterToDate(download_at, to_download_at);
    } else {
      // ダウンロード開始日とダウンロード終了日が設定されている場合
      downloadAtRangeFilter = isEmpty(download_at) ? false : isDateTimeInRange(download_at, from_download_at, to_download_at);
    }

    // ガス料金番号
    const chargeNumberFilter = (applicationSearch.charge_number === "") ? true
      : (a.charge_number === undefined) ? false
        : isStringContainString(a.charge_number, applicationSearch.charge_number);

    return (
      defaultFilter
      && statusFilter
      && gasKindFilter
      && contentTypeFilter
      && okigasElectricityFilter
      && isSetDiscountFilter
      && completionMailStatusFilter
      && receptionNoFilter
      && applicantLastNameFilter
      && applicantFirstNameFilter
      && lastNameFilter
      && firstNameFilter
      && lastNameKanaFilter
      && firstNameKanaFilter
      && createdAtRangeFilter
      && preferredDateRangeFilter
      && updatedAtRangeFilter
      && downloadAtRangeFilter
      && chargeNumberFilter
    );
  });
};

export default {
  data() {
    return {
      STATUS_ENUM,
      STATUS_ENUM_DISP,
      APPLICATION_TYPE_ENUM,
      GAS_KIND_ENUM,
      ACCOUNT_TYPE_ENUM,
      BANK_TYPE_ENUM,
      IS_BANK_DESIGNATION_ENUM,
      IS_LESS_THAN_3MONTH_ENUM,
      IS_USE_ELECTRICITY_ENUM,
      ELECTRICITY_START_SELECT_ENUM,
      IS_ELECTRICITY_NEWS_ENUM,
      IS_SET_DISCOUNT_ENUM,
      WEB_ITEMIZED_CHARGES_1,
      WEB_ITEMIZED_CHARGES_2,
      GAS_PAYMENT_ENUM_1,
      GAS_PAYMENT_ENUM_2,
      IS_USED_OKIGAS_ELECTRICITY_ENUM,
      IS_SUSPEND_ELECTRICITY_ENUM,
      IS_CONTINUOUS_USE_ELECTRICITY_ENUM,
      LAST_GAS_PAYMENT_TYPE_ENUM,
      OKIGAS_ELECTRICITY_NEW_USE_ENUM,
      IS_ELECTRICITY_CHARGE_NOTIFICATION_ENUM,
      EMAIL_SEND_STATE_ENUM,
      STATUS_COLOR_DEFAULT,
      OKIGAS_ELECTRICITY_ENUM,
    };
  },
  computed: {
    openFaucet,
    closeFaucet,
  },
  methods: {
    isOpenFaucet,
    isCloseFaucet,
    isSamePreferredDate,
    convertReceptionNoToNumeric,
    convertStatusToColor,
    convertStatusToText,
    convertApplicationTypeToText,
    convertGasKindToText,
    convertIsLessThan3monthsToText,
    convertIsUseElectricityToText,
    convertWebItemizedChargesToText,
    convertElectricityStartSelectToText,
    convertIsElectricityNewsToText,
    convertIsSetDiscountToText,
    convertLastGasPaymentTypeToText,
    convertBankTypeToText,
    convertAccountTypeToText,
  },
}