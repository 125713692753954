<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: Array
  },
  data() {
    return {
      isOpenMenu: false,
      selectedDate: "",
    };
  },
  watch: {
    selectedDate: {
      handler(val) {
        // 新たな日付が選択された時のみ値を更新してメニューを閉じる
        if (val !== "") {
          // 前回と同じ値の場合、値は更新しない
          if (!this.value.includes(val)) {
            const newDateList = [...this.value, val];
            this.$emit('input', newDateList);
          }
          this.isOpenMenu = false;
        }
      }
    }
  },
  methods: {
    toggleMenu() {
      this.selectedDate = "";
      this.isOpenMenu = !this.isOpenMenu;
    },
  },
});
</script>

<template>
  <v-menu
    v-model="isOpenMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="0" dark color="var(--app-color)" @click="toggleMenu">
        <div style="font-size: 14px;">除外日を追加</div>
      </v-btn>
    </template>
    <v-date-picker
      v-model="selectedDate"
      locale="ja"
      no-title
      color="var(--app-color)"
    />
  </v-menu>
</template>
