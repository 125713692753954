import axios from 'axios'

const OkigasAxiosPlugin = {}

OkigasAxiosPlugin.install = function (Vue) {
  Vue.prototype.$okigasAxios = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_ENDPOINT,
    headers: {
        'x-api-key': import.meta.env.VITE_X_API_KEY
    },
  })
}

export default OkigasAxiosPlugin;