<script>
import { defineComponent } from "vue";
import SubHeader from "../components/Header/SubHeader.vue";
import UserList from "../components/UserSetting/UserList.vue";
export default defineComponent({
  components: {
    SubHeader,
    UserList
  },
});
</script>

<template>
  <div>
    <SubHeader/>
    <v-container class="pa-0">
      <v-card outlined class="my-4 mx-auto">
        <v-container>
          <UserList/>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>
