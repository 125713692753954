<script>
import { defineComponent } from "vue";
import SettingButton from "./SettingButton.vue";
import Calendar from "./Calendar.vue";
import BulkRegistrationDialog from "./BulkRegistrationDialog/index.vue";
import ReceptionSettingDialog from "./ReceptionSettingDialog.vue";
import AcceptableRangeSettingDialog from "./AcceptableRangeSettingDialog.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_RECEPTION_SETTING,
  GET_ACCEPTABLE_RANGE_SETTING,
  PUT_ACCEPTABLE_RANGE_SETTING,
} from "@/store/action-types";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    SettingButton,
    Calendar,
    BulkRegistrationDialog,
    ReceptionSettingDialog,
    AcceptableRangeSettingDialog
  },
  props: {
    applicationType: String
  },
  data() {
    return {
      gridCols: {
        label: 2,
        input: 10
      },
      acceptableRangeSettingLocal: {
        acceptable_range_day_count: 1,
        acceptable_range_month_count: 1,
        acceptable_range_partial_setting: []
      },
      acceptableRangeSettingDefault: {
        acceptable_range_day_count: 1,
        acceptable_range_month_count: 1,
        acceptable_range_partial_setting: []
      },

      // スケジュール登録ダイアログ
      bulkRegistrationDialog: false,

      // 受付設定ダイアログ
      receptionSettingDialog: false,

      // 受付範囲設定ダイアログ
      acceptableRangeSettingDialog: false
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      acceptableRangeSettingState: 'acceptableRangeSetting',
    }),
    acceptableRangeSetting() {
      return this.acceptableRangeSettingState(this.applicationType);
    },
    acceptableRangeDayCount() {
      return this.acceptableRangeSettingLocal?.acceptable_range_day_count;
    },
    acceptableRangeMonthCount() {
      return this.acceptableRangeSettingLocal?.acceptable_range_month_count;
    },
    acceptableRangePartialSetting() {
      return this.acceptableRangeSettingLocal?.acceptable_range_partial_setting;
    },
  },
  watch: {
    acceptableRangeSetting: {
      handler(value) {
        this.acceptableRangeSettingLocal = (value) ? structuredClone(value) : structuredClone(this.acceptableRangeSettingDefault);
      },
      deep: true
    }
  },
  async created() {
    // 受付設定取得
    const currentDate = new Date(); // 現在の日時を取得
    const currentDay = currentDate.getDay(); // 現在の曜日 (0: 日曜, 1: 月曜, ..., 6: 土曜)
    const mondayDate = new Date(currentDate);
    mondayDate.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // 月曜日の日付を取得
    const sundayDate = new Date(mondayDate);
    sundayDate.setDate(mondayDate.getDate() + 6); // 日曜日の日付を取得
    await this.handleGetReceptionSetting(mondayDate, sundayDate);

    // 受付可能範囲設定取得
    await this.handleGetAcceptableRangeSetting();
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getReceptionSetting: GET_RECEPTION_SETTING,
      getAcceptableRangeSetting: GET_ACCEPTABLE_RANGE_SETTING,
      putAcceptableRangeSetting: PUT_ACCEPTABLE_RANGE_SETTING,
    }),
    handleBulkRegistrationDialog() {
      this.bulkRegistrationDialog = !this.bulkRegistrationDialog;
    },
    handleReceptionSettingDialog() {
      this.receptionSettingDialog = !this.receptionSettingDialog;
    },
    handleAcceptableRangeSettingDialog() {
      this.acceptableRangeSettingDialog = !this.acceptableRangeSettingDialog;
    },
    async handleGetReceptionSetting(startDate, endDate) {
      let payload = {
        applicationType: this.applicationType,
        startDate: this.convertDateToYYYYMMDD(startDate),
        endDate: this.convertDateToYYYYMMDD(endDate)
      };
      await this.getReceptionSetting(payload);
    },
    async handleGetAcceptableRangeSetting() {
      // 受付可能範囲の個別設定は1週間分のみ表示(6日前から表示)
      const today = this.getToday();
      const oneWeeksAgo = this.calcSubDate(today, 6);
      const minDate = this.convertDateToYYYYMMDD(oneWeeksAgo);

      let payload = {
        applicationType: this.applicationType,
        minDate: minDate
      };
      await this.getAcceptableRangeSetting(payload);
    },
    async handlePutAcceptableRangeSetting(acceptableRangeSetting) {
      // 受付可能範囲の個別設定は1週間分のみ表示(6日前から表示)
      const today = this.getToday();
      const oneWeeksAgo = this.calcSubDate(today, 6);
      const minDate = this.convertDateToYYYYMMDD(oneWeeksAgo);

      let payload = {
        applicationType: this.applicationType,
        acceptableRangeSetting: acceptableRangeSetting,
        minDate: minDate
      };
      await this.putAcceptableRangeSetting(payload);
    }
  }
})
</script>

<template>
  <div>
    <v-container class="px-4">
      <v-row>
        <v-col class="pa-0">
          <div class="blue-grey--text font-weight-bold">
            {{ convertApplicationTypeToText(applicationType) }}設定
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-6 justify-space-between align-center">
        <v-col class="pa-0" align="left">
          <div cols="auto" class="blue-grey--text" style="font-size: 14px;">
            受付設定
          </div>
        </v-col>
        <v-col cols="auto" align="right" class="pa-0 ml-6">
          <SettingButton label="スケジュール登録" @click="handleBulkRegistrationDialog"/>
        </v-col>
        <v-col cols="auto" align="right" class="pa-0 ml-6">
          <SettingButton @click="handleReceptionSettingDialog"/>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col class="pa-0">
          <Calendar
            :applicationType="applicationType"
            :editMode="false"
          />
        </v-col>
      </v-row>

      <v-row class="mt-6 align-center">
        <v-col cols="auto" class="pa-0">
          <div class="blue-grey--text" style="font-size: 14px;">
            受付可能範囲の設定
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-6 justify-space-between align-center">
        <v-col class="pa-0">
          <v-row class="ma-0 align-center">
            <v-col v-if="!acceptableRangeSettingLocal" class="pa-0">
              <v-row class="ma-0 align-center">
                <v-col class="pa-0">
                  <div style="font-size: 14px;">
                    まだ受付可能範囲が設定されていません。
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else class="pa-0">
              <v-row class="ma-0 align-center">
                <v-col :cols="gridCols.label" class="pa-0">
                  <div style="font-size: 14px;">
                    標準
                  </div>
                </v-col>
                <v-col :cols="gridCols.input" class="pa-0">
                  <div class="mb-2" style="font-size: 14px;">
                    申し込み日の
                    <span>{{ acceptableRangeDayCount }}</span>
                    日後から
                    <span>{{ acceptableRangeMonthCount }}</span>
                    ヶ月間を表示する
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="acceptableRangePartialSetting.length > 0" class="ma-0 align-start">
                <v-col :cols="gridCols.label" class="pa-0">
                  <div style="font-size: 14px;">
                    個別
                  </div>
                </v-col>
                <v-col :cols="gridCols.input" class="pa-0">
                  <div
                    v-for="partialSetting in acceptableRangePartialSetting"
                    :key="partialSetting.date"
                    class="mb-2"
                    style="font-size: 14px;"
                  >
                    <span>{{ partialSetting.date }}</span>
                    に
                    <span>{{ convertDateToYYYYMMDD(calcAddDate(partialSetting.date, partialSetting.day_count)) }}</span>
                    までを非表示
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="pa-0">
              <SettingButton @click="handleAcceptableRangeSettingDialog"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <BulkRegistrationDialog
      v-model="bulkRegistrationDialog"
      :applicationType="applicationType"
    />
    <ReceptionSettingDialog
      v-model="receptionSettingDialog"
      :applicationType="applicationType"
    />
    <AcceptableRangeSettingDialog
      v-model="acceptableRangeSettingDialog"
      :applicationType="applicationType"
      :acceptableRangeSettingDefault="acceptableRangeSettingLocal"
      @save="handlePutAcceptableRangeSetting"
    />
  </div>
</template>
