<script>
import { defineComponent } from "vue";
import CustomTimePicker from "./CustomTimePicker.vue";
import DatePeriodDialog from "./DatePeriodDialog/index.vue";
import LoadingCircle from "../LoadingCircle.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_CURRENT_DATE_LIST,
  SET_CURRENT_CURRENT_TIMEZONE,
} from "@/store/mutation-types";
import {
  GET_RECEPTION_SETTING,
} from "@/store/action-types";
import {
  CALENDAR_DISPLAY_MODE,
  UNSPECIFIED_COMA,
  NEW_COMA,
  COMA_ID_CSV_HEADER_NAME,
  START_CSV_HEADER_NAME,
  END_CSV_HEADER_NAME,
  SAMPLE_CSV_COMA_LIST,
} from "@/store/modules/calendar/calendar.constants";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CustomTimePicker,
    DatePeriodDialog,
    LoadingCircle,
  },
  props: {
    applicationType: String,
    editMode: Boolean,
  },
  data() {
    return {
      // 定数
      scheduleNotExistLbl: "スケジュール情報がありません。",

      // カレンダー
      calendarLocal: [],
      editableDate: null,
      editableComa: null,

      // サンプルCSVの期間入力ダイアログ
      datePeriodDialog: false,
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      startDateState: 'startDate',
      endDateState: 'endDate',
      receptionSettingState: 'receptionSetting',
      isLoadingState: 'isLoadingGetReceptionSetting',
      currentTimezoneState: 'currentTimezone'
    }),
    startDate() {
      return this.startDateState(this.applicationType);
    },
    endDate() {
      return this.endDateState(this.applicationType);
    },
    receptionSetting() {
      return this.receptionSettingState(this.applicationType);
    },
    isLoading() {
      return this.isLoadingState(this.applicationType);
    },
    currentTimezone() {
      return this.currentTimezoneState(this.applicationType);
    },
    currentDateList() {
      const dateList = [];

      const date1 = new Date(this.startDate);
      const date2 = new Date(this.endDate);
      const currentDate = new Date(date1);
      while (currentDate <= date2) {
        dateList.push(this.convertDateToYYYYMMDD(new Date(currentDate)));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateList;
    },
    comaList: {
      cache: false,
      get() {
        return this.receptionSetting?.comaList || null;
      }
    },
    schedule: {
      cache: false,
      get() {
        return this.receptionSetting?.schedule || null;
      }
    }
  },
  watch: {
    receptionSetting: {
      handler() {
        // 表示時間枠を更新
        const schedule = structuredClone(this.schedule);
        if (schedule) {
          const timezone = Object.keys(schedule[Object.keys(schedule)[0]]); // 表示週の月曜の時間枠
          const payload = {
            applicationType: this.applicationType,
            currentTimezone: timezone
          };
          this.setCurrentTimezone(payload);
        }

        // カレンダー情報の再生成
        this.generateCalendar();
      },
      deep: true
    },
    calendarLocal: {
      handler(value) {
        this.$emit('update:nodata', value.length === 0); // データがないならtrue
      },
      deep: true
    }
  },
  async created() {
    // まだ表示する週が設定されていなければ今週を設定
    if (!this.startDate || !this.endDate) {
      const currentDate = new Date(); // 現在の日時を取得
      const currentDay = currentDate.getDay(); // 現在の曜日 (0: 日曜, 1: 月曜, ..., 6: 土曜)
      const mondayDate = new Date(currentDate);
      mondayDate.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1)); // 月曜日の日付を取得
      const sundayDate = new Date(mondayDate);
      sundayDate.setDate(mondayDate.getDate() + 6); // 日曜日の日付を取得
      this.changeDispDate(this.applicationType, mondayDate, sundayDate);
    }

    // カレンダーの表示
    this.generateCalendar();
  },
  methods: {
    ...mapMutations({
      setCurrentDateList: SET_CURRENT_DATE_LIST,
      setCurrentTimezone: SET_CURRENT_CURRENT_TIMEZONE,
    }),
    ...mapActions({
      getReceptionSetting: GET_RECEPTION_SETTING,
    }),
    // カレンダー情報
    generateCalendar() {
      let calendar = [];

      // 受付設定, 現在の表示週を取得
      const comaList = structuredClone(this.comaList);
      const schedule = structuredClone(this.schedule);
      const currentDateList = structuredClone(this.currentDateList);
      const currentTimezone = structuredClone(this.currentTimezone);

      // カレンダーを生成
      if (comaList && schedule && currentDateList && currentTimezone) {
        // 表示中の時間枠から、カレンダーの雛形を作成
        calendar = currentTimezone.map((comaId) => {
          return {
            comaId: parseInt(comaId),
            start: comaList[comaId].start,
            end: comaList[comaId].end,
            datas: []
          }
        });

        // 各日付に対して、カレンダーに情報を追加していく
        let nonDataCount = null; // データ無しカウント
        let notShowCount = null; // 非表示カウント
        currentDateList.forEach((date, dIdx) => {
          // データ無しか判定
          const isNonData = (schedule[date] === undefined);
          if (isNonData) {
            // データ無しの場合
            if (nonDataCount === null) {
              // 前回までのループでデータ無しカウントがない場合
              // データ無しカウントを開始
              nonDataCount = {
                date: date, // データ無しカウントを開始した日付
                count: 0 // データ無しカウント数
              };
            }
            // データ無しカウントを+1
            nonDataCount.count++;
          }

          // 非表示時間枠か判定
          let isNotShow = false;
          if (!isNonData) {
            // データ無しでは無い場合
            // 時間枠をチェック
            const scheduleItem = schedule[date]; // 時間枠IDをキーとして、予約数と予約枠数が格納されたオブジェクト
            const timezone = Object.keys(scheduleItem)// 時間枠(時間枠IDのリスト)
            isNotShow = (currentTimezone.toString() !== timezone.toString());
          }
          if (isNotShow) {
            // 非表示時間枠の場合
            if (notShowCount === null) {
              // 前回までのループで非表示カウントがない場合
              // 非表示カウントを開始
              notShowCount = {
                date: date, // 非表示カウントを開始した日付
                count: 0 // 非表示カウント数
              };
            }
            // 非表示カウントを+1
            notShowCount.count++;
          }

          // 前回までのループでデータ無しカウントがあるか判定
          if (nonDataCount && nonDataCount.count > 0) {
            // 前回までのループでデータ無しカウントがある場合
            // 次の日付がデータ無しかを計算
            let nextIsNonData = false;
            const isLastOfDateList = ((currentDateList.length - 1) === dIdx); // リストの最後の日付かどうか
            if (!isLastOfDateList) {
              // 次の日付がリストの最後の日付ではない場合
              const nextDate = currentDateList[dIdx + 1]; // 次の日付
              if (schedule[nextDate] === undefined) {
                // 次の日付がデータなしの場合
                nextIsNonData = true;
              }
            }

            // 次の日付がデータ無しではないかを判定
            if (!nextIsNonData) {
              // 次の日付がデータ無しではない場合
              // カレンダーにデータ無しオブジェクトを追加
              const nonData = {
                displayMode: CALENDAR_DISPLAY_MODE.NON_DATA, // 表示タイプ(表示, 非表示, データ無し)
                date: nonDataCount.date, // 日付
                colSpan: nonDataCount.count, // 横の長さ
                rowSpan: currentTimezone.length // 縦の長さ
              };
              calendar[0].datas.push(nonData); // カレンダーの先頭行に追加

              // データ無しカウントをリセット
              nonDataCount = null;
            }
          }

          // 前回までのループで非表示カウントがあるか判定
          if (notShowCount && notShowCount.count > 0) {
            // 前回までのループで非表示カウントがある場合
            // 次の日付が非表示時間枠か(リストの最後の日付 or 次の日付がデータなし or 次の日付が表示中の時間枠)を計算
            let nextIsNotShow = false;
            const isLastOfDateList = ((currentDateList.length - 1) === dIdx); // リストの最後の日付かどうか
            if (!isLastOfDateList) {
              // 次の日付がリストの最後の日付ではない場合
              const nextDate = currentDateList[dIdx + 1]; // 次の日付
              if (schedule[nextDate] !== undefined) {
                // 次の日付がデータなしではない場合
                const nextTimezone = Object.keys(schedule[nextDate]); // 次の日付の時間枠IDのリスト
                if (currentTimezone.toString() !== nextTimezone.toString()) {
                  // 次の日付が非表示時間枠の場合
                  nextIsNotShow = true;
                }
              }
            }

            // 次の日付が非表示時間枠ではないかを判定
            if (!nextIsNotShow) {
              // 次の日付が非表示時間枠ではない場合
              // カレンダーに非表示時間枠オブジェクトを追加
              const notShowData = {
                displayMode: CALENDAR_DISPLAY_MODE.NOT_SHOW_TIMEZONE, // 表示タイプ(表示, 非表示, データ無し)
                date: notShowCount.date, // 日付
                colSpan: notShowCount.count, // 横の長さ
                rowSpan: currentTimezone.length // 縦の長さ
              };
              calendar[0].datas.push(notShowData); // カレンダーの先頭行に追加

              // 非表示カウントをリセット
              notShowCount = null;
            }
          }

          // 表示中の時間枠か判定
          if (!isNonData && !isNotShow) {
            // 表示中の時間枠の場合
            // カレンダーに表示中の時間枠オブジェクトを追加
            const scheduleItem = schedule[date]; // 時間枠IDに対して、予約数と予約枠数が格納されたオブジェクト
            Object.keys(scheduleItem).forEach((comaId, cIdx) => {
              const data = {
                displayMode: CALENDAR_DISPLAY_MODE.HAS_DATA, // 表示タイプ(表示, 非表示, データ無し)
                date: date, // 日付
                comaId: comaId, // 時間枠ID
                slots: scheduleItem[comaId], // 予約枠数と予約数
                colSpan: 1, // 横の長さ
                rowSpan: 1 // 縦の長さ
              };
              calendar[cIdx].datas.push(data);
            });
          }
        });

        // 時間枠の開始時間の若い順に並び替え
        calendar.sort((a, b) => {
          const aTime = a.start;
          const bTime = b.start;

          // 時間と分に分割して数値として比較
          const [aHour, aMinute] = aTime.split(":").map(Number);
          const [bHour, bMinute] = bTime.split(":").map(Number);

          // 時間の比較
          if (aHour !== bHour) {
            return aHour - bHour; // 時間が異なる場合は時間で比較
          } else {
            return aMinute - bMinute; // 同じ時間なら分で比較
          }
        });
      }

      // カレンダーを表示
      this.calendarLocal = calendar;
    },
    isNonData(data) {
      return data.displayMode === CALENDAR_DISPLAY_MODE.NON_DATA;
    },
    isNotShowTimezone(data) {
      return data.displayMode === CALENDAR_DISPLAY_MODE.NOT_SHOW_TIMEZONE;
    },
    isShowData(data) {
      return data.displayMode === CALENDAR_DISPLAY_MODE.HAS_DATA;
    },
    getTdColspan(data) {
      if (this.isNonData(data) || this.isNotShowTimezone(data)) {
        return data.colSpan;
      }
      return 1;
    },
    getTdRowspan(data) {
      if (this.isNonData(data) || this.isNotShowTimezone(data)) {
        return data.rowSpan;
      }
      return 1;
    },
    getTdValign(data) {
      return this.isShowData(data) ? "middle" : "left";
    },
    getTdClass(data) {
      let classes = []
      if (this.isShowData(data)) {
        classes.push("text-center data-cell pt-3 pb-3");
      } else {
        classes.push("text-center non-data");
      }

      // 過去の日付なら非活性表示
      if(this.isBeforeDate(data.date)) classes.push('disable-date');

      return classes
    },
    getTdPTagClass(data) {
      return { "full-title": data.value === 9999 };
    },
    getClassForDate(date) {
      const classes = [];
      const dayIndex = new Date(date).getDay(); // 曜日のインデックス (0: 日曜, 1: 月曜, ..., 6: 土曜)
      if (dayIndex === 6) classes.push('saturday');
      if (dayIndex === 0) classes.push('sunday');
      if(this.isBeforeDate(date)) classes.push('disable-date');
      return classes;
    },
    isUnspecifiedComaId(comaId) {
      return (UNSPECIFIED_COMA.comaId === comaId);
    },
    changeTimezone(data) {
      // 表示時間枠を更新
      const schedule = structuredClone(this.schedule);
      const timezone = Object.keys(schedule[data.date]); // 押下された日付の時間枠
      const payload = {
        applicationType: this.applicationType,
        currentTimezone: timezone
      };
      this.setCurrentTimezone(payload);

      // カレンダー情報の再生成
      this.generateCalendar();
    },
    isEditableData(data) {
      return (
        this.editMode
        && !this.isBeforeDate(data.date)
        && this.editableDate
        && this.editableDate.comaId === data.comaId
        && this.editableDate.date === data.date
      );
    },
    isEditableComa(coma, isStart) {
      return (
        !this.isCloseFaucet(this.applicationType) // 閉栓の場合、コマは編集できない
        && this.editMode
        && this.editableComa
        && this.editableComa.comaId === coma.comaId
        && this.editableComa.isStart === isStart
      );
    },
    enableToEditData(data) {
      this.cancelEdit();
      this.editableDate = structuredClone(data);
    },
    enableToEditCome(coma, isStart) {
      this.cancelEdit();
      this.editableComa = structuredClone({...coma, isStart});
    },
    cancelEdit() {
      this.editableDate = null;
      this.editableComa = null;
    },
    addComa() { // 親コンポーネントから呼び出される
      const calendar = structuredClone(this.calendarLocal);

      let newComa;
      if (calendar.length === 0) {
        // 一行目は時間指定なしコマを作成
        // 固定値の枠数,予約数を作成
        const dateList = this.currentDateList; // 表示中の日付リスト
        const datas = dateList.map((date) => {
          return {
            comaId: UNSPECIFIED_COMA.comaId,
            date: date,
            displayMode: CALENDAR_DISPLAY_MODE.HAS_DATA,
            slots: NEW_COMA.slots
          };
        });

        // 追加するコマを作成
        newComa = {
          comaId: UNSPECIFIED_COMA.comaId,
          start: UNSPECIFIED_COMA.start,
          end: UNSPECIFIED_COMA.end,
          datas: datas
        };
      } else {
        // comaIdの数値部分のみを抽出して、数値比較で最大値を見つける
        const newComaId = Math.max(...calendar.map((item) => parseInt(item.comaId))) + 100;

        // 固定値の枠数,予約数を作成
        const dateList = calendar[0].datas.filter((data) => !this.isNonData(data) && !this.isNotShowTimezone(data)).map((data) => data.date); // 先頭行かつ、データがあるかつ、表示中の時間枠の日付リスト
        const datas = dateList.map((date) => {
          return {
            comaId: newComaId,
            date: date,
            displayMode: CALENDAR_DISPLAY_MODE.HAS_DATA,
            slots: NEW_COMA.slots
          };
        });

        // 追加するコマを作成
        newComa = {
          comaId: newComaId,
          start: NEW_COMA.start, // コマ追加の場合のstart(固定値)
          end: NEW_COMA.end, // コマ追加の場合のend(固定値)
          datas: datas
        };
      }

      // 表示時間枠に反映
      const newTimezone = structuredClone(this.currentTimezone);
      newTimezone.push(newComa.comaId); // 末尾に新しいコマIDを追加
      const payload = {
        applicationType: this.applicationType,
        currentTimezone: newTimezone
      };
      this.setCurrentTimezone(payload);

      // カレンダーに反映
      this.calendarLocal.push(newComa);
    },
    deleteComa(row) {
      // カレンダーからコマを削除
      this.calendarLocal = this.calendarLocal.filter((calendarRow) => calendarRow.comaId !== row.comaId);
    },
    prevWeek() {
      // 前の週を表示
      const prevStartDate = new Date(this.startDate);
      prevStartDate.setDate(prevStartDate.getDate() - 7);
      const prevEndDate = new Date(this.endDate);
      prevEndDate.setDate(prevEndDate.getDate() - 7);
      this.changeDispDate(this.applicationType, prevStartDate, prevEndDate);
    },
    nextWeek() {
      // 次の週を表示
      const nextStartDate = new Date(this.startDate);
      nextStartDate.setDate(nextStartDate.getDate() + 7);
      const nextEndDate = new Date(this.endDate);
      nextEndDate.setDate(nextEndDate.getDate() + 7);
      this.changeDispDate(this.applicationType, nextStartDate, nextEndDate);
    },
    async changeDispDate(applicationType, startDate, endDate) {
      // 表示週を更新
      const payload = {
        applicationType: applicationType,
        startDate: this.convertDateToYYYYMMDD(startDate),
        endDate: this.convertDateToYYYYMMDD(endDate)
      };
      this.setCurrentDateList(payload);
      await this.getReceptionSetting(payload);
    },
    getEditedItem() { // 親コンポーネントから呼び出される
      // カレンダー情報をcomaListとscheduleに変換
      const comaList = this.calendarLocal.map((row) => {
        return {
          id: row.comaId,
          items: {
            start: row.start,
            end: row.end
          }
        }
      });
      const schedule = [];
      this.calendarLocal.forEach((row) => {
        row.datas
          .filter((data) => !this.isNonData(data) && !this.isNotShowTimezone(data)) // データがない場合、非表示時間枠の場合のオブジェクトを排除
          .forEach((data) => {
            // カレンダーから情報を取得
            const comaId = data.comaId;
            const date = data.date;
            const slots = data.slots[0];

            // scheduleに日付に紐づくObjectがまだ追加されていないなら追加
            const find = schedule.find((s) => s.date === date);
            if (!find) schedule.push({ date: date, slots: {} });

            // カレンダーの情報をscheduleに追加
            for (let i = 0; i < schedule.length; i++) {
              if (schedule[i].date === date) {
                schedule[i].slots[comaId] = slots;
              }
            }
          });
      });

      return {
        comaList: comaList,
        schedule: schedule
      };
    },
    handleExportSampleCsv() {
      this.datePeriodDialog = true;
    },
    exportSampleCsv(targetDateList) {
      // サンプルCSVを生成
      let csv = '';
      if (this.isOpenFaucet(this.applicationType)) {
        const csvHeader = COMA_ID_CSV_HEADER_NAME + ',' + START_CSV_HEADER_NAME + ',' + END_CSV_HEADER_NAME + ',' + targetDateList.map((date) => this.convertYYYYMMDDtoYYYYMD(date)).join(',');
        const csvBody = SAMPLE_CSV_COMA_LIST.map((coma) => {
          const comaId = coma.comaId;
          const start = (this.isUnspecifiedComaId(comaId)) ? "" : coma.start; // 時間指定なしコマなら空文字
          const end = (this.isUnspecifiedComaId(comaId)) ? "" : coma.end; // 時間指定なしコマなら空文字
          const slots = coma.slots;
          const row = comaId + ',' + start + ',' + end + ',' + Array(targetDateList.length).fill(slots).join(',');
          return row;
        }).join('\n');
        csv = csvHeader + '\n' + csvBody;

      } else if (this.isCloseFaucet(this.applicationType)) {
        const csvHeader = targetDateList.map((date) => this.convertYYYYMMDDtoYYYYMD(date)).join(',');
        const csvBody = SAMPLE_CSV_COMA_LIST
          .filter((coma) => {
            const comaId = coma.comaId;
            return this.isUnspecifiedComaId(comaId);
          })
          .map((coma) => {
            const slots = coma.slots;
            const row = Array(targetDateList.length).fill(slots).join(',');
            return row;
          })
          .join('\n');
        csv = csvHeader + '\n' + csvBody;
      }

      // CSVをダウンロード
      const downloadAt = this.convertDateToYYYYMMDDhhmmss(new Date());
      const filename = `schedule_${downloadAt}.csv`;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8を指定
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }

      // 完了ダイアログを表示
      this.$dialog.show({
        title: "CSVダウンロード",
        text: "CSVダウンロードが完了しました。",
        type: "info",
        btnCancelTitle: "閉じる",
        hideBtnConfirm: true,
      });
    },
  }
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-center mb-3">
        <v-col cols="6" sm="6">
          <v-row>
            <v-col cols="3" sm="3" class="text-center" align-self="center">
              <v-btn plain class="float-left" :ripple="false" @click="prevWeek">
                <v-icon medium class="mr-1">mdi-chevron-left</v-icon>前の週
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" class="text-center">
              <div class="targetyear">{{ new Date(currentDateList[0]).getFullYear() }}</div>
              <div class="targetweek">{{ convertYYYYMMDDtoMD(currentDateList[0]) }} - {{ convertYYYYMMDDtoMD(currentDateList[6]) }}</div>
            </v-col>
            <v-col cols="3" sm="3" class="text-center" align-self="center">
              <v-btn plain class="float-right" :ripple="false" @click="nextWeek">
                次の週<v-icon medium class="ml-1">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <LoadingCircle v-if="isLoading"/>
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-if="editMode" class="data-control"><!-- 空白 --></th>
              <th class="text-center data-head pt-3 pb-3">開始</th>
              <th class="data-control"><!-- 空白 --></th>
              <th class="text-center data-head pt-3 pb-3">終了</th>
              <th
                v-for="date in currentDateList"
                :key="date"
                class="text-center data-cell pt-3 pb-3"
                :class="getClassForDate(date)"
              >
                <div>{{ convertYYYYMMDDtoYoubi(date) }}</div>
                <div>{{ convertYYYYMMDDtoMD(date) }}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="calendarLocal.length === 0">
              <td class="text-center" colspan="11">
                <p>
                  この週のスケジュール情報がありません。ファイルをインポートして下さい。<br>
                  テンプレートは<a @click="handleExportSampleCsv">こちら</a>からダウンロードしてください。
                </p>
              </td>
            </tr>
            <tr v-for="row in calendarLocal" :key="row.comaId">
              <div v-if="isUnspecifiedComaId(row.comaId)" style="display: contents; vertical-align: middle;">
                <td v-if="editMode" class="data-control"><!-- 空白 --></td>
                <td class="text-center data-head" :colspan="3">
                  <!-- 時間指定なしの場合 -->
                  <div>時間指定なし</div>
                </td>
              </div>
              <div v-else style="display: contents; vertical-align: middle;">
                <!-- 時間指定なしではない場合 -->
                <td v-if="editMode" class="text-center data-control">
                  <v-icon color="error" @click="deleteComa(row)">mdi-close-circle</v-icon>
                </td>
                <td class="text-center data-head">
                  <div v-if="isEditableComa(row, true)">
                    <!-- 編集時 -->
                    <CustomTimePicker v-model="row.start"/>
                  </div>
                  <div v-else @click="enableToEditCome(row, true)">
                    <!-- 閲覧時 -->
                    {{ row.start }}
                  </div>
                </td>
                <td class="text-center data-head">〜</td>
                <td class="text-center data-head" @click="enableToEditCome(row, false)">
                  <div v-if="isEditableComa(row, false)">
                    <!-- 編集時 -->
                    <CustomTimePicker v-model="row.end"/>
                  </div>
                  <div v-else @click="enableToEditCome(row, false)">
                    {{ row.end }}
                  </div>
                </td>
              </div>
              <td
                v-for="data in row.datas"
                :key="`${data.date}:${data.comaId}`"
                :colspan="getTdColspan(data)"
                :rowspan="getTdRowspan(data)"
                :valign="getTdValign(data)"
                :class="getTdClass(data)"
              >
                <div v-if="isNonData(data)">
                  <!-- スケジュール情報がない場合 -->
                  <p>{{ scheduleNotExistLbl }}</p>
                </div>
                <div v-else-if="isNotShowTimezone(data)">
                  <!-- 表示中の受付可能時間でない場合 -->
                  <v-btn
                    elevation="2"
                    color="primary"
                    dark
                    @click="changeTimezone(data)"
                  >
                    <v-icon medium class="ml-1">mdi mdi-refresh</v-icon>表示
                  </v-btn>
                </div>
                <div v-else-if="isEditableData(data)">
                  <!-- 編集時 -->
                  <v-text-field
                    type="number"
                    v-model.number="data.slots[0]"
                    :min="data.slots[1]"
                    max="9999"
                    single-line
                    hide-details
                    dense
                    class="mx-auto"
                    style="max-width: 50px; font-size: 15px;"
                    color="var(--app-color)"
                  ></v-text-field>
                </div>
                <div v-else>
                  <!-- 閲覧時 -->
                  <p :class="getTdPTagClass(data)" @click="enableToEditData(data)">{{ data.slots[1] }}/{{ data.slots[0] }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <DatePeriodDialog
      v-model="datePeriodDialog"
      title="サンプルCSVのダウンロード"
      message="ダウンロードする期間を入力してください"
      labelCloseBtn="閉じる"
      labelSubmitBtn="ダウンロード"
      @submit="exportSampleCsv"
    />
  </div>
</template>

<style scoped>
p {
  text-align: center !important;
  vertical-align: middle !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.targetyear {
  font-weight: bold;
  font-size: 16px;
}
.targetweek {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 28px;
  letter-spacing: -0.1em;
}

th.data-control {
  border-top: solid 1px lightgray !important;
  border-bottom: solid 2px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
}
th.data-head {
  border-top: solid 1px lightgray !important;
  border-bottom: solid 2px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
  width: 11.5%;
}
th.data-head.saturday {
  color: #2196F3 !important;
}
th.data-head.sunday {
  color: #F44336 !important;
}
th.data-cell {
  border-top: solid 1px lightgray !important;
  border-left: solid 1px lightgray !important;
  border-bottom: solid 2px lightgray !important;
  border-color: lightgray !important;
  font-weight: bold;
  padding: 0px !important;
  width: 11%;
  cursor: default;
}
th.disable-date {
  background-color: rgb(238, 238, 238);
}

td.data-control {
  border-bottom: solid 1px lightgray !important;
}
td.data-head {
  border-bottom: solid 1px lightgray !important;
}
td.data-head[colspan="3"],
td.data-head:last-child  {
  border-right: solid 2px lightgray !important;
}
td.data-cell {
  border-left: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  border-color: lightgray !important;
  padding: 0px !important;
  vertical-align: middle !important;
}
td.non-data {
  border-left: solid 1px lightgray !important;
  border-bottom: solid 1px lightgray !important;
  background-color: #F5F7F8 !important;
  border-color: lightgray !important;
  padding: 0px !important;
  cursor: default;
}
td.disable-date {
  background-color: rgb(238, 238, 238);
}
</style>
