<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    id: String,
    value: String,
  },
  data() {
    return {
      pdfUrl: null,
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.pdfUrl = value;
        }
      },
      deep: true
    }
  },
  async created() {
    if (this.value) {
      this.pdfUrl = this.value;
    }
  },
  methods: {
    resetURL() {
      this.pdfUrl = null;
      this.$emit('input', null);
    },
    handleFileChange(e) {
      if (e.target.files.length != 0) {
        const inputFile = e.target.files[0];
        this.$emit('input', inputFile);
        this.pdfUrl = URL.createObjectURL(inputFile);
        // 下記一行を入れないと、同じファイルを選択することが出来ない不具合がある
        e.target.value = "";
      }
    }
  },
})
</script>

<template>
  <div>
    <v-container fill-height>
      <v-row>
        <v-textarea
          ref="textarea"
          v-model="pdfUrl"
          dense
          grow
          :id="id"
          rows="1"
          no-resize
          outlined
          color="var(--app-color)"
        ></v-textarea>
        <input
          type="file"
          ref="inputFile"
          accept=".pdf"
          style="display: none"
          @change="handleFileChange"
        />
      </v-row>
    </v-container>
  </div>
</template>
