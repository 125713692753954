<script>
import { defineComponent } from "vue";
import Pagination from "./Pagination.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  CHANGE_DELETING_STATE_APPLICATIONS,
  UPDATE_DOWNLOAD_AT_APPLICATIONS,
  GET_APPLICATIONS_BY_APPLICATION_IDS,
} from "@/store/action-types";
import {
  UNSPECIFIED_COMA,
} from "@/store/modules/calendar/calendar.constants.js";
import applicationMixins from "@/mixins/application.js";
import moment from "moment"
import JSZip from 'jszip'
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    Pagination,
  },
  data() {
    return {
      // 定数
      perOnPageOptions: [
        30,
        50,
        100,
        -1
      ],
      headers: [
        { text: '', value: 'data-table-select', width: "6%" },
        { text: "受付番号", value: "reception_no", width: "13%" },
        { text: "受付状態", value: "status", width: "8%" },
        { text: "申し込み内容", value: "application_type", width: "8%" },
        { text: "ガス種", value: "gas_kind", width: "8%" },
        { text: "名前", value: "name", width: "10%" },
        { text: "住所", value: "address", width: "20%" },
        { text: "連絡先", value: "tel_number", width: "12%" },
        { text: "お申し込み日", value: "created_at", width: "8%" },
        { text: "更新日", value: "updated_at", width: "8%" }
      ],

      // お申し込み一覧
      items: [],
      perOnPage: 30,
      page: 1,
      selected: [],

      // CSVダウンロード
      csvLoad: false,
    };
  },
  computed: {
    ...mapState({
      isLoadingUpdateDownloadAtApplications: (state) => state.isLoadingUpdateDownloadAtApplications,
    }),
    ...mapGetters({
      isLoading: 'isLoadingApplication',
      invalidApplications: "invalidApplications",
      signInUser: 'signInUser',
    }),
    isSelected() {
      return this.selected.length === 0;
    },
    paginatedItems() {
      const items = (this.perOnPage === -1) ? this.items : this.paginate(this.items);
      return items;
    }
  },
  watch: {
    invalidApplications: {
      handler(value) {
        this.items = structuredClone(value);
        this.selected = this.selected.map((s) => {
          const findObj = value.find((i) => i.application_id === s.application_id);
          return (findObj) ? findObj : s;
        });
      },
      deep: true
    }
  },
  created() {
    this.items = structuredClone(this.invalidApplications);
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      changeDelitingStateApplications: CHANGE_DELETING_STATE_APPLICATIONS,
      updateDownloadAtApplications: UPDATE_DOWNLOAD_AT_APPLICATIONS,
      getApplicationsByApplicationIds: GET_APPLICATIONS_BY_APPLICATION_IDS
    }),
    // お申し込み一覧
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        let valueComp = 0;
        switch (index[0]) {
          case 'preferred_date':
            // 開栓申込ならpreferred_date、閉栓申込ならgas_last_use_dateを参照
            const prefDateA = (this.isOpenFaucet(a.application_type)) ? a.preferred_date : a.gas_last_use_date;
            const prefDateB = (this.isOpenFaucet(b.application_type)) ? b.preferred_date : b.gas_last_use_date;
            valueComp = (!isDesc[0]) ? (prefDateA < prefDateB ? -1 : 1) : (prefDateB < prefDateA ? -1 : 1);
            break;
        
          default:
            valueComp = (!isDesc[0]) ? (a[index] < b[index] ? -1 : 1) : (b[index] < a[index] ? -1 : 1);
            break;
        }
        return valueComp;
      });

      return items;
    },
    paginate(items) {
      const start = (this.page - 1) * this.perOnPage;
      return items.slice(start, start + this.perOnPage);
    },
    changePage(page) {
      this.page = page;
    },
    changePerOnPage(perOnPage) {
      this.perOnPage = perOnPage;
    },
    goToApplicationDetail(row) {
      this.$router.push(`/ApplicationDetail/${row.application_id}`);
    },

    // CSVダウンロード
    handleConfirmCsvExportApplicationDialog() {
      this.$dialog.show({
        title: "CSVダウンロード",
        text: `${this.selected.length} 件のデータをダウンロードしてもよろしいですか？`,
        type: "info",
        onConfirm: () => {
          this.exportCsv();
        },
        btnCancelTitle: "キャンセル",
        btnConfirmTitle: "ダウンロード",
      });
    },
    async exportCsv() {
      this.csvLoad = true;
      const application_ids = this.selected.map((s) => s.application_id);

      // 最新データの取り直し
      const applications = await this.getApplicationsByApplicationIds({
        application_ids: application_ids
      });

      // CSVデータを作成
      const csvHeader = [
        { text: "受付番号", key: "reception_no" },
        { text: "受付状態", key: "status" },
        { text: "お申し込み日時", key: "created_at" },
        { text: "申し込み種別", key: "application_type" },
        { text: "お申込み者（姓）", key: "applicant_last_name" },
        { text: "お申込み者（名）", key: "applicant_first_name" },
        { text: "申し込み者電話番号", key: "applicant_tel_number" },
        { text: "ガス種", key: "gas_kind" },
        { text: "築3ヶ月未満", key: "is_less_than_3months" },
        { text: "お名前(姓)", key: "last_name" },
        { text: "お名前(名)", key: "first_name" },
        { text: "お名前(セイ)", key: "last_name_kana" },
        { text: "お名前(メイ)", key: "first_name_kana" },
        { text: "郵便番号", key: "postal_code" },
        { text: "都道府県", key: "pref" },
        { text: "市町村", key: "municipality" },
        { text: "町字", key: "address1" },
        { text: "番地", key: "address2" },
        { text: "建物名", key: "building" },
        { text: "部屋番号", key: "room_number" },
        { text: "電話番号", key: "tel_number" },
        { text: "メールアドレス", key: "mail" },
        { text: "希望日", key: "preferred_date" },
        { text: "希望時間", key: "preferred_time" },
        { text: "「沖縄ガスのでんき」のご利用について", key: "is_use_electricity" },
        { text: "「沖縄ガスのでんき」の利用開始日(開栓新規利用)", key: "open_electricity_start_date" },
        { text: "ガスのご利用停止希望日時", key: "gas_last_use_date" },
        { text: "「でんき料金のお知らせ」の郵送の要・不要", key: "is_electricity_news" },
        { text: "でんきとガスのセット割の申し込み", key: "is_set_discount" },
        { text: "引越し先郵便番号", key: "moving_postal_code" },
        { text: "引越し先都道府県", key: "moving_pref" },
        { text: "引越し先市町村", key: "moving_municipality" },
        { text: "引越し先町字", key: "moving_address1" },
        { text: "引越し先番地", key: "moving_address2" },
        { text: "引越し先建物名", key: "moving_building" },
        { text: "引越し先部屋番号", key: "moving_room_number" },
        { text: "「沖縄ガスのでんき」の利用停止日", key: "suspend_date" },
        { text: "「沖縄ガスのでんき」の利用開始日(閉栓継続利用)", key: "close_electricity_start_date" },
        { text: "「沖縄ガスのでんき」の利用開始日(閉栓新規利用)", key: "new_electricity_start_date" },
        { text: "ガス料金番号", key: "charge_number" },
        { text: "検針票の画像", key: "meter_reading_slip_s3_url" },
        { text: "保証金預かりNO", key: "deposit_number" },
        { text: "保証金預かり証の画像", key: "deposit_s3_url" },
        { text: "口座名義", key: "account_holder" },
        { text: "銀行種別", key: "bank_type" },
        { text: "銀行・金庫・農協の名称", key: "financial_name" },
        { text: "口座種別", key: "account_type" },
        { text: "支店名", key: "branch_name" },
        { text: "支店番号", key: "branch_office_number" },
        { text: "口座番号", key: "bank_account_number" },
        { text: "ゆうちょ記号", key: "yucho_symbol" },
        { text: "ゆうちょ番号", key: "yucho_number" },
        { text : "ガス料金のWEB料金明細サービスの利用を申し込む", key : "web_itemized_charges"},
        { text : "ガス料金のお支払い方法", key : "gasPayment"},
        { text: "代理店番号", key: "agency_number" },
        { text: "QRコードのパラメーター", key: "qr_parameter" },
      ];
      let deposit_s3_url_list = [];
      let meter_reading_slip_s3_url_list = [];
      let csv = csvHeader.map((h) => h.text).join(',') + '\n';
      applications.forEach((item) => {
        if (item.deposit_s3_url) {
          deposit_s3_url_list.push({
            name: item.reception_no,
            url: item.deposit_s3_url
          })
        }

        if (item.meter_reading_slip_s3_url) {
          meter_reading_slip_s3_url_list.push({
            name: item.reception_no,
            url: item.meter_reading_slip_s3_url
          })
        }

        const csvRow = csvHeader.map((h) => {
          const key = h.key;
          let value = '';
          switch (key) {
            case 'status':
              value = this.convertStatusToText(item.status);
              break;

            case 'application_type':
              value = this.convertApplicationTypeToText(item.application_type);
              break;

            case 'gas_kind':
              value = this.convertGasKindToText(item.gas_kind);
              break;

            case 'is_less_than_3months':
              value = this.convertIsLessThan3monthsToText(item.is_less_than_3months);
              break;

            case 'last_name_kana':
            case 'first_name_kana':
              value = this.convertFullwidthKatakanaToHalfwidth(item[key]); // 全角カタカナを半角カタカナに変換
              break;

            case 'preferred_time':
              // 時間指定なしコマは「時間指定なし」と表記
              const isUnspecifiedComa = (item.preferred_time === `${UNSPECIFIED_COMA.start}～${UNSPECIFIED_COMA.end}`);
              value = (isUnspecifiedComa) ? '時間指定なし' : item.preferred_time;
              break;

            case 'is_use_electricity':
              value = this.convertIsUseElectricityToText(item.is_use_electricity);
              break;

            case 'web_itemized_charges':
              value = this.convertWebItemizedChargesToText(item.web_itemized_charges);
              break;

            case 'gasPayment':
              const gasPayment = item[key]
              value = gasPayment === 1 ? '口座振替' : gasPayment === 2 ? 'クレジットカード' : gasPayment === 3 ? '払込票' : '';
              break;

            case 'open_electricity_start_date':
              if (this.isOpenFaucet(item.application_type)) {
                const electricity_start_select = item.electricity_start_select;
                if (this.isSamePreferredDate(electricity_start_select)) {
                  value = item.preferred_date;
                } else {
                  value = item.electricity_start_date;
                }
              }
              break;

            case 'close_electricity_start_date':
              if (this.isCloseFaucet(item.application_type)) {
                value = item.electricity_start_date;
              }
              break;

            case 'is_electricity_news':
              value = this.convertIsElectricityNewsToText(item.is_electricity_news);
              break;

            case 'is_set_discount':
              value = this.convertIsSetDiscountToText(item.is_set_discount);
              break;

              case 'meter_reading_slip_s3_url':
              value = this.s3UrlToFileName(item.meter_reading_slip_s3_url || "");
              break;

            case 'deposit_s3_url':
              value = this.s3UrlToFileName(item.deposit_s3_url || "");
              break;

            case 'account_holder':
              value = this.convertFullwidthKatakanaToHalfwidth(item.account_holder); // 全角カタカナを半角カタカナに変換
              break;

            case 'bank_type':
              value = this.convertBankTypeToText(item.bank_type);
              break;

            case 'account_type':
              value = this.convertAccountTypeToText(item.account_type);
              break;

            case 'last_gas_payment_type':
              value = this.convertLastGasPaymentTypeToText(item.last_gas_payment_type);
              break;

            default:
              value = item[key];
              break;
          }
          return (value) ? this.escapeForCSV(value) : '';
        }).join(',');
        csv += csvRow + '\n';
      })

      // ダウンロードファイル
      let fileList = [];

      // CSVをダウンロード
      const hiduke = moment().format('YYYYMMDDhhmmss');
      const filename = 'application_' + hiduke + '.csv';
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // UTF-8を指定
      const blob = new Blob([bom, csv], { type: 'text/csv' });
      fileList.push({name : filename, content : blob});

      // 画像ダウンロード
      for (let i = 0; i < meter_reading_slip_s3_url_list.length; i++) {
        const meter_reading_slip_s3_url = meter_reading_slip_s3_url_list[i].url;
        const filename = '検針票_' + meter_reading_slip_s3_url_list[i].name + '.jpg';
        await fetch(meter_reading_slip_s3_url)
        .then(response => response.blob())
        .then(async (blob) => {
          fileList.push({name : filename, content : blob})
        })
        .catch(error => { console.error('画像をダウンロードできませんでした:', error); });
      }
      for (let i = 0; i < deposit_s3_url_list.length; i++) {
        const deposit_s3_url = deposit_s3_url_list[i].url;
        const filename = '保証金預かり証_' + deposit_s3_url_list[i].name + '.jpg';
        await fetch(deposit_s3_url)
        .then(response => response.blob())
        .then(async (blob) => {
          fileList.push({name : filename, content : blob});
        })
        .catch(error => { console.error('画像をダウンロードできませんでした:', error); });
      }
      const name = 'application_' + hiduke;
      if (fileList.length > 0) {
        const zipBlob = await this.generateZipBlob(fileList, name);
        this.saveBlob(zipBlob, name);
      }

      //最終ダウンロード日時を更新
      await this.updateDownloadAtApplications(application_ids);

      // 選択状態をリセット
      this.selected = [];

      this.csvLoad = false;
    },
    s3UrlToFileName(url) {
      // URLを"/"で分割し、配列の最後の要素を取得
      const parts = url.split("/");
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    async getNameContentPairsFrom(urls){
      const promises = urls.map(async url => {
        const response = await fetch(url.url);
        const content = await response.blob();
        const name = url.name;
        return { name, content };
      });
      const pairs = [];
      for (const promise of promises) {
        pairs.push(await promise);
      }
      return pairs;
    },
    saveBlob(blob, name){
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = this.restrictFileName(name) + '.zip';

      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    async generateZipBlob(nameContentPairs, name){
      const zip = new JSZip();
      const folder = zip.folder(this.restrictFileName(name));
      await Promise.all(nameContentPairs)
      for(let i = 0; i < nameContentPairs.length; i++){
        const name = this.restrictFileName(nameContentPairs[i].name);
        const content = nameContentPairs[i].content;
        folder.file(name, content);
      }
      return zip.generateAsync({ type: 'blob' }); // デフォルトで無圧縮
    },
    restrictFileName(name){
      return name.replace(/[\\\/:*?"<>|]/g, c => '%' + c.charCodeAt(0).toString(16))
    },

    // 削除取消
    handleConfirmUndeleteApplicationDialog() {
      this.$dialog.show({
        title: "削除取消確認",
        text: `${this.selected.length} 件のデータの削除取消をしてもよろしいですか？`,
        type: "error",
        onConfirm: () => {
          this.validateApplications(this.selected);
        },
        btnCancelTitle: "キャンセル",
        btnConfirmTitle: "削除取消",
      });
    },
    async validateApplications(application_items){
      const application_ids = application_items.map((s) => s.application_id)
      await this.changeDelitingStateApplications({operation_type: 'deleted_reset', application_ids: application_ids})
      this.handleConfirmDeleteAfterDialog();
    },
    handleConfirmDeleteAfterDialog(){
      this.$dialog.show({
        title: "削除取消完了",
        text: `${this.selected.length} 件のデータの削除を取り消しました。`,
        type: "info",
        onConfirm: () => {
          this.selected = [];
        },
        btnConfirmTitle: "閉じる",
        hideBtnCancel: true,
      });
    },
  }
});
</script>

<template>
  <div>
    <v-container class="pa-0">
      <v-row class="ma-0 justify-end">
        <v-col cols="auto">
          <v-btn
            color="var(--app-color)"
            class="white--text"
            :loading="isLoadingUpdateDownloadAtApplications || csvLoad"
            :disabled="isSelected"
            @click="handleConfirmCsvExportApplicationDialog"
            v-if="this.signInUser.group === '管理者'"
          >
            <v-icon left>mdi-file-export-outline</v-icon>
            CSVダウンロード
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="error darken-2"
            :loading="isLoading"
            :dark="!isSelected"
            :disabled="isSelected"
            @click="handleConfirmUndeleteApplicationDialog"
          >
            削除取消
          </v-btn>
        </v-col>
      </v-row>

      <Pagination
        :totalCount="items.length"
        :page="page"
        :perOnPage="perOnPage"
        :perOnPageOptions="perOnPageOptions"
        :loading="isLoading"
        @changePage="changePage"
        @changePerOnPage="changePerOnPage"
      />
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="paginatedItems"
        item-key="application_id"
        show-select
        :custom-sort="customSort"
        sort-by="updated_at"
        :sort-desc="true"
        :items-per-page="-1"
        :loading="isLoading"
        fixed-header
        hide-default-footer
        elevation="0"
        :height="(paginatedItems.length >= 10) ? 550 : undefined"
        @click:row="goToApplicationDetail"
      >
      <template v-slot:header.data-table-select="{ on, props }">
          <div class="d-flex align-center">
            <v-simple-checkbox v-bind="props" v-on="on" color="var(--app-color)"/>
            <div style="text-wrap: nowrap;">全て</div>
          </div>
        </template>
        <template v-slot:item.reception_no="{ item }">
          <div class="app-color--text">{{ item.reception_no }}</div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            dark
            :color="convertStatusToColor(item.status)"
            class="justify-center"
            style="width: 100%;"
          >
            {{ convertStatusToText(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.application_type="{ item }">
          <div>{{ convertApplicationTypeToText(item.application_type) }}</div>
        </template>
        <template v-slot:item.gas_kind="{ item }">
          <div>{{ convertGasKindToText(item.gas_kind) }}</div>
        </template>
        <template v-slot:item.name="{ item }">
          <div>{{ item.last_name + item.first_name }}</div>
        </template>
        <template v-slot:item.address="{ item }">
          <div>{{ item.pref + item.municipality + item.address1 + item.address2 }}</div>
          <div>{{ item.building + item.room_number }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{ convertYYYYMMDDHHMMSStoYYYYMMDD(item.created_at) }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div>{{ convertYYYYMMDDHHMMSStoYYYYMMDD(item.updated_at) }}</div>
        </template>
        <template v-slot:no-data>
          <div class="text-left body-2">データはありません。</div>
        </template>
      </v-data-table>
      <Pagination
        :totalCount="items.length"
        :page="page"
        :perOnPage="perOnPage"
        :perOnPageOptions="perOnPageOptions"
        :loading="isLoading"
        @changePage="changePage"
        @changePerOnPage="changePerOnPage"
      />
    </v-container>
  </div>
</template>
