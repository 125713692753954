<script>
import { defineComponent } from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import CustomTextarea from "./CustomTextarea.vue";
import InsertTemplateDialog from "./InsertTemplateDialog.vue";
import LoadingCircle from "../../LoadingCircle.vue";
import {
  SET_MAIL_SETTING
} from "@/store/mutation-types";
import {
  GET_MAIL_SETTING,
  UPDATE_MAIL_SETTING
} from "@/store/action-types";
export default defineComponent({
  components: {
    CustomTextarea,
    InsertTemplateDialog,
    LoadingCircle
  },
  props: {
    settingType: String
  },
  data() {
    return {
      // メール内容がデータベースにない場合、サンプルを表示
      openingMailSetting: {
        title: "${申し込み種別}のお申し込みを受け付けました。",
        content: "開栓手続きのお申し込みありがとうございます。\n\n受付番号: ${受付番号}\n\nお手続きに関してご不明な点がある場合は、以下までお問い合わせください。\n<問合せ先>\n沖縄ガス株式会社 財務部料金課\n電話番号:"
      },
      closingMailSetting: {
        title: "${申し込み種別}のお申し込みを受け付けました。",
        content: "閉栓手続きのお申し込みありがとうございます。\n\n受付番号: ${受付番号}\n\nお手続きに関してご不明な点がある場合は、以下までお問い合わせください。\n<問合せ先>\n沖縄ガス株式会社 財務部料金課\n電話番号:"
      },
      openingReserveMailSetting: {
        title: "開栓予約変更のお申込みを受け付けました。",
        content: "開栓予約変更のお申し込みありがとうございます。\n\n受付番号: ${受付番号}\n\nお手続きに関してご不明な点がある場合は、以下までお問い合わせください。\n<問合せ先>\n沖縄ガス株式会社 財務部料金課\n電話番号:"
      },
      closingReserveMailSetting: {
        title: "閉栓予約変更のお申込みを受け付けました。",
        content: "閉栓予約変更のお申し込みありがとうございます。\n\n受付番号: ${受付番号}\n\nお手続きに関してご不明な点がある場合は、以下までお問い合わせください。\n<問合せ先>\n沖縄ガス株式会社 財務部料金課\n電話番号:"
      },

      // 受付完了メール設定画面
      mailWriteSetting: {
        title: "",
        content: ""
      },

      isLoadingUpdateMailSetting: false,
      isSavingMailSetting: false,

      // 自由入力項目挿入ダイアログ
      insertTemplateDialog: false,
    };
  },
  computed: {
    ...mapState({
      mailSetting: (state) => state.mailSetting.okigas_mail_setting.Items
    }),
    isLoading() {
      return (
        this.isLoadingUpdateMailSetting
      );
    },
    isSaving() {
      return (
        this.isSavingMailSetting
      );
    }
  },
  watch: {
    mailWriteSetting: {
      handler(value) {
        this.mailWriteSetting = value;
      }
    }
  },
  async created() {
    this.isLoadingUpdateMailSetting = true;
    await this.getMailSetting();
    // 初期表示
    if (this.settingType === "open_faucet") {
      // 開栓受付完了（open_faucet）
      this.mailWriteSetting = this.openingMailSetting;
    } else if (this.settingType === "close_faucet") {
      // 閉栓受付完了（close_faucet）
      this.mailWriteSetting = this.closingMailSetting;
    } else if (this.settingType === "open_reserve") {
      // 開栓予約変更（open_reserve）
      this.mailWriteSetting = this.openingReserveMailSetting;
    } else if (this.settingType === "close_reserve") {
      // 閉栓予約変更（close_reserve）
      this.mailWriteSetting = this.closingReserveMailSetting;
    }
    
    // データベースにメール設定がある場合
    for(let mailSet of this.mailSetting) {
      if (this.settingType === mailSet.application_type) {
        this.mailWriteSetting.title = mailSet.subject;
        this.mailWriteSetting.content = mailSet.body;
      }
    }
    this.isLoadingUpdateMailSetting = false;
  },
  methods: {
    ...mapMutations({
      setMailSetting: SET_MAIL_SETTING,
    }),
    ...mapActions({
      getMailSetting: GET_MAIL_SETTING,
      updateMailSetting: UPDATE_MAIL_SETTING,
    }),
    handleInsertTemplateDialog() {
      this.insertTemplateDialog = !this.insertTemplateDialog;
    },
    selectTemplate(template) {
      let textarea = "";
      if (this.settingType === "open_faucet") {
        // 開栓受付完了（open_faucet）
        textarea = document.getElementById("open_faucet");
      } else if (this.settingType === "close_faucet") {
        // 閉栓受付完了（close_faucet）
        textarea = document.getElementById("close_faucet");
      } else if (this.settingType === "open_reserve") {
        // 開栓予約変更（open_reserve）
        textarea = document.getElementById("open_reserve");
      } else if (this.settingType === "close_reserve") {
        // 閉栓予約変更（close_reserve）
        textarea = document.getElementById("close_reserve");
      }
      
      let sentence = this.mailWriteSetting.content;
      const len = sentence.length;
      // 本文にカーソルが置かれていない場合、末尾に挿入する
      let cursor = len;
      if (textarea !== undefined) {
        cursor = textarea.selectionStart;
      }
      
      let before = sentence.slice(0, cursor);
      let after = sentence.slice(cursor, len);
      sentence = before + template + after;
      this.mailWriteSetting.content = sentence;
    },
    async update() {
      this.isSavingMailSetting = true;
      await this.updateMailSetting({
        setting_type: "mail_setting",
        application_type: this.settingType,
        subject: this.mailWriteSetting.title,
        body: this.mailWriteSetting.content
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.isSavingMailSetting = false;
    }
  }
})
</script>

<template>
  <div v-if="isLoading">
    <v-container height="600px" class="pt-16">
      <LoadingCircle message="loading">
      </LoadingCircle>
    </v-container>
  </div>
  <div v-else-if="isSaving">
    <v-container height="600px" class="pt-16">
      <LoadingCircle message="saving">
      </LoadingCircle>
    </v-container>
  </div>
  <div v-else>
    <v-container height="600px" class="pt-16">
      <v-row class="flex-column">
        <v-col>
          <div class="blue-grey--text font-weight-bold">
            件名
          </div>
          <CustomTextarea v-model="mailWriteSetting.title" :rows="1" :maxlength="32"/>
        </v-col>
        <v-col>
          <div class="blue-grey--text font-weight-bold">
            本文
          </div>
          <CustomTextarea v-model="mailWriteSetting.content" :id="settingType" :rows="13" :maxlength="2000"/>
        </v-col>
      </v-row>
      <v-row cols="6">
        <v-col>
          <v-btn elevation="0" dark color="var(--app-color)" 
            outlined
            @click="handleInsertTemplateDialog"
          >
            <div style="font-size: 14px;">申し込み内容を挿入</div>
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-end">
          <v-btn elevation="0" dark color="var(--app-color)" 
            @click="update"
          >
            <div style="font-size: 14px;">更新</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <InsertTemplateDialog
      v-model="insertTemplateDialog"
      @select-template="selectTemplate"
      :settingType="this.settingType"
    />
  </div>
</template>
