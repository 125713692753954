<script>
import { defineComponent } from "vue";
import AuthInfo from "./AuthInfo.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
} from "@/store/action-types";
import img from '@/assets/okinawagas-logo.png';
export default defineComponent({
  components: {
    AuthInfo
  },
  data(){
    return {
      // 固定値
      menuList: [
        { label : '申し込み一覧', icon : 'mdi-clipboard-list-outline', value: 'ApplicationList'},
        { label : '受付設定', icon : 'mdi-cog', value: 'FormSetting' },
        { label : 'ユーザ設定', icon : 'mdi-account-cog', value: 'UserSetting' },
      ],
      groupList: [
        { value: '管理者', allowMenuList: ['ApplicationList', 'FormSetting', 'UserSetting'] },
        { value: '一般', allowMenuList: ['ApplicationList'] }
      ]
    }
  },
  computed:{
    ...mapState({
    }),
    ...mapGetters({
      signInUser: 'signInUser',
    }),
    logoSrc() {
      return img;
    },
    dispMenuList() {
      const currentGroup = this.groupList.find((g) => g.value === this.signInUser.group);
      return this.menuList.filter((m) => currentGroup?.allowMenuList.includes(m.value));
    }
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    isActive(value) {
      return this.$route.name === value;
    },
    clickMenu(value) {
      this.$router.push({ name: value });
    },
    envNameJp(){
      const env_name = import.meta.env.VITE_ENV_NAME
      console.log(env_name)
      return env_name === 'dev' ? '開発環境' : env_name === 'demo' ? '検証環境' : ''
    },
  }
});
</script>

<template>
  <div class="global-header">
    <v-container>
      <v-row class="justify-space-between">
        <v-col cols="auto" style="display: flex;">
          <v-img
            max-height="150"
            max-width="250"
            :src="logoSrc"
          />
          <div style="display: grid; align-items: center;">
            {{ envNameJp() }}
          </div>
        </v-col>
        <v-col cols="auto">
          <AuthInfo/>
        </v-col>
      </v-row>
    </v-container>

    <v-app-bar dense color="var(--app-color)">
      <v-container class="px-0">
        <v-toolbar flat dense color="var(--app-color)">
          <v-btn
            v-for="menu in dispMenuList"
            :key="menu.value"
            text
            :color="isActive(menu.value) ? '#ffff00' : '#ffffff'"
            @click="clickMenu(menu.value)"
          >
            <v-icon left>{{ menu.icon }}</v-icon>
            {{ menu.label }}
          </v-btn>
        </v-toolbar>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style scoped>
.global-header {
  width: 100vw;
  background-color: white;
}
</style>
