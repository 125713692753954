<script>
import { defineComponent } from "vue";
import SubHeader from "../components/Header/SubHeader.vue";
import OpenFaucetApplicationDetail from "../components/ApplicationDetail/OpenFaucetApplicationDetail.vue";
import CloseFaucetApplicationDetail from "../components/ApplicationDetail/CloseFaucetApplicationDetail.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_APPLICATIONS_BY_APPLICATION_IDS,
  EDIT_OPEN_FAUCET,
  EDIT_CLOSE_FAUCET,
  CANCEL_CLOSE_FAUCET,
  CANCEL_OPEN_FAUCET,
} from "@/store/action-types";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    SubHeader,
    OpenFaucetApplicationDetail,
    CloseFaucetApplicationDetail,
  },
  data() {
    return {
      // お申し込み詳細
      applicationLocal: {},
      isValid: false,
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      isLoading: 'isLoadingApplication'
    }),
  },
  watch: {
    loading: {
      handler(v) {
        if(v){
          this.$noScroll();
        }
        else {
          this.$returnScroll();
        }
      },
    }
  },
  async created() {
    // お申し込み詳細を取得
    const application_id = this.$route.params.application_id; // パスパラメータからapplication_idを取得
    const applications = await this.getApplicationsByApplicationIds({
      application_ids: [application_id]
    });
    this.applicationLocal = structuredClone(applications[0]);
  },
  mounted() {
    this.$emit('scrollToTop', 0); // 画面最上部を表示
    window.scrollTo({
      top:0,
    });
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      getApplicationsByApplicationIds: GET_APPLICATIONS_BY_APPLICATION_IDS,
      editOpenFaucet: EDIT_OPEN_FAUCET,
      editCloseFaucet: EDIT_CLOSE_FAUCET,
      cancelCloseFaucet: CANCEL_CLOSE_FAUCET,
      cancelOpenFaucet: CANCEL_OPEN_FAUCET,
    }),
    validate() {
      this.$refs.applicationDetail.validate();
    },
    handleConfirmUpdateApplicationDialog() {
      this.$dialog.show({
        title: "申し込み内容を更新してもよろしいですか？",
        text: "この操作は取り消せません。",
        type: "info",
        onConfirm: () => {
          this.updateApplication(this.applicationLocal);
        },
        btnCancelTitle: "キャンセル",
        btnConfirmTitle: "OK",
      });
    },
    async updateApplication(applicationLocal) {
      // 更新処理
      const isOpenFaucet = this.isOpenFaucet(this.applicationLocal?.application_type);
      const isCancel = (applicationLocal.status === 'cancel');
      if (isOpenFaucet && isCancel) {
        // 開栓キャンセル処理
        await this.cancelOpenFaucet({
          application_id: applicationLocal.application_id, 
          status: applicationLocal.status, 
          preferred_date: this.application.preferred_date, 
          preferred_time: this.application.preferred_time
        });

      } else if (isOpenFaucet && !isCancel) {
        // 開栓更新処理
        await this.editOpenFaucet(applicationLocal);

      } else if (!isOpenFaucet && isCancel) {
        // 閉栓キャンセル処理
        await this.cancelCloseFaucet({
          application_id: applicationLocal.application_id, 
          status: applicationLocal.status, 
          gas_last_use_date: this.application.gas_last_use_date, 
        });

      } else {
        // 閉栓更新処理
        await this.editCloseFaucet(applicationLocal);
      }

      // 更新完了通知
      this.$dialog.show({
        title: "更新が完了しました。",
        text: "",
        type: "info",
        onConfirm: () => {},
        btnCancelTitle: "キャンセル",
        btnConfirmTitle: "OK",
      });

      // 一覧画面に遷移
      this.$router.push('/');
    },
  }
});
</script>

<template>
  <div>
    <div
      v-if="isLoading"
      style="min-height: 100vh; min-width: 100vw; background-color: rgba(0, 0, 0, 0.347); position: absolute; z-index: 999;"
    >
      <div class="text-center" style="min-height: 80vh; align-items: center; display: grid;">
        <v-container>
          <v-row>
            <v-col>
              <v-progress-circular
                :size="100"
                indeterminate
                color="white"
              ></v-progress-circular>
              <br>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <SubHeader>
      <template>
        <div class="d-flex justify-space-between align-center">
          <v-col cols="auto" class="pa-0">
            <router-link :to="{ name: 'ApplicationList' }">
              <v-btn text color="var(--app-color)">
                &lt; お申し込み一覧に戻る
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="auto" class="pa-0">
            <v-btn
              color="var(--app-color)"
              class="mx-2 white--text"
              :disabled="!isValid || applicationLocal.status === 'cancel'"
              :loading="isLoading"
              @click="handleConfirmUpdateApplicationDialog"
            >
              更新
            </v-btn>
          </v-col>
        </div>
      </template>
    </SubHeader>

    <v-form ref="applicationDetail" v-model="isValid">
      <OpenFaucetApplicationDetail
        v-if="isOpenFaucet(applicationLocal?.application_type)"
        v-model="applicationLocal"
        :currentStatus="applicationLocal.status"
        @validate="validate"
      />
      <CloseFaucetApplicationDetail
        v-else
        v-model="applicationLocal"
        :currentStatus="applicationLocal.status"
        @validate="validate"
      />
    </v-form>
  </div>
</template>
