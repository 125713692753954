<script>
import { defineComponent } from "vue";
import CommonSearchSelector from "./CommonSearchSelector.vue";
import CustomTextField from "./CustomTextField.vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  SET_APPLICATION_SEARCH,
} from "@/store/mutation-types";
import {
} from "@/store/action-types";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CommonSearchSelector,
    CustomTextField,
    CustomDatePicker
  },
  data() {
    return {
      // バリデーション
      isValidationError: false,

      // 検索
      gridCols: {
        four: 4,
        label: 5,
        input: 7
      },
      show: false,
      input: {
        status: [],
        gas_kind: [],
        application_type: [],
        okigas_electricity: [],
        is_set_discount: [],
        email_send_state: [],
        reception_no: {
          min: "",
          max: ""
        },
        applicant_last_name: "",
        applicant_first_name: "",
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        created_at: "",
        created_at_start: "",
        created_at_end: "",
        preferred_date: "",
        preferred_date_start: "",
        preferred_date_end: "",
        from_updated_at: "",
        to_updated_at: "",
        from_download_at: "",
        to_download_at: "",
        charge_number: ""
      },
      inputDefault: {
        status: [],
        gas_kind: [],
        application_type: [],
        okigas_electricity: [],
        is_set_discount: [],
        email_send_state: [],
        reception_no: {
          min: "",
          max: ""
        },
        applicant_last_name: "",
        applicant_first_name: "",
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        created_at: "",
        created_at_start: "",
        created_at_end: "",
        preferred_date: "",
        preferred_date_start: "",
        preferred_date_end: "",
        from_updated_at: "",
        to_updated_at: "",
        from_download_at: "",
        to_download_at: "",
        charge_number: ""
      },
    };
  },
  computed: {
    ...mapState({
      applicationSearch: (state) => state.applicationSearch,
    }),
    ...mapGetters({
      isLoading: 'isLoadingApplication',
    })
  },
  created() {
    // 前回の選択状態を保持
    this.input = structuredClone(this.applicationSearch);
  },
  methods: {
    ...mapMutations({
      setApplicationSearch: SET_APPLICATION_SEARCH,
    }),
    ...mapActions({
    }),

    // 検索
    handleSearchArea() {
      this.show = !this.show;
    },
    search() {
      this.setApplicationSearch(structuredClone(this.input));
    },
    clearInput() {
      this.input = structuredClone(this.inputDefault); // オブジェクトのため、ディープコピー
    },
    validationError() {
      this.isValidationError = true;
    },
    clearValidationError() {
      this.isValidationError = false;
    }
  }
});
</script>

<template>
  <div>
    <v-container>
      <v-row class="justify-start">
        <v-col cols="auto">
          <v-btn color="var(--app-color)" @click="handleSearchArea">
            <v-icon left large color="white">
              {{ (show) ? "mdi-chevron-up" : "mdi-chevron-down" }}
            </v-icon>
            <div class="white--text">検索条件</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="show">
      <v-card outlined class="px-4" :disabled="isLoading">
        <v-container class="pa-6">
          <v-row>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.status"
                :options="STATUS_ENUM_DISP"
                label="受付状態"
                itemKey="status"
              />
            </v-col>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.gas_kind"
                :options="GAS_KIND_ENUM"
                label="ガス種"
              />
            </v-col>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.application_type"
                :options="APPLICATION_TYPE_ENUM"
                label="申し込み内容"
              />
            </v-col>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.okigas_electricity"
                :options="OKIGAS_ELECTRICITY_ENUM"
                label="沖縄ガスのでんき申し込み"
              />
            </v-col>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.is_set_discount"
                :options="IS_SET_DISCOUNT_ENUM"
                label="セット割の申し込み"
              />
            </v-col>
            <v-col :cols="gridCols.four">
              <CommonSearchSelector
                v-model="input.email_send_state"
                :options="EMAIL_SEND_STATE_ENUM"
                label="申込完了メール"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">受付番号</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.reception_no.min"
                    placeholder="始め"
                    :rules="[
                      IS_RECEPTION_NO
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0 justify-center">〜</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.reception_no.max"
                    placeholder="終わり"
                    :rules="[
                      IS_RECEPTION_NO
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">申込者(姓)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.applicant_last_name"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">申込者(名)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.applicant_first_name"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">契約者(姓)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.last_name"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">契約者(名)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.first_name"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">契約者(セイ)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.last_name_kana"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">契約者(メイ)</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.first_name_kana"
                    :rules="[
                      MAX_LENGTH_32
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">お申し込み日</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.created_at_start"
                    type="from"
                    :targetDate="input.created_at_end"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0 justify-center">〜</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.created_at_end"
                    type="to"
                    :targetDate="input.created_at_start"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">希望日</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.preferred_date_start"
                    type="from"
                    :targetDate="input.preferred_date_end"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0 justify-center">〜</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.preferred_date_end"
                    type="to"
                    :targetDate="input.preferred_date_start"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="mr-n6 px-0">更新日</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.from_updated_at"
                    type="from"
                    :targetDate="input.to_updated_at"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0 justify-center">〜</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.to_updated_at"
                    type="to"
                    :targetDate="input.from_updated_at"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="mr-n6 px-0">最終ダウンロード日</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.from_download_at"
                    type="from"
                    :targetDate="input.to_download_at"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0 justify-center">〜</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomDatePicker
                    v-model="input.to_download_at"
                    type="to"
                    :targetDate="input.from_download_at"
                    @validation-error="validationError"
                    @clear-validation-error="clearValidationError"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="gridCols.four">
              <v-row>
                <v-col :cols="gridCols.label">
                  <v-subheader class="px-0">ガス料金番号</v-subheader>
                </v-col>
                <v-col :cols="gridCols.input" class="pb-0">
                  <CustomTextField
                    v-model="input.charge_number"
                    :rules="[
                      IS_NUMBER_STRING,
                      MAX_LENGTH_10
                    ]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-row class="my-3 justify-center">
        <v-btn
          color="var(--app-color)"
          class="white--text"
          :loading="isLoading"
          :disabled="isLoading || isValidationError"
          @click="search"
        >
          検索
        </v-btn>
        <v-btn
          color="grey lighten-2"
          class="ml-4"
          :disabled="isLoading"
          @click="clearInput"
        >
          検索条件をクリア
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>
