<script>
import { defineComponent } from "vue";
import {
  createUniqueStr,
} from "@/mixins/index.js";
export default defineComponent({
  props: {
    value: String,
    min: String,
    max: String,
    title: String,
    required: Boolean,
    rules: Array
  },
  data() {
    return {
      isOpenMenu: false,
      uniqueClass: '',
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    selectedDate: {
      handler() {
        this.isOpenMenu = false;
      }
    }
  },
  created() {
    this.uniqueClass = 'menu-patent-' + createUniqueStr();
  }
});
</script>

<template>
  <v-container class="mt-3">
    <v-row v-if="title">
      <v-col class="py-0">
        <span class="grey--text text--darken-3">{{ title }}</span>
        <span v-if="required" class="v-messages theme--light error--text">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" :class="uniqueClass">
        <v-menu
          v-model="isOpenMenu"
          :rules="rules"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          :attach="`.${uniqueClass}`"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-on="on"
              v-bind="attrs"
              v-model="selectedDate"
              :rules="rules"
              placeholder="YYYY-MM-DD"
              readonly
              dense
              color="var(--app-color)"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            :min="min"
            :max="max"
            :rules="rules"
            locale="ja"
            no-title
            color="var(--app-color)"
          />
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>
