<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    type: String,
    targetDate: String,
  },
  data() {
    return {
      isOpenMenu: false
    };
  },
  computed: {
    selectedDate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    validateDateRange() {
      if (!this.isEmpty(this.targetDate) && !this.isEmpty(this.selectedDate)) {
        let from;
        let to;
        if (this.type === "from") {
          from = new Date(this.selectedDate);
          to = new Date(this.targetDate);
        } else {
          from = new Date(this.targetDate);
          to = new Date(this.selectedDate);
        }
        
        if (from > to) {
          // fromよりもtoが小さい場合、バリデーションエラーを設定する
          this.$emit('validation-error');
          return "日付の範囲が無効です";
        } else {
          this.$emit('clear-validation-error');
          return true;
        }
      } else {
        this.$emit('clear-validation-error');
        return true;
      }
    }
  },
  watch: {
    selectedDate: {
      handler() {
        this.isOpenMenu = false;
      }
    }
  }
});
</script>

<template>
  <v-menu
    v-model="isOpenMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-on="on"
        v-bind="attrs"
        v-model="selectedDate"
        placeholder="YYYY-MM-DD"
        clearable
        readonly
        outlined
        dense
        color="var(--app-color)"
        prepend-inner-icon="mdi-calendar-outline"
        :rules="[validateDateRange]"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selectedDate"
      locale="ja"
      no-title
      color="var(--app-color)"
    />
  </v-menu>
</template>
