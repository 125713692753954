<script>
import { defineComponent } from "vue";
import CustomChipGruop from "./CustomChipGruop.vue";
import CustomTextField from "./CustomTextField.vue";
import CustomNumberField from "./CustomNumberField.vue";
import CustomRadioGroup from "./CustomRadioGroup.vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import CustomCheckBox from "./CustomCheckBox.vue";
import ApplicationReserveDatePicker from "./ApplicationReserveDatePicker.vue";
import ApplicationReserveSelect from "./ApplicationReserveSelect.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_RESERVABLE_DATA,
  GET_ACCEPTABLE_RANGE_SETTING,
} from "@/store/action-types";
import applicationMixins from "@/mixins/application.js";
import moment from "moment";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CustomChipGruop,
    CustomTextField,
    CustomNumberField,
    CustomRadioGroup,
    CustomDatePicker,
    CustomCheckBox,
    ApplicationReserveDatePicker,
    ApplicationReserveSelect,
  },
  props: {
    value: Object,
    currentStatus : String
  },
  data() {
    return {
      // お申し込み詳細
      accRngMinDate: "", // 受付可能範囲の最小値
      accRngMaxDate: "", // 受付可能範囲の最大値
      receptionSetting: null, // 受付設定

      // バリデーション
      requiredOnlyIsNotCityGas: (v) => (!this.isNotCityGas || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsUseElectricity: (v) => (!this.isUseElectricity || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsElectricityStartSelect: (v) => (!this.isUseElectricity || !this.isElectricityStartSelect || !this.isEmpty(v)) || "必須入力です",
      requiredWebItemizedCharges: (v) => {
        const allowOptions = (this.isSetDiscount && this.isUseElectricity) ? this.WEB_ITEMIZED_CHARGES_1 : this.WEB_ITEMIZED_CHARGES_2;
        return (allowOptions.some((o) => o.value === v)) || "必須入力です";
      },
      requiredGasPayment: (v) => {
        const allowOptions = (this.isSetDiscount && this.isUseElectricity) ? this.GAS_PAYMENT_ENUM_1 : this.GAS_PAYMENT_ENUM_2;
        return (allowOptions.some((o) => o.value === v)) || "必須入力です";
      },
    };
  },
  computed: {
    // ストア
    ...mapState({
    }),
    ...mapGetters({
    }),

    // propsをラップするcomputed
    status: {
      get() {
        return this.value.status;
      },
      set(status) {
        const value = structuredClone(this.value);
        value.status = status;
        this.updateValue(value);
      },
    },
    applicant_last_name: {
      get() {
        return this.value.applicant_last_name;
      },
      set(applicant_last_name) {
        const value = structuredClone(this.value);
        value.applicant_last_name = applicant_last_name;
        this.updateValue(value);
      },
    },
    applicant_first_name: {
      get() {
        return this.value.applicant_first_name;
      },
      set(applicant_first_name) {
        const value = structuredClone(this.value);
        value.applicant_first_name = applicant_first_name;
        this.updateValue(value);
      },
    },
    applicant_tel_number: {
      get() {
        return this.value.applicant_tel_number;
      },
      set(applicant_tel_number) {
        const value = structuredClone(this.value);
        value.applicant_tel_number = applicant_tel_number;
        this.updateValue(value);
      },
    },
    gas_kind: {
      get() {
        return this.value.gas_kind;
      },
      set(gas_kind) {
        const value = structuredClone(this.value);
        value.gas_kind = gas_kind;
        this.updateValue(value);
      },
    },
    is_less_than_3months: {
      get() {
        return this.value.is_less_than_3months;
      },
      set(is_less_than_3months) {
        const value = structuredClone(this.value);
        value.is_less_than_3months = is_less_than_3months;
        this.updateValue(value);
      },
    },
    last_name: {
      get() {
        return this.value.last_name;
      },
      set(last_name) {
        const value = structuredClone(this.value);
        value.last_name = last_name;
        this.updateValue(value);
      },
    },
    first_name: {
      get() {
        return this.value.first_name;
      },
      set(first_name) {
        const value = structuredClone(this.value);
        value.first_name = first_name;
        this.updateValue(value);
      },
    },
    last_name_kana: {
      get() {
        return this.value.last_name_kana;
      },
      set(last_name_kana) {
        const value = structuredClone(this.value);
        value.last_name_kana = last_name_kana;
        this.updateValue(value);
      },
    },
    first_name_kana: {
      get() {
        return this.value.first_name_kana;
      },
      set(first_name_kana) {
        const value = structuredClone(this.value);
        value.first_name_kana = first_name_kana;
        this.updateValue(value);
      },
    },
    postal_code: {
      get() {
        return this.value.postal_code;
      },
      set(postal_code) {
        const value = structuredClone(this.value);
        value.postal_code = postal_code;
        this.updateValue(value);
      },
    },
    pref: {
      get() {
        return this.value.pref;
      },
      set(pref) {
        const value = structuredClone(this.value);
        value.pref = pref;
        this.updateValue(value);
      },
    },
    municipality: {
      get() {
        return this.value.municipality;
      },
      set(municipality) {
        const value = structuredClone(this.value);
        value.municipality = municipality;
        this.updateValue(value);
      },
    },
    address1: {
      get() {
        return this.value.address1;
      },
      set(address1) {
        const value = structuredClone(this.value);
        value.address1 = address1;
        this.updateValue(value);
      },
    },
    address2: {
      get() {
        return this.value.address2;
      },
      set(address2) {
        const value = structuredClone(this.value);
        value.address2 = address2;
        this.updateValue(value);
      },
    },
    building: {
      get() {
        return this.value.building;
      },
      set(building) {
        const value = structuredClone(this.value);
        value.building = building;
        this.updateValue(value);
      },
    },
    room_number: {
      get() {
        return this.value.room_number;
      },
      set(room_number) {
        const value = structuredClone(this.value);
        value.room_number = room_number;
        this.updateValue(value);
      },
    },
    tel_number: {
      get() {
        return this.value.tel_number;
      },
      set(tel_number) {
        const value = structuredClone(this.value);
        value.tel_number = tel_number;
        this.updateValue(value);
      },
    },
    mail: {
      get() {
        return this.value.mail;
      },
      set(mail) {
        const value = structuredClone(this.value);
        value.mail = mail;
        this.updateValue(value);
      },
    },
    preferred_date: {
      get() {
        return this.value.preferred_date;
      },
      set(preferred_date) {
        const value = structuredClone(this.value);
        value.preferred_date = preferred_date;
        this.updateValue(value);
      },
    },
    preferred_time: {
      get() {
        return this.value.preferred_time;
      },
      set(preferred_time) {
        const value = structuredClone(this.value);
        value.preferred_time = preferred_time;
        this.updateValue(value);
      },
    },
    deposit_agreement: {
      get() {
        return this.value.deposit_agreement;
      },
      set(deposit_agreement) {
        const value = structuredClone(this.value);
        value.deposit_agreement = deposit_agreement;
        this.updateValue(value);
      },
    },
    is_use_electricity: {
      get() {
        return this.value.is_use_electricity;
      },
      set(is_use_electricity) {
        const value = structuredClone(this.value);
        value.is_use_electricity = is_use_electricity;
        this.updateValue(value);
      },
    },
    electricity_start_select: {
      get() {
        return this.value.electricity_start_select;
      },
      set(electricity_start_select) {
        const value = structuredClone(this.value);
        value.electricity_start_select = electricity_start_select;
        this.updateValue(value);
      },
    },
    electricity_start_date: {
      get() {
        return this.value.electricity_start_date;
      },
      set(electricity_start_date) {
        const value = structuredClone(this.value);
        value.electricity_start_date = electricity_start_date;
        this.updateValue(value);
      },
    },
    is_electricity_news: {
      get() {
        return this.value.is_electricity_news;
      },
      set(is_electricity_news) {
        const value = structuredClone(this.value);
        value.is_electricity_news = is_electricity_news;
        this.updateValue(value);
      },
    },
    is_set_discount: {
      get() {
        return this.value.is_set_discount;
      },
      set(is_set_discount) {
        const value = structuredClone(this.value);
        value.is_set_discount = is_set_discount;
        this.updateValue(value);
      },
    },
    web_itemized_charges: {
      get() {
        return this.value.web_itemized_charges;
      },
      set(web_itemized_charges) {
        const value = structuredClone(this.value);
        value.web_itemized_charges = web_itemized_charges;
        this.updateValue(value);
      },
    },
    gasPayment: {
      get() {
        return this.value.gasPayment;
      },
      set(gasPayment) {
        const value = structuredClone(this.value);
        value.gasPayment = gasPayment;
        this.updateValue(value);
      },
    },
    agency_number: {
      get() {
        return this.value.agency_number;
      },
      set(agency_number) {
        const value = structuredClone(this.value);
        value.agency_number = agency_number;
        this.updateValue(value);
      },
    },
    qr_parameter: {
      get() {
        return this.value.qr_parameter;
      },
      set(qr_parameter) {
        const value = structuredClone(this.value);
        value.qr_parameter = qr_parameter;
        this.updateValue(value);
      },
    },

    // 開栓お申し込み詳細
    isNotCityGas() {
      return this.gas_kind !== "city_gas";
    },
    isUseElectricity() {
      return this.is_use_electricity === 1;
    },
    isElectricityStartSelect() {
      return this.electricity_start_select === 1;
    },
    isSetDiscount() {
      return this.is_set_discount === 1;
    },
    isWebItemizedCharges() {
      return this.web_itemized_charges === 1;
    },
  },
  watch: {
    // v-showで出しわけている要素のバリデーション
    isNotCityGas() {
      this.$emit('validate');
    },
    isUseElectricity() {
      this.$emit('validate');
    },
    isElectricityStartSelect() {
      this.$emit('validate');
    },

  },
  async created() {
    // 受付可能範囲の取得
    const responseGetAcceptableRangeSetting = await this.getAcceptableRangeSetting({
      applicationType: this.openFaucet
    });
    const acceptableRangeSetting = responseGetAcceptableRangeSetting.data.okigas_acceptable_range_setting;

    // 受付可能範囲の計算
    const today = moment().format('YYYY-MM-DD');
    const accRngDay = acceptableRangeSetting.acceptable_range_day_count;
    const accRngMonth = acceptableRangeSetting.acceptable_range_month_count;
    const accRngPrtSet = acceptableRangeSetting.acceptable_range_partial_setting;
    const accRngPrtSetToday = accRngPrtSet.find((obj) => obj.date === today);

    // 今日から{accRngDay}日後が最小値
    this.accRngMinDate = (accRngPrtSetToday)
      ? moment(accRngPrtSetToday.date).add((accRngPrtSetToday.day_count + 1), 'd').format("YYYY-MM-DD")
      : moment(today).add(accRngDay, 'd').format("YYYY-MM-DD");

    // 受付可能な最小値から{accRngMonth}ヶ月後が最大値
    this.accRngMaxDate = moment(this.accRngMinDate).add(accRngMonth, 'M').format("YYYY-MM-DD");

    // 受付可能枠数を取得
    const datesMinMax = this.getMinMaxDates([this.accRngMinDate, this.accRngMaxDate, this.value.preferred_date]); // 受付可能範囲にガスのご利用開始希望日も範囲に含め、受付設定を取得する
    const responseGetReservableData = await this.getReservableData({
      applicationType: this.openFaucet,
      startDate: datesMinMax.minDate,
      endDate: datesMinMax.maxDate
    });
    this.receptionSetting = responseGetReservableData.data.okigas_reception_setting;
  },
  methods: {
    // ストア
    ...mapMutations({
    }),
    ...mapActions({
      getAcceptableRangeSetting: GET_ACCEPTABLE_RANGE_SETTING,
      getReservableData: GET_RESERVABLE_DATA,
    }),

    // 開栓お申し込み詳細
    updateValue(value) {
      this.$emit('input', value);
    },
  }
});
</script>

<template>
  <v-container class="pa-0">
    <v-card outlined class="mx-auto my-4 pa-4">
      <v-row class="align-center">
        <v-col cols="auto">
          <div>受付状態:</div>
        </v-col>
        <v-col cols="auto">
          <CustomChipGruop
            v-model="status"
            :options="STATUS_ENUM"
            :currentStatus="currentStatus"
          />
        </v-col>
      </v-row>

      <v-row class="justify-space-between">
        <v-col cols="auto">
          <div class="header font-weight-bold">
            「ご利用開始」のお申込み
          </div>
        </v-col>
        <v-col cols="auto">
          <div v-if="value.cancel_at">キャンセル日：{{ value.cancel_at }}</div>
          <div>申し込み日：{{ value.created_at }}</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="auto">
          <div>受付番号</div>
        </v-col>
        <v-col cols="auto">
          <div>{{ value.reception_no }}</div>
        </v-col>
      </v-row>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">1. お申し込み者情報</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="applicant_last_name"
            title="お申し込み者(姓)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9,
              IS_ONLY_CHAR,
            ]"
          />
          <CustomTextField
            v-model="applicant_first_name"
            title="お申し込み者(名)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10,
              IS_ONLY_CHAR,
            ]"
          />
          <CustomTextField
            v-model="applicant_tel_number"
            title="お申し込み者電話番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_10_OR_11
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">2. ご利用お申し込み場所の情報</div>
            </v-col>
          </v-row>
          <CustomRadioGroup
            v-model="gas_kind"
            :options="GAS_KIND_ENUM"
            title="ご利用場所のガス種を選択してください"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <CustomRadioGroup
            v-model="is_less_than_3months"
            :options="IS_LESS_THAN_3MONTH_ENUM"
            title="築３ヶ月未満の建物ですか？"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <CustomTextField
            v-model="last_name"
            title="お名前(姓)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9
            ]"
          />
          <CustomTextField
            v-model="first_name"
            title="お名前(名)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10
            ]"
          />
          <CustomTextField
            v-model="last_name_kana"
            title="お名前(セイ)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9,
              IS_FURIGANA,
            ]"
          />
          <CustomTextField
            v-model="first_name_kana"
            title="お名前(メイ)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10,
              IS_FURIGANA,
            ]"
          />
          <CustomTextField
            v-model="postal_code"
            title="郵便番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_7
            ]"
          />
          <CustomTextField
            v-model="pref"
            title="都道府県"
            required
            :rules="[
              REQUIRED,
              MIN_LENGTH_1,
              MAX_LENGTH_4
            ]"
          />
          <CustomTextField
            v-model="municipality"
            title="市町村"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="address1"
            title="町字"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="address2"
            title="番地"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="building"
            title="建物名"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="room_number"
            title="部屋番号"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="tel_number"
            title="電話番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_10_OR_11
            ]"
          />
          <CustomTextField
            v-model="mail"
            title="メールアドレス"
            required
            :rules="[
              REQUIRED,
              IS_EMAIL,
              MAX_LENGTH_320
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">3. ガスのご利用開始希望日時について</div>
            </v-col>
          </v-row>
          <ApplicationReserveDatePicker
            v-model="preferred_date"
            :min="accRngMinDate"
            :max="accRngMaxDate"
            :receptionSetting="receptionSetting"
            title="希望日"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <ApplicationReserveSelect
            v-model="preferred_time"
            :selectDate="preferred_date"
            :receptionSetting="receptionSetting"
            title="希望時間"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <div v-show="isNotCityGas">
            <CustomCheckBox
              v-model="deposit_agreement"
              title="LPガス賃貸契約の場合、ご入居の際に保証金10,000円を現金にてご用意のお願い"
              label="承諾して申し込みます"
              required
              :rules="[
                requiredOnlyIsNotCityGas
              ]"
            />
          </div>
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">4. でんき及びセット割のお申し込みについて</div>
            </v-col>
          </v-row>
          <CustomRadioGroup
            v-model="is_use_electricity"
            :options="IS_USE_ELECTRICITY_ENUM"
            title="「沖縄ガスのでんき」のご利用について"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <div v-show="isUseElectricity">
            <CustomRadioGroup
              v-model="electricity_start_select"
              :options="ELECTRICITY_START_SELECT_ENUM"
              title="「沖縄ガスのでんき」の利用開始日"
              required
              :rules="[
                requiredOnlyIsUseElectricity
              ]"
            />
            <div v-show="isElectricityStartSelect">
              <CustomDatePicker
                v-model="electricity_start_date"
                required
                :rules="[
                  requiredOnlyIsElectricityStartSelect
                ]"
              />
            </div>
            <CustomRadioGroup
              v-model="is_electricity_news"
              :options="IS_ELECTRICITY_NEWS_ENUM"
              title="「でんき料金のお知らせ」の郵送の要・不要"
              required
              :rules="[
                requiredOnlyIsUseElectricity
              ]"
            />
            <CustomRadioGroup
              v-model="is_set_discount"
              :options="IS_SET_DISCOUNT_ENUM"
              title="でんきとガスのセット割を申し込む"
              required
              :rules="[
                requiredOnlyIsUseElectricity
              ]"
            />
          </div>
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">5. でんき料金とガス料金のお支払方法について</div>
            </v-col>
          </v-row>
          <CustomRadioGroup
            v-model="web_itemized_charges"
            :options="(isUseElectricity && isSetDiscount) ? WEB_ITEMIZED_CHARGES_1 : WEB_ITEMIZED_CHARGES_2"
            title="ガス料金のWEB料金明細サービスの利用を申し込む"
            required
            :rules="[
              requiredWebItemizedCharges
            ]"
          />
          <CustomRadioGroup
            v-model="gasPayment"
            :options="((isUseElectricity && isSetDiscount) || isWebItemizedCharges) ? GAS_PAYMENT_ENUM_1 : GAS_PAYMENT_ENUM_2"
            title="ガス料金のお支払い方法"
            required
            :rules="[
              requiredGasPayment
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">6. その他（代理店記入）</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="agency_number"
            title="代理店番号"
            :rules="[
              MAX_LENGTH_20_NO_ERR_MSG
            ]"
          />
          <CustomTextField
            v-model="qr_parameter"
            title="QRコード"
            :rules="[]"
          />
        </v-container>
      </v-card>
    </v-card>
  </v-container>
</template>
