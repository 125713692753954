<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: {
      type: String,
      default: '00:00'
    },
  },
  data() {
    return {
      menuHour: false,
      menuMinute: false,
      selectedHour: null,
      selectedMinute: null,
      hours: Array.from({ length: 24 }, (_, i) => i),
      minutes: Array.from({ length: 4 }, (_, index) => index * 15)
    };
  },
  watch: {
    value: {
      handler(newVal) {
        const [hour = '0', minute = '0'] = newVal.split(':');
        this.selectedHour = parseInt(hour);
        this.selectedMinute = parseInt(minute);
      }
    },
  },
  mounted() {
    const [hour = '0', minute = '0'] = this.value.split(':');
    this.selectedHour = parseInt(hour);
    this.selectedMinute = parseInt(minute);
  },
  methods: {
    setHour(hour) {
      this.selectedHour = hour;
      this.menuHour = false;
      this.emitTime();
    },
    setMinute(minute) {
      this.selectedMinute = minute;
      this.menuMinute = false;
      this.emitTime();
    },
    emitTime() {
      const time = `${this.selectedHour || 0}:${this.selectedMinute || 0}`.replace(/^(\d):(\d)$/, '0$1:0$2').replace(/^(\d):(\d\d)$/, '0$1:$2').replace(/^(\d\d):(\d)$/, '$1:0$2');
      this.$emit('input', time);
    },
  }
});
</script>

<template>
  <div class="d-flex justify-center align-center">
    <v-menu
      v-model="menuHour"
      :close-on-content-click="false"
      :nudge-right="30"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-model.number="selectedHour"
          readonly
          hide-details
          color="var(--app-color)"
        ></v-text-field>
      </template>
      <v-list class="scrollable-list" dense>
        <div v-for="hour in hours" :key="hour" @click="setHour(hour)">
          <v-list-item>
            <v-list-item-content>{{ hour }}</v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>

    <div class="px-1">:</div>

    <v-menu
      v-model="menuMinute"
      :close-on-content-click="false"
      :nudge-right="30"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-model.number="selectedMinute"
          readonly
          hide-details
          color="var(--app-color)"
        ></v-text-field>
      </template>
      <v-list class="scrollable-list" dense>
        <div v-for="minute in minutes" :key="minute" @click="setMinute(minute)">
          <v-list-item>
            <v-list-item-content>{{ minute }}</v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<style scoped>
::v-deep .v-input {
  font-size: 15px !important;
  max-width: 22px !important;
}
::v-deep .v-text-field {
  padding: 0 !important;
}
::v-deep .v-text-field input {
  padding: 0 !important;
}
::v-deep .scrollable-list {
  overflow-y: auto;
  max-height: 240px;
}
</style>
