export const STATUS_ENUM = [
  {
    text: "未対応",
    value: "unsupport",
    color: "lime"
  },
  {
    text: "完了",
    value: "complete",
    color: "orange"
  },
  {
    text: "お客様変更あり",
    value: "changed",
    color: "primary"
  },
  {
    text: "キャンセル",
    value: "cancel",
    color: "red"
  },
  {
    text: "キャンセル",
    value: "cancel_downloaded",
    color: "red"
  }
];

export const STATUS_ENUM_DISP = [
  {
    text: "未対応",
    value: "unsupport",
    color: "lime"
  },
  {
    text: "完了",
    value: "complete",
    color: "orange"
  },
  {
    text: "お客様変更あり",
    value: "changed",
    color: "primary"
  },
  {
    text: "キャンセル",
    value: "cancel",
    color: "red"
  }
];

export const APPLICATION_TYPE_ENUM = [
  {
    text: "開栓",
    value: "open_faucet"
  },
  {
    text: "退去時の閉栓",
    value: "close_faucet"
  }
];

export const GAS_KIND_ENUM = [
  {
    text: "都市ガス",
    value: "city_gas"
  },
  {
    text: "プロパンガス",
    value: "propane_gas"
  },
  {
    text: "不明",
    value: "unknown"
  }
];

export const ACCOUNT_TYPE_ENUM = [
  {
    text: "普通",
    value: 0
  },
  {
    text: "当座",
    value: 1
  }
];

export const BANK_TYPE_ENUM = [
  {
    text: "銀行・金庫・農協",
    value: 0
  },
  {
    text: "ゆうちょ銀行",
    value: 1
  }
];

export const IS_BANK_DESIGNATION_ENUM = [
  {
    text: "保証金の受け取り口座を指定しない。",
    value: 1
  },
  {
    text: "保証金の受け取り口座を指定する。",
    value: 2
  }
];

export const IS_LESS_THAN_3MONTH_ENUM = [
  {
    text: "はい",
    value: 1
  },
  {
    text: "いいえ",
    value: 0
  }
];

export const IS_USE_ELECTRICITY_ENUM = [
  {
    text: "申し込む",
    value: 1
  },
  {
    text: "申し込まない",
    value: 0
  }
];

export const ELECTRICITY_START_SELECT_ENUM = [
  {
    text: "でんきとガスご利用開始日は同じ",
    value: 0
  },
  {
    text: "でんきの利用開始日を指定する",
    value: 1
  }
];

export const IS_ELECTRICITY_NEWS_ENUM = [
  {
    text: "要",
    value: 1
  },
  {
    text: "不要",
    value: 0
  }
];

export const IS_SET_DISCOUNT_ENUM = [
  {
    text: "申し込む",
    value: 1
  },
  {
    text: "申し込まない",
    value: 0
  }
];

export const WEB_ITEMIZED_CHARGES_1 = [
  {
    text: "申し込む",
    value: 1
  },
];

export const WEB_ITEMIZED_CHARGES_2 = [
  {
    text: "申し込む",
    value: 1
  },
  {
    text: "申し込まない",
    value: 0
  }
];

export const GAS_PAYMENT_ENUM_1 = [
  {
    text: "口座振替",
    value: 1
  },
  {
    text: "クレジットカード",
    value: 2
  }
];

export const GAS_PAYMENT_ENUM_2 = [
  {
    text: "口座振替",
    value: 1
  },
  {
    text: "クレジットカード",
    value: 2
  },
  {
    text: "払込票",
    value: 3
  },
];

export const IS_USED_OKIGAS_ELECTRICITY_ENUM = [
  {
    text: "ガスをご利用している場所で、「沖縄ガスのでんき」を利用している場合",
    value: 0
  },
  {
    text: "ガスをご利用している場所で、「沖縄ガスのでんき」を利用していない場合",
    value: 1
  }
];

export const IS_SUSPEND_ELECTRICITY_ENUM = [
  {
    text: "はい",
    value: 1
  },
  {
    text: "いいえ（でんきは、継続して利用する）",
    value: 0
  }
];

export const IS_CONTINUOUS_USE_ELECTRICITY_ENUM = [
  {
    text: "申し込む",
    value: 1
  },
  {
    text: "申し込まない",
    value: 0
  }
];

export const LAST_GAS_PAYMENT_TYPE_ENUM = [
  {
    text: "現在と同じ方法で行う",
    value: 0
  },
  {
    text: "お引越し先に払込票を郵送する",
    value: 1
  }
];

export const OKIGAS_ELECTRICITY_NEW_USE_ENUM = [
  {
    text: "申し込む",
    value: 1
  },
  {
    text: "申し込まない",
    value: 0
  }
];

export const IS_ELECTRICITY_CHARGE_NOTIFICATION_ENUM = [
  {
    text: "要",
    value: 1
  },
  {
    text: "不要",
    value: 0
  }
];

export const EMAIL_SEND_STATE_ENUM = [
  {
    text: "メール送信成功",
    value: "success"
  },
  {
    text: "メール送信失敗",
    value: "failure"
  }
];

export const STATUS_COLOR_DEFAULT = "lime";

export const OKIGAS_ELECTRICITY_ENUM = [
  {
    text: "申し込む（ガス開栓）",
    value: 0
  },
  {
    text: "申し込まない（ガス開栓）",
    value: 1
  },
  {
    text: "継続して申し込む（ガス閉栓）",
    value: 2
  },
  {
    text: "継続して申し込まない（ガス閉栓）",
    value: 3
  },
  {
    text: "新規で申し込む（ガス閉栓）",
    value: 4
  },
  {
    text: "新規で申し込まない（ガス閉栓）",
    value: 5
  },
];