<script>
import { defineComponent } from "vue";
import SubHeader from "../components/Header/SubHeader.vue";
import OpeningSetting from "../components/FormSetting/ReceptionSetting/index.vue";
import ClosingSetting from "../components/FormSetting/ReceptionSetting/index.vue";
import EmailSetting from "../components/FormSetting/EmailSetting/index.vue";
import ResourceSetting from "../components/FormSetting/ResourceSetting/index.vue";
import applicationMixins from "@/mixins/application.js";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    SubHeader,
    OpeningSetting,
    ClosingSetting,
    EmailSetting,
    ResourceSetting
  },
  data() {
    return {
      tab: 'recievable-setting'
    };
  }
});
</script>

<template>
  <div>
    <SubHeader>
      <template>
        <div class="d-flex justify-start">
          <v-btn-toggle v-model="tab" dense mandatory color="var(--app-color)">
            <v-btn value="recievable-setting" class="px-4">
              開栓 / 閉栓設定
            </v-btn>
            <v-btn value="email-setting" class="px-4">
              メール設定
            </v-btn>
            <v-btn value="resource-setting" class="px-4">
              リンク設定
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
    </SubHeader>
    <v-container class="pa-0">
      <v-card outlined class="my-4 mx-auto">
        <v-tabs-items v-model="tab">
          <v-tab-item value="recievable-setting">
            <v-container>
              <OpeningSetting :applicationType="openFaucet"/>
              <ClosingSetting :applicationType="closeFaucet" class="mt-12"/>
            </v-container>
          </v-tab-item>
          <v-tab-item value="email-setting">
            <v-container>
              <EmailSetting/>
            </v-container>
          </v-tab-item>
          <v-tab-item value="resource-setting">
            <v-container>
              <ResourceSetting/>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>
