import {
  YOUBI_LIST,
} from '@/constants/index.js';
import validation from '@/constants/validation.js';
import {
  confirmSession
} from '@/mixins/amplify-auth.js';
import store from "@/store/index";
import {
  SET_SIGN_IN_USER_SESSION
} from "@/store/mutation-types";
import moment from "moment"

// サインイン状態か確認する
export const confirmSignIn = async () => {
  const signInUserSession = await confirmSession();
  if (signInUserSession) {
    // ログインしている
    store.commit(SET_SIGN_IN_USER_SESSION, signInUserSession);
    return true;
  } else {
    // ログインしていない
    return false;
  }
};

// 空文字,null,undefinedのいずれかであるか判定する
export const isEmpty = (value) => {
  return (
    value === ""
    || value === null
    || value === undefined
  );
};

// 数値であるか判定する
export const isNumber = (value) => {
  return Number.isInteger(value);
};

// 今日の日付よりも過去の日付であるか判定する
export const isBeforeDate = (date) => {
  const currentDate = new Date(); // 今日の日付を取得
  const inputDate = new Date(date); // 渡された日付をDateオブジェクトに変換

  // 日付部分のみを比較するため、時刻情報を0時0分0秒に設定
  currentDate.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);

  // 入力された日付が今日の日付よりも過去であるかを確認
  return inputDate < currentDate;
};

// 配列の中に要素が含まれているか判定する(プリミティブ型の配列に限る)
export const isArrayContainElem = (array, elem) => {
  return array.includes(elem);
};

// 文字列mainの中に文字列subが含まれているか判定する
export const isStringContainString = (main, sub) => {
  return main.includes(sub);
};

// 日時データと日付データが等しいか判定する
export const isDateTimeEqualDate = (datetime, date) => {
  return (datetime.split(' ')[0] === date);
};

// 対象の日付が from 日付以上の日付かどうかをチェックする
export const isDateAfterFromDate = (dateToCheck, fromDate) => {
  const format_dateToCheck = moment(dateToCheck).format('YYYY-MM-DD');

  const date = new Date(format_dateToCheck);
  const from = new Date(fromDate);

  return date >= from;
}

// 対象の日付が to 日付以下の日付かどうかをチェックする
export const isDateAfterToDate = (dateToCheck, toDate) => {
  const format_dateToCheck = moment(dateToCheck).format('YYYY-MM-DD');

  const date = new Date(format_dateToCheck);
  const to = new Date(toDate);

  return date <= to;
}

// 指定した日付の範囲内であるか判定する
export const isDateTimeInRange = (dateToCheck, fromDate, toDate) => {
  const format_dateToCheck = moment(dateToCheck).format('YYYY-MM-DD');

  const date = new Date(format_dateToCheck);
  const from = new Date(fromDate);
  const to = new Date(toDate);
  
  return date >= from && date <= to;
}

// 日付配列の中から最小(過去)の日付と最大(未来)の日付を取得する
export const getMinMaxDates = (dates) => {
  // 最小の日付を初期化
  let minDate = moment(dates[0]);
  // 最大の日付を初期化
  let maxDate = moment(dates[0]);

  // 日付の配列をループして最小と最大の日付を探す
  dates.forEach(date => {
    const currentDate = moment(date);
    if (currentDate.isBefore(minDate)) {
      minDate = currentDate;
    }
    if (currentDate.isAfter(maxDate)) {
      maxDate = currentDate;
    }
  });

  // 最小と最大の日付を返す
  return {
    minDate: minDate.format('YYYY-MM-DD'),
    maxDate: maxDate.format('YYYY-MM-DD')
  };
};

// 文字列内の全角カタカナを全て半角カタカナに変換する
export const convertFullwidthKatakanaToHalfwidth = (str) => {
  try {
    let kanaMap = {
      "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
      "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
      "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
      "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
      "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
      "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
      "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
      "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
      "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
      "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
      "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
      "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
      "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
      "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
      "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
      "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
      "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
      "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
      "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･",
      "　": " ",
      "０": "0","１": "1","２": "2","３": "3","４": "4","５": "5","６": "6","７": "7","８": "8","９": "9",
    };
    let reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str.replace(reg, function(s) {
        return kanaMap[s];
    }).replace(/゛/g, 'ﾞ').replace(/゜/g, 'ﾟ');
  } catch (error) {
    return str
  }
};

// エスケープ処理
export const escapeForCSV = (s) => {
  return `"${s.replace(/\"/g, '\"\"')}"`;
}

// RFC3339形式のデータをDateTime形式に変換する
export const convertRFC3339ToDateTime = (rfc3339String) => {
  const date = new Date(rfc3339String);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDateTime;
};

// YYYY-MM-DD形式のデータを曜日に変換する
export const convertYYYYMMDDtoYoubi = (YYYYMMDDString) => {
  // 日時文字列をDateオブジェクトに変換
  const date = new Date(YYYYMMDDString);

  // 曜日を取得
  const dayIndex = date.getDay(); // 曜日のインデックス (0: 日曜, 1: 月曜, ..., 6: 土曜)

  return YOUBI_LIST[dayIndex];
};

// YYYY-MM-DD形式のデータをM/D形式に変換する
export const convertYYYYMMDDtoMD = (YYYYMMDDString) => {
  // 日時文字列をDateオブジェクトに変換
  const date = new Date(YYYYMMDDString);

  // 月,日を取得
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}`;
};

// YYYY-MM-DD形式のデータをYYYY/M/D形式に変換する
export const convertYYYYMMDDtoYYYYMD = (YYYYMMDDString) => {
  // 日時文字列をDateオブジェクトに変換
  const date = new Date(YYYYMMDDString);

  // 年,月,日を取得
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}/${month}/${day}`;
};

// YYYYMMDDHHMMSS形式のデータをYYYY-MM-DD形式に変換する
export const convertYYYYMMDDHHMMSStoYYYYMMDD = (YYYYMMDDHHMMSSString) => {
  // 日時文字列をDateオブジェクトに変換
  const date = new Date(YYYYMMDDHHMMSSString);

  // 年,月,日を取得
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// DateオブジェクトをYYYY-MM-DD形式に変換する
export const convertDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため+1、2桁表示に変換
  const day = String(date.getDate()).padStart(2, '0'); // 日を2桁表示に変換

  return `${year}-${month}-${day}`;
};

// DateオブジェクトをYYYYMMDDhhmmss形式に変換する
export const convertDateToYYYYMMDDhhmmss = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

// ユニークな文字列を作成する
export const createUniqueStr = (myStrong) => {
  let strong = 1000;
  if (myStrong) strong = myStrong;
  return new Date().getTime().toString(16) + Math.floor(strong*Math.random()).toString(16);
};

// 今日の日付をYYYY-MM-DD形式で取得
export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // 月は0から始まるため、+1する必要がある
  const day = String(today.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

// Dateオブジェクトに日数を加算する
export const calcAddDate = (date, add) => {
  // 元のDateオブジェクトをコピーする
  const newDate = new Date(date);

  // 指定された日数を加算
  newDate.setDate(newDate.getDate() + add);

  // 結果を返す
  return newDate;
};

// Dateオブジェクトに日数を減算する
export const calcSubDate = (date, sub) => {
  // 元のDateオブジェクトをコピーする
  const newDate = new Date(date);

  // 指定された日数を減算
  newDate.setDate(newDate.getDate() - sub);

  // 結果を返す
  return newDate;
};

// Dateオブジェクトの日数の差を求める
export const calcDiffDate = (date1, date2) => {
  // 日付の部分のみを含む新しいDateオブジェクトを作成
  const newDate1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const newDate2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

  // 24時間 * 60分 * 60秒 * 1000ミリ秒 = 1日のミリ秒数
  const oneDayMilliseconds = 24 * 60 * 60 * 1000;

  // 2つの日付の差を計算し、整数で返す
  const differenceInMilliseconds = newDate2 - newDate1;
  const differenceInDays = Math.floor(differenceInMilliseconds / oneDayMilliseconds);

  return differenceInDays;
};

export default {
  data() {
    return {
      ...validation,
    }
  },
  methods: {
    confirmSignIn,
    isEmpty,
    isNumber,
    isBeforeDate,
    isArrayContainElem,
    isStringContainString,
    isDateTimeEqualDate,
    isDateAfterFromDate,
    isDateAfterToDate,
    isDateTimeInRange,
    getMinMaxDates,
    convertFullwidthKatakanaToHalfwidth,
    escapeForCSV,
    convertRFC3339ToDateTime,
    convertYYYYMMDDtoYoubi,
    convertYYYYMMDDtoMD,
    convertYYYYMMDDtoYYYYMD,
    convertYYYYMMDDHHMMSStoYYYYMMDD,
    convertDateToYYYYMMDD,
    convertDateToYYYYMMDDhhmmss,
    createUniqueStr,
    getToday,
    calcAddDate,
    calcSubDate,
    calcDiffDate,
  },
};