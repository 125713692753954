import AWS from "aws-sdk";
AWS.config.update({
  region: import.meta.env.VITE_COGNITO_REGION,
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY
});
const cognito = new AWS.CognitoIdentityServiceProvider();

export const listUsersGroups = async (params={}) => {
  let users = [];
  let paginationToken = null;
  do {
    const response = await new Promise((resolve, reject) => {
      cognito.listUsers(
        {
          UserPoolId: import.meta.env.VITE_POOL_ID,
          ...params
        },
        (err, data) => {
          (err) ? reject(err) : resolve(data);
        }
      );
    });
    users = users.concat(response.Users);
    paginationToken = response.PaginationToken;
  } while (paginationToken);

  for (let i = 0; i < users.length; i++) {
    const response = await new Promise((resolve, reject) => {
      cognito.adminListGroupsForUser(
        {
          UserPoolId: import.meta.env.VITE_POOL_ID,
          Username: users[i].Username
        },
        (err, data) => {
          (err) ? reject(err) : resolve(data);
        }
      );
    });
    users[i].Groups = response.Groups;
  }
  return users;
};

export const listUsers = async (params={}) => {
  let users = [];
  let paginationToken = null;
  do {
    const response = await new Promise((resolve, reject) => {
      cognito.listUsers(
        {
          UserPoolId: import.meta.env.VITE_POOL_ID,
          ...params
        },
        (err, data) => {
          (err) ? reject(err) : resolve(data);
        }
      );
    });
    users = users.concat(response.Users);
    paginationToken = response.PaginationToken;
  } while (paginationToken);
  return users;
};

export const listGroups = async (params={}) => {
  let groups = [];
  let paginationToken = null;
  do {
    const response = await new Promise((resolve, reject) => {
      cognito.listGroups(
        {
          UserPoolId: import.meta.env.VITE_POOL_ID,
          ...params
        },
        (err, data) => {
          (err) ? reject(err) : resolve(data);
        }
      );
    });
    groups = groups.concat(response.Groups);
    paginationToken = response.PaginationToken;
  } while (paginationToken);
  return groups;
};

export const getUserByEmail = async (email) => {
  const params = {
    AttributesToGet: [
      'email'
    ],
    Filter: `email = \"${email}\"`
  }
  const users = await listUsersGroups(params);
  if (!users || users.length < 1) {
    // ユーザーが見つからない
    return null;
  } else {
    return users[0];
  }
};

export const createUser = async (user, password, email) => {
  console.log(user, password, email)
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user,
    TemporaryPassword: password,
    UserAttributes: [
      {
        Name: 'email',
        Value: email
      },
      {
        Name: 'email_verified',
        Value: 'true'
      }
    ]
  };

  return cognito.adminCreateUser(params).promise();
};

export const addUserToGroup = async (user, group) => {
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user,
    GroupName: group
  };

  return cognito.adminAddUserToGroup(params).promise();
};

export const removeUserFromGroup = async (user, group) => {
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user,
    GroupName: group
  };

  return cognito.adminRemoveUserFromGroup(params).promise();
};

export const enableUser = async (user) => {
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user
  };

  return cognito.adminEnableUser(params).promise();
};

export const disableUser = async (user) => {
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user
  };

  return cognito.adminDisableUser(params).promise();
};

export const deleteUser = async (user) => {
  const params = {
    UserPoolId: import.meta.env.VITE_POOL_ID,
    Username: user
  };

  return cognito.adminDeleteUser(params).promise();
};
