<script>
import { defineComponent } from "vue";
import OpeningMailSetting from "./MailSetting/index.vue";
import ClosingMailSetting from "./MailSetting/index.vue";
import OpeningReserveMailSetting from "./MailSetting/index.vue";
import ClosingReserveMailSetting from "./MailSetting/index.vue";
export default defineComponent({
  components: {
    OpeningMailSetting,
    ClosingMailSetting,
    OpeningReserveMailSetting,
    ClosingReserveMailSetting
  },
  data() {
    return {
      tab: "opening-mail-setting"
    }
  }
})
</script>

<template>
  <div>
    <v-tabs v-model="tab" grow color="var(--app-color)">
      <v-tabs-slider/>
      <v-tab href="#opening-mail-setting">
        <span>開栓受付完了メール設定</span>
      </v-tab>
      <v-tab href="#closing-mail-setting">
        <span>閉栓受付完了メール設定</span>
      </v-tab>
      <v-tab href="#opening-reserve-mail-setting">
        <span>開栓予約変更メール設定</span>
      </v-tab>
      <v-tab href="closing-#reserve-mail-setting">
        <span>閉栓予約変更メール設定</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="opening-mail-setting">
        <OpeningMailSetting settingType="open_faucet"/>
      </v-tab-item>
      <v-tab-item value="closing-mail-setting">
        <ClosingMailSetting settingType="close_faucet"/>
      </v-tab-item>
      <v-tab-item value="opening-reserve-mail-setting">
        <OpeningReserveMailSetting settingType="open_reserve"/>
      </v-tab-item>
      <v-tab-item value="closing-reserve-mail-setting">
        <ClosingReserveMailSetting settingType="close_reserve"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
