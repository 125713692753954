import Vue from "vue";
import Vuex from "vuex";
import calendar from "@/store/modules/calendar";
import {
  SET_SIGN_IN_USER_SESSION,
  SET_ERROR_LOGIN,
  SET_ERROR_NEW_PASSWORD,
  SET_ERROR_SEND_VERIFY_CODE,
  SET_ERROR_RESET_PASSWORD,
  SET_IS_LOADING_LOGIN,
  SET_IS_LOADING_NEW_PASSWORD,
  SET_IS_LOADING_SEND_VERIFY_CODE,
  SET_IS_LOADING_RESET_PASSWORD,
  SET_IS_LOADING_LOGOUT,
  SET_APPLICATIONS,
  ADD_APPLICATIONS,
  SET_APPLICATION_SEARCH,
  SET_IS_LOADING_GET_APPLICATIONS,
  SET_IS_LOADING_UPDATE_APPLICATION,
  SET_IS_LOADING_VALIDATE_APPLICATIONS,
  SET_IS_LOADING_INVALIDATE_APPLICATIONS,
  SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS,
  SET_COGNITO_USERS,
  SET_USER_SERCH,
  SET_IS_LOADING_GET_USERS,
  SET_IS_LOADING_CREATE_USER,
  SET_IS_LOADING_ENABLE_USER,
  SET_IS_LOADING_DISABLE_USER,
  SET_IS_LOADING_DELETE_USER,
  SET_IS_LOADING_UPDATE_USERS_GROUP,
  SET_COGNITO_USER_GROUPS,
  SET_IS_LOADING_GET_USER_GROUPS,
  SET_IS_LOADING_GET_RESERVABLE_DATA,
  SET_ACCEPTABLE_RANGE_SETTING,
  SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING,
  SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING,
  SET_MAIL_SETTING,
  SET_IS_LOADING_GET_MAIL_SETTING,
  SET_IS_LOADING_UPDATE_MAIL_SETTING,
  SET_LINK_SETTING,
  SET_IS_LOADING_GET_LINK_SETTING,
  SET_IS_LOADING_PUT_LINK_SETTING,
} from "./mutation-types";
import {
  LOGIN,
  NEW_PASSWORD,
  SEND_VERIFY_CODE,
  RESET_PASSWORD,
  LOGOUT,
  GET_APPLICATIONS,
  GET_APPLICATIONS_BY_APPLICATION_IDS,
  UPDATE_APPLICATION,
  VALIDATE_APPLICATIONS,
  INVALIDATE_APPLICATIONS,
  CHANGE_DELETING_STATE_APPLICATIONS,
  UPDATE_DOWNLOAD_AT_APPLICATIONS,
  GET_USERS,
  CREATE_USER,
  ENABLE_USER,
  DISABLE_USER,
  DELETE_USER,
  UPDATE_USERS_GROUP,
  GET_USER_GROUPS,
  GET_RESERVABLE_DATA,
  GET_ACCEPTABLE_RANGE_SETTING,
  PUT_ACCEPTABLE_RANGE_SETTING,
  GET_MAIL_SETTING,
  UPDATE_MAIL_SETTING,
  GET_LINK_SETTING,
  PUT_LINK_SETTING,
  EDIT_OPEN_FAUCET,
  EDIT_CLOSE_FAUCET,
  CANCEL_CLOSE_FAUCET,
  CANCEL_OPEN_FAUCET,
} from "./action-types";
import {
  isEmpty,
} from "@/mixins/index.js";
import {
  isOpenFaucet,
  isCloseFaucet,
  applicationSearch,
} from "@/mixins/application.js";
import {
  convertCognitoToUser,
  convertCognitoToGroup,
  groupSort,
} from "@/mixins/user.js";
import {
  login,
  logout,
  setNewPassword,
  forgotPassword,
  confirmForgotPassword,
} from "@/mixins/amplify-auth.js";
import {
  listUsersGroups,
  listGroups,
  getUserByEmail,
  createUser,
  addUserToGroup,
  removeUserFromGroup,
  enableUser,
  disableUser,
  deleteUser,
} from "@/mixins/aws-client";

Vue.use(Vuex);

// Vuexインスタンス生成
export default new Vuex.Store({
  // state: Vuexで管理する状態を定義
  state: {
    // ログイン
    signInUserSession: null,
    errorLogin: null,
    errorNewPassword: null,
    errorSendVerifyCode: null,
    errorResetPassword: null,
    isLoadingLogin: false,
    isLoadingNewPassword: false,
    isLoadingSendVerifyCode: false,
    isLoadingResetPassword: false,
    isLoadingLogout: false,

    // お申し込み一覧
    applications: [],
    applicationSearch: {
      status: [],
      gas_kind: [],
      application_type: [],
      okigas_electricity: [],
      is_set_discount: [],
      email_send_state: [],
      reception_no: {
        min: "",
        max: ""
      },
      applicant_last_name: "",
      applicant_first_name: "",
      last_name: "",
      first_name: "",
      last_name_kana: "",
      first_name_kana: "",
      created_at: "",
      created_at_start: "",
      created_at_end: "",
      preferred_date: "",
      preferred_date_start: "",
      preferred_date_end: "",
      from_updated_at: "",
      to_updated_at: "",
      from_download_at: "",
      to_download_at: "",
      charge_number: ""
    },

    // 受付設定(お申し込み詳細で使用)
    isLoadingGetReservableData: false,

    // 受付可能範囲設定
    acceptableRangeSettingOpenFaucet: null,
    acceptableRangeSettingCloseFaucet: null,
    isLoadingGetAcceptableRangeSetting: false,
    isLoadingPutAcceptableRangeSetting: false,

    // メール設定
    mailSetting: [],
    isLoadingGetMailSetting: false,
    isLoadingUpdateMailSetting: false,

    // リンク設定
    linkSetting: [],
    isLoadingGetApplications: false,
    isLoadingUpdateApplication: false,
    isLoadingValidateApplications: false,
    isLoadingInvalidateApplications: false,
    isLoadingUpdateDownloadAtApplications: false,
    isLoadingGetLinkSetting: false,
    isLoadingPutLinkSetting: false,

    // ユーザー設定
    cognitoUsers: [],
    userSearch: {
      searchAttribute: "",
      searchValue: ""
    },
    isLoadingGetUsers: false,
    isLoadingCreateUser: false,
    errorCreateUser: null,
    isLoadingEnableUser: false,
    isLoadingDisableUser: false,
    isLoadingDeleteUser: false,
    isLoadingUpdateUsersGroup: false,
    cognitoUserGroups: [],
    isLoadingGetUserGroups: false,
  },

  // getters : stateの値を取得する処理を定義
  //   引数のstateは上で宣言した状態管理オブジェクト
  getters: {
    // ログインユーザー
    signInUser(state) {
      const user = {
        username: "",
        email: "",
        group: ""
      }
      if (state.signInUserSession) {
        user.username = state.signInUserSession.idToken.payload['cognito:username'];
        user.email = state.signInUserSession.idToken.payload['email'];
        user.group = state.signInUserSession.idToken.payload['cognito:groups'][0];
      }

      return user;
    },
    // 申し込み一覧
    validApplications(state) {
      // 検索条件で絞り込み
      const searched = applicationSearch(state.applications, state.applicationSearch);

      // 削除済みでないものを絞り込み
      const validApplications = searched.filter((a) => isEmpty(a.deleted_at));

      // 申し込み日の降順でソート
      validApplications.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      return validApplications;
    },
    // 削除済み申し込み一覧
    invalidApplications(state) {
      // 検索条件で絞り込み
      const searched = applicationSearch(state.applications, state.applicationSearch);

      // 削除済みのものを絞り込み
      const invalidApplications = searched.filter((a) => !isEmpty(a.deleted_at));

      // 申し込み日の降順でソート
      invalidApplications.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      return invalidApplications;
    },
    // 申し込み情報取得/更新ローディング
    isLoadingApplication(state) {
      return (
        state.isLoadingGetApplications
        || state.isLoadingUpdateApplication
        || state.isLoadingValidateApplications
        || state.isLoadingInvalidateApplications
        || state.isLoadingUpdateDownloadAtApplications
        || state.isLoadingGetReservableData
        || state.isLoadingGetAcceptableRangeSetting
      );
    },
    // 受付可能範囲設定を取得
    acceptableRangeSetting: (state) => (applicationType) => {
      if (isOpenFaucet(applicationType)) {
        return state.acceptableRangeSettingOpenFaucet;
      } else if (isCloseFaucet(applicationType)) {
        return state.acceptableRangeSettingCloseFaucet;
      }
    },
    // ユーザー一覧
    users(state) {
      const cognitoUsers = state.cognitoUsers;
      const users = cognitoUsers.map((c) => convertCognitoToUser(c));
      const searched = users.filter((u) => {
        if (isEmpty(state.userSearch.searchAttribute) || isEmpty(state.userSearch.searchValue)) return true;

        let result = false;
        switch (state.userSearch.searchAttribute) {
          case 'userName':
          case 'email':
          case 'status':
            // 文字列検索
            result = u[state.userSearch.searchAttribute].includes(state.userSearch.searchValue);
            break;

          case 'enabled':
            // 真偽値検索
            result = u[state.userSearch.searchAttribute] === state.userSearch.searchValue;
            break;

          case 'groupName':
            // ユーザー権限検索
            result = u.group.groupName === state.userSearch.searchValue;
            break;

          default:
            break;
        }
        return result;
      });
      return searched;
    },
    // 権限一覧
    userGroups(state) {
      const cognitoUserGroups = state.cognitoUserGroups;
      const userGroups = cognitoUserGroups.map((c) => convertCognitoToGroup(c));
      userGroups.sort(groupSort);
      return userGroups;
    },
    // ユーザー情報取得/更新ローディング
    isLoadingUser(state) {
      return (
        state.isLoadingGetUsers
        || state.isLoadingCreateUser
        || state.isLoadingEnableUser
        || state.isLoadingDisableUser
        || state.isLoadingDeleteUser
        || state.isLoadingUpdateUsersGroup
        || state.isLoadingGetUserGroups
      );
    },
  },

  // mutations : stateの値を変更する処理を定義
  //   引数のstateは上で宣言した状態管理オブジェクト
  //   非同期処理に対応していない
  mutations: {
    [SET_SIGN_IN_USER_SESSION](state, signInUserSession) {
      state.signInUserSession = signInUserSession;
    },
    [SET_ERROR_LOGIN](state, errorLogin) {
      state.errorLogin = errorLogin;
    },
    [SET_ERROR_NEW_PASSWORD](state, errorNewPassword) {
      state.errorNewPassword = errorNewPassword;
    },
    [SET_ERROR_SEND_VERIFY_CODE](state, errorSendVerifyCode) {
      state.errorSendVerifyCode = errorSendVerifyCode;
    },
    [SET_ERROR_RESET_PASSWORD](state, errorResetPassword) {
      state.errorResetPassword = errorResetPassword;
    },
    [SET_IS_LOADING_LOGIN](state, isLoadingLogin) {
      state.isLoadingLogin = isLoadingLogin;
    },
    [SET_IS_LOADING_NEW_PASSWORD](state, isLoadingNewPassword) {
      state.isLoadingNewPassword = isLoadingNewPassword;
    },
    [SET_IS_LOADING_SEND_VERIFY_CODE](state, isLoadingSendVerifyCode) {
      state.isLoadingSendVerifyCode = isLoadingSendVerifyCode;
    },
    [SET_IS_LOADING_RESET_PASSWORD](state, isLoadingResetPassword) {
      state.isLoadingResetPassword = isLoadingResetPassword;
    },
    [SET_IS_LOADING_LOGOUT](state, isLoadingLogout) {
      state.isLoadingLogout = isLoadingLogout;
    },
    [SET_APPLICATIONS](state, applications) {
      state.applications = applications;
    },
    [ADD_APPLICATIONS](state, applications) {
      state.applications = state.applications.concat(applications);
    },
    [SET_APPLICATION_SEARCH](state, applicationSearch) {
      state.applicationSearch = applicationSearch;
    },
    [SET_IS_LOADING_GET_APPLICATIONS](state, isLoadingGetApplications) {
      state.isLoadingGetApplications = isLoadingGetApplications;
    },
    [SET_IS_LOADING_UPDATE_APPLICATION](state, isLoadingUpdateApplication) {
      state.isLoadingUpdateApplication = isLoadingUpdateApplication;
    },
    [SET_IS_LOADING_VALIDATE_APPLICATIONS](state, isLoadingValidateApplications) {
      state.isLoadingValidateApplications = isLoadingValidateApplications;
    },
    [SET_IS_LOADING_INVALIDATE_APPLICATIONS](state, isLoadingInvalidateApplications) {
      state.isLoadingInvalidateApplications = isLoadingInvalidateApplications;
    },
    [SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS](state, isLoadingUpdateDownloadAtApplications) {
      state.isLoadingUpdateDownloadAtApplications = isLoadingUpdateDownloadAtApplications;
    },
    [SET_COGNITO_USERS](state, cognitoUsers) {
      state.cognitoUsers = cognitoUsers;
    },
    [SET_USER_SERCH](state, userSearch) {
      state.userSearch = userSearch;
    },
    [SET_IS_LOADING_GET_USERS](state, isLoadingGetUsers) {
      state.isLoadingGetUsers = isLoadingGetUsers;
    },
    [SET_IS_LOADING_CREATE_USER](state, isLoadingCreateUser) {
      state.isLoadingCreateUser = isLoadingCreateUser;
    },
    [SET_IS_LOADING_ENABLE_USER](state, isLoadingEnableUser) {
      state.isLoadingEnableUser = isLoadingEnableUser;
    },
    [SET_IS_LOADING_DISABLE_USER](state, isLoadingDisableUser) {
      state.isLoadingDisableUser = isLoadingDisableUser;
    },
    [SET_IS_LOADING_DELETE_USER](state, isLoadingDeleteUser) {
      state.isLoadingDeleteUser = isLoadingDeleteUser;
    },
    [SET_IS_LOADING_UPDATE_USERS_GROUP](state, isLoadingUpdateUsersGroup) {
      state.isLoadingUpdateUsersGroup = isLoadingUpdateUsersGroup;
    },
    [SET_COGNITO_USER_GROUPS](state, cognitoUserGroups) {
      state.cognitoUserGroups = cognitoUserGroups;
    },
    [SET_IS_LOADING_GET_USER_GROUPS](state, isLoadingGetUserGroups) {
      state.isLoadingGetUserGroups = isLoadingGetUserGroups;
    },
    [SET_IS_LOADING_GET_RESERVABLE_DATA](state, isLoadingGetReservableData) {
      state.isLoadingGetReservableData = isLoadingGetReservableData;
    },
    [SET_ACCEPTABLE_RANGE_SETTING](state, { applicationType, acceptableRangeSetting }) {
      if (isOpenFaucet(applicationType)) {
        state.acceptableRangeSettingOpenFaucet = acceptableRangeSetting;
      } else if (isCloseFaucet(applicationType)) {
        state.acceptableRangeSettingCloseFaucet = acceptableRangeSetting;
      }
    },
    [SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING](state, isLoadingGetAcceptableRangeSetting) {
      state.isLoadingGetAcceptableRangeSetting = isLoadingGetAcceptableRangeSetting;
    },
    [SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING](state, isLoadingPutAcceptableRangeSetting) {
      state.isLoadingPutAcceptableRangeSetting = isLoadingPutAcceptableRangeSetting;
    },
    [SET_MAIL_SETTING](state, mailSetting) {
      state.mailSetting = mailSetting;
    },
    [SET_IS_LOADING_GET_MAIL_SETTING](state, isLoadingGetMailSetting) {
      state.isLoadingGetMailSetting = isLoadingGetMailSetting;
    },
    [SET_IS_LOADING_UPDATE_MAIL_SETTING](state, isLoadingUpdateMailSetting) {
      state.isLoadingUpdateMailSetting = isLoadingUpdateMailSetting;
    },
    [SET_LINK_SETTING](state, linkSetting) {
      state.linkSetting = linkSetting;
    },
    [SET_IS_LOADING_GET_LINK_SETTING](state, isLoadingGetLinkSetting) {
      state.isLoadingGetLinkSetting = isLoadingGetLinkSetting;
    },
    [SET_IS_LOADING_PUT_LINK_SETTING](state, isLoadingPutLinkSetting) {
      state.isLoadingPutLinkSetting = isLoadingPutLinkSetting;
    },
  },

  // actions : mutationを呼び出す処理を定義
  //   非同期も対応している
  actions: {
    // ログイン
    [LOGIN]: async ({ commit }, input) => {
      commit(SET_IS_LOADING_LOGIN, true);
      return await login(input.userName, input.password)
        .then((data) => {
          commit(SET_ERROR_LOGIN, null);
          return data;
        })
        .catch((err) => {
          console.error(err);
          commit(SET_ERROR_LOGIN, err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_LOGIN, false);
        });
    },
    // パスワード更新
    [NEW_PASSWORD]: async ({ commit }, input) => {
      commit(SET_IS_LOADING_NEW_PASSWORD, true);
      return await setNewPassword(input.user, input.password)
        .then((data) => {
          commit(SET_ERROR_NEW_PASSWORD, null);
          return data;
        })
        .catch((err) => {
          console.error(err);
          commit(SET_ERROR_NEW_PASSWORD, err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_NEW_PASSWORD, false);
        });
    },
    // パスワード変更認証コード発行
    [SEND_VERIFY_CODE]: async ({ commit }, input) => {
      commit(SET_IS_LOADING_SEND_VERIFY_CODE, true);

      // Eメールからユーザーを取得
      const user = await getUserByEmail(input.email);
      if (!user) {
        // ユーザーが見つからなかった場合
        commit(SET_ERROR_SEND_VERIFY_CODE, { code: "UserNotFoundException" });
        commit(SET_IS_LOADING_SEND_VERIFY_CODE, false);
        return null;
      }
      return await forgotPassword(user.Username)
        .then((data) => {
          commit(SET_ERROR_SEND_VERIFY_CODE, null);
          return data;
        })
        .catch((err) => {
          console.error(err);
          commit(SET_ERROR_SEND_VERIFY_CODE, err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_SEND_VERIFY_CODE, false);
        });
    },
    // パスワード変更
    [RESET_PASSWORD]: async ({ commit }, input) => {
      commit(SET_IS_LOADING_RESET_PASSWORD, true);

      // Eメールからユーザーを取得
      const user = await getUserByEmail(input.email);
      if (!user) {
        // ユーザーが見つからなかった場合
        commit(SET_ERROR_SEND_VERIFY_CODE, { code: "UserNotFoundException" });
        commit(SET_IS_LOADING_RESET_PASSWORD, false);
        return null;
      }
      return await confirmForgotPassword(user.Username, input.code, input.password)
        .then((data) => {
          commit(SET_ERROR_RESET_PASSWORD, null);
          return data;
        })
        .catch((err) => {
          commit(SET_ERROR_RESET_PASSWORD, err);
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_RESET_PASSWORD, false);
        });
    },
    // ログアウト
    [LOGOUT]: async ({ commit }) => {
      commit(SET_IS_LOADING_LOGOUT, true);
      return await logout()
        .then((data) => {
          return data;
        })
        .catch((err) => {
          console.error(err);
          return err;
        })
        .finally(() => {
          commit(SET_IS_LOADING_LOGOUT, false);
        });
    },
    // 申し込み一覧を取得
    [GET_APPLICATIONS]: async ({ commit, dispatch }, LastEvaluatedKey = null) => {
      commit(SET_IS_LOADING_GET_APPLICATIONS, true);
      const url = "api/okigas_applications";
      return await Vue.prototype.$okigasAxios
        .post(url, { LastEvaluatedKey: LastEvaluatedKey })
        .then((response) => {
          if (response && response.data && response.data?.items) {
            if (LastEvaluatedKey) {
              commit(ADD_APPLICATIONS, response.data.items);
            } else {
              commit(SET_APPLICATIONS, response.data.items);
            }

            if (response.data.LastEvaluatedKey != null && response.data.LastEvaluatedKey != undefined && response.data.LastEvaluatedKey != '') {
              dispatch(GET_APPLICATIONS, response.data.LastEvaluatedKey);
            }
          }
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_GET_APPLICATIONS, false);
        });
    },
    // applicaiton_idのリストに紐づく申し込み一覧を取得
    [GET_APPLICATIONS_BY_APPLICATION_IDS]: async ({ commit }, { application_ids }) => {
      commit(SET_IS_LOADING_GET_APPLICATIONS, true);

      // 結果用
      const applications = [];

      // 配列を指定されたサイズごとに分割する関数
      const createChunks = (array, size) => Array.from({ length: Math.ceil(array.length / size) }, (_, index) => array.slice(index * size, index * size + size));

      // 100件ずつに分けてAPIを叩く(GetBatchItemのアイテム上限100件のため)
      const CHUNK_SIZE = 100;
      const chunks = createChunks(application_ids, CHUNK_SIZE);
      for (const chunk of chunks) {
        // データ量の問題でページネイトされた場合にwhile文で全件取得
        let unprocessedKeys = null;
        do {
          // APIにリクエスト
          const url = "api/okigas_applications_by_application_ids";
          await Vue.prototype.$okigasAxios
            .post(url, {
              application_ids: chunk,
              UnprocessedKeys: unprocessedKeys
            })
            .then(async (response) => {
              if (response && response.data && response.data?.items) {
                applications.push(...response.data.items);
                unprocessedKeys = response.data.UnprocessedKeys;
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } while (unprocessedKeys !== null && unprocessedKeys !== undefined && unprocessedKeys !== '');
      }

      commit(SET_IS_LOADING_GET_APPLICATIONS, false);

      return applications;
    },
    // 申し込みを更新
    [UPDATE_APPLICATION]: async ({ commit, dispatch }, application) => {
      commit(SET_IS_LOADING_UPDATE_APPLICATION, true);
      const url = "api/okigas_application";
      return await Vue.prototype.$okigasAxios
        .post(url, {
          okigas_application: application
        })
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_APPLICATION, false);
        });
    },
    // 申し込みを有効化
    [VALIDATE_APPLICATIONS]: async ({ commit, dispatch }, applications) => {
      commit(SET_IS_LOADING_VALIDATE_APPLICATIONS, true);
      const url = "api/okigas_validate_applications";
      return await Vue.prototype.$okigasAxios
        .post(url, {
          okigas_applications: applications
        })
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_VALIDATE_APPLICATIONS, false);
        });
    },
    // 申し込みを無効化
    [INVALIDATE_APPLICATIONS]: async ({ commit, dispatch }, applications) => {
      commit(SET_IS_LOADING_INVALIDATE_APPLICATIONS, true);
      const url = "api/okigas_invalidate_applications";
      return await Vue.prototype.$okigasAxios
        .post(url, {
          okigas_applications: applications
        })
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_INVALIDATE_APPLICATIONS, false);
        });
    },
    // 申込の有効/無効状態を切り替え
    [CHANGE_DELETING_STATE_APPLICATIONS]: async ({ commit, dispatch, rootState }, {operation_type , application_ids}) => {
      commit(SET_IS_LOADING_INVALIDATE_APPLICATIONS, true);
      
      // logical_delete = 削除 , deleted_reset = 削除取り消し
      const user_name = rootState.signInUserSession.idToken.payload['cognito:username']
      const url = "api/okigas_application/deleting_state/" + operation_type;
      return await Vue.prototype.$okigasAxios
        .put(url, { application_ids : application_ids, user_name : user_name })
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_INVALIDATE_APPLICATIONS, false);
        });
    },
    // 申し込みの最終ダウンロードを更新
    [UPDATE_DOWNLOAD_AT_APPLICATIONS]: async ({ commit, dispatch }, application_ids) => {
      commit(SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS, true);

      // 配列を指定されたサイズごとに分割する関数
      const createChunks = (array, size) => Array.from({ length: Math.ceil(array.length / size) }, (_, index) => array.slice(index * size, index * size + size));

      // 50件ずつに分けてAPIを叩く(ProvisionedThroughputExceededExceptionかGateway Timeoutのいずれかに引っかかるため)
      const CHUNK_SIZE = 50;
      const chunks = createChunks(application_ids, CHUNK_SIZE);
      for (const chunk of chunks) {
        const url = "api/okigas_application/last_download_timestamp_update";
        await Vue.prototype.$okigasAxios
          .post(url, {
            application_ids: chunk
          })
          .then((response) => {
            console.log('申し込みの最終ダウンロードを更新', response);
          })
          .catch((err) => {
            console.error(err);
          });
        }

        // 最新データ取り直し
        dispatch(GET_APPLICATIONS);

        commit(SET_IS_LOADING_UPDATE_DOWNLOAD_AT_APPLICATIONS, false);
      },
    // ユーザー一覧を取得
    [GET_USERS]: async ({ commit }) => {
      commit(SET_IS_LOADING_GET_USERS, true);
      const users = await listUsersGroups();
      commit(SET_COGNITO_USERS, users);
      commit(SET_IS_LOADING_GET_USERS, false);
    },
    // ユーザーを作成
    [CREATE_USER]: async ({ commit, dispatch }, payload) => {
      commit(SET_IS_LOADING_CREATE_USER, true);
      const user = await getUserByEmail(payload.email);
      if (user) {
        // 同じEメールのユーザーが既にいる場合
        commit(SET_IS_LOADING_CREATE_USER, false);
        return "同じメールアドレスのユーザーが既に存在しています。";
      }
      await createUser(payload.userName, payload.password, payload.email);
      await addUserToGroup(payload.userName, payload.group);
      await dispatch(GET_USERS);
      commit(SET_IS_LOADING_CREATE_USER, false);
      return;
    },
    // ユーザーを有効化
    [ENABLE_USER]: async ({ commit, dispatch }, payload) => {
      commit(SET_IS_LOADING_ENABLE_USER, true);
      await enableUser(payload.userName);
      await dispatch(GET_USERS);
      commit(SET_IS_LOADING_ENABLE_USER, false);
    },
    // ユーザーを無効化
    [DISABLE_USER]: async ({ commit, dispatch }, payload) => {
      commit(SET_IS_LOADING_DISABLE_USER, true);
      await disableUser(payload.userName);
      await dispatch(GET_USERS);
      commit(SET_IS_LOADING_DISABLE_USER, false);
    },
    // ユーザーを削除
    [DELETE_USER]: async ({ commit, dispatch }, payload) => {
      commit(SET_IS_LOADING_DELETE_USER, true);
      await deleteUser(payload.userName);
      await dispatch(GET_USERS);
      commit(SET_IS_LOADING_DELETE_USER, false);
    },
    // ユーザーのグループを更新
    [UPDATE_USERS_GROUP]: async ({ commit, dispatch }, payload) => {
      commit(SET_IS_LOADING_UPDATE_USERS_GROUP, true);
      await Promise.all([
        addUserToGroup(payload.userName, payload.newGroup),
        removeUserFromGroup(payload.userName, payload.oldGroup)
      ]);
      await dispatch(GET_USERS);
      commit(SET_IS_LOADING_UPDATE_USERS_GROUP, false);
    },
    // ユーザーグループ一覧を取得
    [GET_USER_GROUPS]: async ({ commit }) => {
      commit(SET_IS_LOADING_GET_USER_GROUPS, true);
      const userGroups = await listGroups();
      commit(SET_COGNITO_USER_GROUPS, userGroups);
      commit(SET_IS_LOADING_GET_USER_GROUPS, false);
    },
    // 受付設定を取得
    [GET_RESERVABLE_DATA]: async ({ commit }, { applicationType, startDate, endDate }) => {
      commit(SET_IS_LOADING_GET_RESERVABLE_DATA, true);
      const url = "api/okigas_reception_setting";
      const headers = {
        'content-type': 'application/json'
      };
      const params = {
        application_type: applicationType,
        start_date: startDate,
        end_date: endDate
      };
      return await Vue.prototype.$okigasAxios
        .get(url, { params: params }, { headers: headers })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_GET_RESERVABLE_DATA, false);
        });
    },
    // 受付可能範囲設定を返す
    [GET_ACCEPTABLE_RANGE_SETTING]: async ({ commit }, { applicationType, minDate }) => {
      commit(SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING, true);
      const url = "api/okigas_acceptable_range_setting";
      const headers = {
        'content-type': 'application/json'
      };
      const params = {
        application_type: applicationType,
        min_date: minDate
      };
      return await Vue.prototype.$okigasAxios
        .get(url, { params: params }, { headers: headers })
        .then((response) => {
          let payload = {
            applicationType: applicationType,
            acceptableRangeSetting: response.data.okigas_acceptable_range_setting
          };
          commit(SET_ACCEPTABLE_RANGE_SETTING, payload);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_GET_ACCEPTABLE_RANGE_SETTING, false);
        });
    },
    // 受付可能範囲設定を更新する
    [PUT_ACCEPTABLE_RANGE_SETTING]: async ({ commit, dispatch }, { applicationType, acceptableRangeSetting, minDate }) => {
      commit(SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING, true);
      const url = "api/okigas_acceptable_range_setting/" + applicationType;
      return await Vue.prototype.$okigasAxios
        .put(url, {
          okigas_acceptable_range_setting: acceptableRangeSetting
        })
        .then((response) => {
          let payload = {
            applicationType: applicationType,
            minDate: minDate
          };
          dispatch(GET_ACCEPTABLE_RANGE_SETTING, payload);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() =>{
          commit(SET_IS_LOADING_PUT_ACCEPTABLE_RANGE_SETTING, false);
        });
    },
    // 受付設定のメール設定を取得
    [GET_MAIL_SETTING]: async ({ commit }) => {
      commit(SET_IS_LOADING_GET_MAIL_SETTING, true);
      const url = "api/okigas_mail_setting";
      return await Vue.prototype.$okigasAxios
        .get(url)
        .then((response) => {
          commit(SET_MAIL_SETTING, response.data);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_GET_MAIL_SETTING, false);
        });
    },
    // 受付設定のメール設定を更新
    [UPDATE_MAIL_SETTING]: async ({ commit, dispatch }, applications) => {
      commit(SET_IS_LOADING_UPDATE_MAIL_SETTING, true);
      const url = "api/okigas_mail_setting/application_type";
      return await Vue.prototype.$okigasAxios
        .post(url, {
          put_mail_setting: applications
        })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_MAIL_SETTING, false);
        })
    },
    // リンク設定情報を返す
    [GET_LINK_SETTING]: async ({ commit }) => {
      commit(SET_IS_LOADING_GET_LINK_SETTING, true);
      const url = "api/okigas_link_setting";
      return await Vue.prototype.$okigasAxios
        .get(url)
        .then((response) => {
          commit(SET_LINK_SETTING, response.data.okigas_link_setting);
          return response;
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          commit(SET_IS_LOADING_GET_LINK_SETTING, false);
        });
    },
    // リンク設定情報を更新する
    [PUT_LINK_SETTING]: async ({ commit, dispatch }, application) => {
      commit(SET_IS_LOADING_PUT_LINK_SETTING, true);
      const url = "api/okigas_link_setting/" + application.applicationType;
      return await Vue.prototype.$okigasAxios
        .put(url, {
          okigas_link_setting: application
        })
        .then((response) => {
          let payload = {
            applicationType: application.applicationType
          };
          dispatch(GET_LINK_SETTING, payload);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_PUT_LINK_SETTING, false);
        })
    },
    // 開栓申し込みを更新する
    [EDIT_OPEN_FAUCET]: async ({ commit, dispatch, rootState }, application) => {
      commit(SET_IS_LOADING_UPDATE_APPLICATION, true);
      const url = "api/okigas_application/open_faucet/" + application.application_id;
      const user_name = rootState.signInUserSession.idToken.payload['cognito:username']
      const data = {
        okigas_application: application,
        user_name: user_name
      }
      return await Vue.prototype.$okigasAxios
        .put(url, data)
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_APPLICATION, false);
        });
    },
    // 閉栓申し込みを更新する
    [EDIT_CLOSE_FAUCET]: async ({ commit, dispatch, rootState }, application) => {
      commit(SET_IS_LOADING_UPDATE_APPLICATION, true);
      const url = "api/okigas_application/close_faucet/" + application.application_id;
      const user_name = rootState.signInUserSession.idToken.payload['cognito:username']
      return await Vue.prototype.$okigasAxios
        .put(url, { okigas_application: application, user_name: user_name })
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_APPLICATION, false);
        });
    },
    // 閉栓申し込みをキャンセルする
    [CANCEL_OPEN_FAUCET]: async ({ commit, dispatch, rootState }, {application_id, status,preferred_date,preferred_time}) => {
      commit(SET_IS_LOADING_UPDATE_APPLICATION, true);
      const url = "api/okigas_application/open_faucet/cancel";
      const user_name = rootState.signInUserSession.idToken.payload['cognito:username']
      return await Vue.prototype.$okigasAxios
      .put(url, { okigas_application : { 
        user_name: user_name,
        application_id : application_id,
        status: status,
        preferred_date: preferred_date,
        preferred_time: preferred_time,
      }})
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_APPLICATION, false);
        });
    },
    // 閉栓申し込みをキャンセルする
    [CANCEL_CLOSE_FAUCET]: async ({ commit, dispatch, rootState }, {application_id, status, gas_last_use_date}) => {
      commit(SET_IS_LOADING_UPDATE_APPLICATION, true);
      const url = "api/okigas_application/close_faucet/cancel";
      const user_name = rootState.signInUserSession.idToken.payload['cognito:username']
      return await Vue.prototype.$okigasAxios
        .put(url, { okigas_application: { 
          user_name: user_name,
          application_id : application_id,
          status: status,
          gas_last_use_date: gas_last_use_date,
        }})
        .then((response) => {
          dispatch(GET_APPLICATIONS);
          return response;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          commit(SET_IS_LOADING_UPDATE_APPLICATION, false);
        });
    },
  },
  modules: {
    calendar,
  },
})