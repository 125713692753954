<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: String,
    options: Array,
    currentStatus: String
  },
  computed: {
    statusOption() {
      if (this.value) {
        return this.options.find(option => option.value === this.value);
      }
    }
  },
});
</script>

<template>
  <v-container>
    <v-row class="justify-start">
      <v-col cols="auto" class="ma-1 pa-0">
        <v-chip
          v-if="statusOption"
          dark
          :color="statusOption.color"
        >
          <v-avatar left>
            <v-icon small color="white">mdi-checkbox-marked-circle</v-icon>
          </v-avatar>
          {{ statusOption.text }}
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>
