<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    value: Boolean,
    settingType: String
  },
  data() {
    return {
      gridCols: {
        button: 6,
        label: 5
      },
      openTemplateList: [
        {label:"受付番号", value:"受付番号", explanation:"受付番号が表示されます", rows:1},
        {label:"ガス種", value:"ガス種", explanation:"選択されたガス種が表示されます", rows:1},
        {label:"申込者", value:"申込者", explanation:"入力された申込者名（姓名）が表示されます", rows:1},
        {label:"申込者連絡先", value:"申込者連絡先", explanation:"入力された申込者連絡先が表示されます", rows:1},
        {label:"契約者", value:"契約者", explanation:"入力された契約者名（姓名）が表示されます", rows:1},
        {label:"契約者連絡先", value:"契約者連絡先", explanation:"入力された契約者連絡先が表示されます", rows:1},
        {label:"住所", value:"住所", explanation:"入力された住所が表示されます", rows:1},
        {label:"建物名", value:"建物名", explanation:"入力された建物名が表示されます", rows:1},
        {label:"部屋番号", value:"部屋番号", explanation:"入力された部屋番号が表示されます", rows:1},
        {label:"ご利用開始希望日", value:"ご利用開始希望日", explanation:"入力されたご利用開始希望日が表示されます", rows:1},
        {label:"ご利用開始希望時間帯", value:"ご利用開始希望時間帯", explanation:"選択されたご利用開始希望時間帯が表示されます", rows:1},
        {label:"保証金預かりの承諾", value:"保証金預かりの承諾", explanation:"入力されたガス種が都市ガス以外の場合、保証金預かりの承諾が表示されます", rows:1},
        {label:"沖縄ガスのでんきの申し込み", value:"沖縄ガスのでんきの申し込み", explanation:"選択された沖縄ガスのでんきの申し込みが表示されます", rows:1},
        {label:"沖縄ガスのでんきの申し込み日", value:"沖縄ガスのでんきの申し込み日", explanation:"選択された沖縄ガスのでんきの申し込み日が表示されます", rows:1}
      ],
      closeTemplateList: [
        {label:"受付番号", value:"受付番号", explanation:"受付番号が表示されます", rows:1},
        {label:"ガス種", value:"ガス種", explanation:"選択されたガス種が表示されます", rows:1},
        {label:"申込者", value:"申込者", explanation:"入力された申込者名（姓名）が表示されます", rows:1},
        {label:"申込者連絡先", value:"申込者連絡先", explanation:"入力された申込者連絡先が表示されます", rows:1},
        {label:"契約者", value:"契約者", explanation:"入力された契約者名（姓名）が表示されます", rows:1},
        {label:"契約者連絡先", value:"契約者連絡先", explanation:"入力された契約者連絡先が表示されます", rows:1},
        {label:"住所", value:"住所", explanation:"入力された住所が表示されます", rows:1},
        {label:"建物名", value:"建物名", explanation:"入力された建物名が表示されます", rows:1},
        {label:"部屋番号", value:"部屋番号", explanation:"入力された部屋番号が表示されます", rows:1},
        {label:"ガス最終利用日", value:"ガス最終利用日", explanation:"選択されたガス最終利用日が表示されます", rows:1},
        {label:"「沖縄ガスのでんき」の最終ご利用日", value:"「沖縄ガスのでんき」の最終ご利用日", explanation:"選択された「沖縄ガスのでんき」の最終ご利用日が表示されます", rows:1},
        {label:"引っ越し先での「沖縄ガスのでんき」\nの申し込み", value:"引っ越し先での「沖縄ガスのでんき」の申し込み", explanation:"選択された引っ越し先での「沖縄ガスのでんき」の申し込みが表示されます", rows:2},
        {label:"引っ越し先での「沖縄ガスのでんき」\nの申し込み日", value:"引っ越し先での「沖縄ガスのでんき」の申し込み日", explanation:"選択された引っ越し先での「沖縄ガスのでんき」の申し込み日が表示されます", rows:2}
      ],

      // 自由入力項目テンプレート文章
      messageTemplate: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (value === false) this.$emit('input', false);
      }
    },
    isOpenTemplate() {
      return (
        this.settingType === "open_faucet" || this.settingType === "open_reserve"
      );
    }
  },
  methods: {
    selectTemplate(template) {
      this.messageTemplate = "${" + template.value + "}";
      this.$emit('select-template',this.messageTemplate);
      this.close();
    },
    close() {
      this.show = false;
    }
  }
});
</script>

<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-container v-if="isOpenTemplate">
        <v-row
          v-for="template in openTemplateList"
          :key="template.id"
        >
          <v-col :cols="gridCols.button">
            <v-btn
              elevation="0"
              block
              outlined
              @click="selectTemplate(template)"
            >
              {{ template.label }}
            </v-btn>
          </v-col>
          <v-col :cols="gridCols.label">
            {{ template.explanation }}
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row
          v-for="template in closeTemplateList"
          :key="template.id"
        >
          <v-col :cols="gridCols.button">
            <v-btn
              elevation="0"
              block
              outlined
              :height="(21 * template.rows) + 15"
              @click="selectTemplate(template)"
            >
              <div style="white-space: pre-wrap;">
                {{ template.label }}
              </div>
            </v-btn>
          </v-col>
          <v-col :cols="gridCols.label">
            {{ template.explanation }}
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-btn
              elevation="0"
              @click="close"
            >
              閉じる
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>