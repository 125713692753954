// 表示制御用
export const CALENDAR_DISPLAY_MODE = {
  HAS_DATA: 0, // データあり
  NON_DATA: 1, // データなし
  NOT_SHOW_TIMEZONE: 2 // 非表示
};

// 時間指定なしコマのコマ情報
export const UNSPECIFIED_COMA = {
  comaId: 1,
  start: "00:00",
  end: "23:59"
}

// 受付可能時間の追加のコマ情報
export const NEW_COMA = {
  start: "00:00",
  end: "00:00",
  slots: [
    3, // 予約枠数(固定値)
    0 // 予約数
  ]
};

// カレンダーCSVのヘッダー
export const COMA_ID_CSV_HEADER_NAME = "id";
export const START_CSV_HEADER_NAME = "start";
export const END_CSV_HEADER_NAME = "end";

// サンプルカレンダーCSVのコマ一覧
export const SAMPLE_CSV_COMA_LIST = [
  { comaId: UNSPECIFIED_COMA.comaId, start: UNSPECIFIED_COMA.start, end: UNSPECIFIED_COMA.end, slots: 0 },
  { comaId: 100, start: "09:00", end: "12:00", slots: 3 },
  { comaId: 200, start: "13:00", end: "15:00", slots: 3 },
  { comaId: 300, start: "15:00", end: "17:00", slots: 3 }
];
