<template>
  <v-app ref="scrolltarget">
    <GlobalHeader v-if="isHeaderShow"/>
    <router-view
      @scrollToTop="scrollToTop"
    />
    <app-dialog/>
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import GlobalHeader from "./components/Header/GlobalHeader.vue";
export default defineComponent({
  components: {
    GlobalHeader
  },
  computed:{
    isHeaderShow() {
      const notShowList = [
        'SignIn',
      ];
      return !notShowList.includes(this.$route.name);
    }
  },
  methods: {
    scrollToTop(time) {
      const element = this.$refs.scrolltarget;
      const scrollDuration = time; // スクロールにかかる時間（ミリ秒）
      const scrollHeight = element.scrollTop; // 現在のスクロール位置
      const scrollStep = Math.PI / (scrollDuration / 15); // スクロールのステップ

      if(time === 0){
        element.scrollTop = 0;
      } else {
        let scrollCount = 0;
        const scrollInterval = setInterval(() => {
          if (element.scrollTop !== 0) {
            scrollCount = scrollCount + 1;
            const newPosition = scrollHeight * Math.cos(scrollCount * scrollStep);
            element.scrollTop = newPosition;
          } else {
            clearInterval(scrollInterval);
          }
        }, 15);
      }
    }
  }
});
</script>
