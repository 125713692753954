<script>
import { defineComponent } from "vue";
import CustomChipGruop from "./CustomChipGruop.vue";
import CustomTextField from "./CustomTextField.vue";
import CustomNumberField from "./CustomNumberField.vue";
import CustomRadioGroup from "./CustomRadioGroup.vue";
import CustomDatePicker from "./CustomDatePicker.vue";
import ApplicationReserveDatePicker from "./ApplicationReserveDatePicker.vue";
import CustomImage from "./CustomImage.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
} from "@/store/mutation-types";
import {
  GET_RESERVABLE_DATA,
  GET_ACCEPTABLE_RANGE_SETTING,
} from "@/store/action-types";
import applicationMixins from "@/mixins/application.js";
import moment from "moment";
export default defineComponent({
  mixins: [applicationMixins],
  components: {
    CustomChipGruop,
    CustomTextField,
    CustomNumberField,
    CustomRadioGroup,
    CustomDatePicker,
    ApplicationReserveDatePicker,
    CustomImage
  },
  props: {
    value: Object,
    currentStatus : String
  },
  data() {
    return {
      // お申し込み詳細
      accRngMinDate: "", // 受付可能範囲の最小値
      accRngMaxDate: "", // 受付可能範囲の最大値
      receptionSetting: null, // 受付設定

      // バリデーション
      requiredOnlyIsUsedOkigasElectricity: (v) => (!this.isUsedOkigasElectricity || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsSuspendElectricity: (v) => (!this.isUsedOkigasElectricity || !this.isSuspendElectricity || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsContinuousUseElectricity: (v) => (!this.isUsedOkigasElectricity || !this.isContinuousUseElectricity || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsNotUsedOkigasElectricity: (v) => (this.isUsedOkigasElectricity || !this.isEmpty(v)) || "必須入力です",
      requiredOnlyIsOkigasElectricityNewUse: (v) => (this.isUsedOkigasElectricity || !this.isOkigasElectricityNewUse || !this.isEmpty(v)) || "必須入力です"
    };
  },
  computed: {
    // ストア
    ...mapState({
    }),
    ...mapGetters({
    }),

    // propsをラップするcomputed
    status: {
      get() {
        return this.value.status;
      },
      set(status) {
        const value = structuredClone(this.value);
        value.status = status;
        this.updateValue(value);
      },
    },
    applicant_last_name: {
      get() {
        return this.value.applicant_last_name;
      },
      set(applicant_last_name) {
        const value = structuredClone(this.value);
        value.applicant_last_name = applicant_last_name;
        this.updateValue(value);
      },
    },
    applicant_first_name: {
      get() {
        return this.value.applicant_first_name;
      },
      set(applicant_first_name) {
        const value = structuredClone(this.value);
        value.applicant_first_name = applicant_first_name;
        this.updateValue(value);
      },
    },
    applicant_tel_number: {
      get() {
        return this.value.applicant_tel_number;
      },
      set(applicant_tel_number) {
        const value = structuredClone(this.value);
        value.applicant_tel_number = applicant_tel_number;
        this.updateValue(value);
      },
    },
    gas_kind: {
      get() {
        return this.value.gas_kind;
      },
      set(gas_kind) {
        const value = structuredClone(this.value);
        value.gas_kind = gas_kind;
        this.updateValue(value);
      },
    },
    last_name: {
      get() {
        return this.value.last_name;
      },
      set(last_name) {
        const value = structuredClone(this.value);
        value.last_name = last_name;
        this.updateValue(value);
      },
    },
    first_name: {
      get() {
        return this.value.first_name;
      },
      set(first_name) {
        const value = structuredClone(this.value);
        value.first_name = first_name;
        this.updateValue(value);
      },
    },
    last_name_kana: {
      get() {
        return this.value.last_name_kana;
      },
      set(last_name_kana) {
        const value = structuredClone(this.value);
        value.last_name_kana = last_name_kana;
        this.updateValue(value);
      },
    },
    first_name_kana: {
      get() {
        return this.value.first_name_kana;
      },
      set(first_name_kana) {
        const value = structuredClone(this.value);
        value.first_name_kana = first_name_kana;
        this.updateValue(value);
      },
    },
    postal_code: {
      get() {
        return this.value.postal_code;
      },
      set(postal_code) {
        const value = structuredClone(this.value);
        value.postal_code = postal_code;
        this.updateValue(value);
      },
    },
    pref: {
      get() {
        return this.value.pref;
      },
      set(pref) {
        const value = structuredClone(this.value);
        value.pref = pref;
        this.updateValue(value);
      },
    },
    municipality: {
      get() {
        return this.value.municipality;
      },
      set(municipality) {
        const value = structuredClone(this.value);
        value.municipality = municipality;
        this.updateValue(value);
      },
    },
    address1: {
      get() {
        return this.value.address1;
      },
      set(address1) {
        const value = structuredClone(this.value);
        value.address1 = address1;
        this.updateValue(value);
      },
    },
    address2: {
      get() {
        return this.value.address2;
      },
      set(address2) {
        const value = structuredClone(this.value);
        value.address2 = address2;
        this.updateValue(value);
      },
    },
    building: {
      get() {
        return this.value.building;
      },
      set(building) {
        const value = structuredClone(this.value);
        value.building = building;
        this.updateValue(value);
      },
    },
    room_number: {
      get() {
        return this.value.room_number;
      },
      set(room_number) {
        const value = structuredClone(this.value);
        value.room_number = room_number;
        this.updateValue(value);
      },
    },
    tel_number: {
      get() {
        return this.value.tel_number;
      },
      set(tel_number) {
        const value = structuredClone(this.value);
        value.tel_number = tel_number;
        this.updateValue(value);
      },
    },
    mail: {
      get() {
        return this.value.mail;
      },
      set(mail) {
        const value = structuredClone(this.value);
        value.mail = mail;
        this.updateValue(value);
      },
    },
    gas_last_use_date: {
      get() {
        return this.value.gas_last_use_date;
      },
      set(gas_last_use_date) {
        const value = structuredClone(this.value);
        value.gas_last_use_date = gas_last_use_date;
        this.updateValue(value);
      },
    },
    charge_number: {
      get() {
        return this.value.charge_number;
      },
      set(charge_number) {
        const value = structuredClone(this.value);
        value.charge_number = charge_number;
        this.updateValue(value);
      },
    },
    moving_postal_code: {
      get() {
        return this.value.moving_postal_code;
      },
      set(moving_postal_code) {
        const value = structuredClone(this.value);
        value.moving_postal_code = moving_postal_code;
        this.updateValue(value);
      },
    },
    moving_pref: {
      get() {
        return this.value.moving_pref;
      },
      set(moving_pref) {
        const value = structuredClone(this.value);
        value.moving_pref = moving_pref;
        this.updateValue(value);
      },
    },
    moving_municipality: {
      get() {
        return this.value.moving_municipality;
      },
      set(moving_municipality) {
        const value = structuredClone(this.value);
        value.moving_municipality = moving_municipality;
        this.updateValue(value);
      },
    },
    moving_address1: {
      get() {
        return this.value.moving_address1;
      },
      set(moving_address1) {
        const value = structuredClone(this.value);
        value.moving_address1 = moving_address1;
        this.updateValue(value);
      },
    },
    moving_address2: {
      get() {
        return this.value.moving_address2;
      },
      set(moving_address2) {
        const value = structuredClone(this.value);
        value.moving_address2 = moving_address2;
        this.updateValue(value);
      },
    },
    moving_building: {
      get() {
        return this.value.moving_building;
      },
      set(moving_building) {
        const value = structuredClone(this.value);
        value.moving_building = moving_building;
        this.updateValue(value);
      },
    },
    moving_room_number: {
      get() {
        return this.value.moving_room_number;
      },
      set(moving_room_number) {
        const value = structuredClone(this.value);
        value.moving_room_number = moving_room_number;
        this.updateValue(value);
      },
    },
    deposit_number: {
      get() {
        return this.value.deposit_number;
      },
      set(deposit_number) {
        const value = structuredClone(this.value);
        value.deposit_number = deposit_number;
        this.updateValue(value);
      },
    },
    account_holder: {
      get() {
        return this.value.account_holder;
      },
      set(account_holder) {
        const value = structuredClone(this.value);
        value.account_holder = account_holder;
        this.updateValue(value);
      },
    },
    bank_type: {
      get() {
        return Number(this.value.bank_type);
      },
      set(bank_type) {
        const value = structuredClone(this.value);
        value.bank_type = bank_type;
        this.updateValue(value);
      },
    },
    isBankDesignation: {
      get() {
        return Number(this.value.isBankDesignation);
      },
      set(isBankDesignation) {
        const value = structuredClone(this.value);
        value.isBankDesignation = Number(isBankDesignation);
        this.updateValue(value);
      },
    },
    account_type: {
      get() {
        return Number(this.value.account_type);
      },
      set(account_type) {
        const value = structuredClone(this.value);
        value.account_type = account_type;
        this.updateValue(value);
      },
    },
    financial_name: {
      get() {
        return this.value.financial_name;
      },
      set(financial_name) {
        const value = structuredClone(this.value);
        value.financial_name = financial_name;
        this.updateValue(value);
      },
    },
    branch_name: {
      get() {
        return this.value.branch_name;
      },
      set(branch_name) {
        const value = structuredClone(this.value);
        value.branch_name = branch_name;
        this.updateValue(value);
      },
    },
    branch_office_number: {
      get() {
        return this.value.branch_office_number;
      },
      set(branch_office_number) {
        const value = structuredClone(this.value);
        value.branch_office_number = branch_office_number;
        this.updateValue(value);
      },
    },
    bank_account_number: {
      get() {
        return this.value.bank_account_number;
      },
      set(bank_account_number) {
        const value = structuredClone(this.value);
        value.bank_account_number = bank_account_number;
        this.updateValue(value);
      },
    },
    yucho_symbol: {
      get() {
        return this.value.yucho_symbol;
      },
      set(yucho_symbol) {
        const value = structuredClone(this.value);
        value.yucho_symbol = yucho_symbol;
        this.updateValue(value);
      },
    },
    yucho_number: {
      get() {
        return this.value.yucho_number;
      },
      set(yucho_number) {
        const value = structuredClone(this.value);
        value.yucho_number = yucho_number;
        this.updateValue(value);
      },
    },
    is_used_okigas_electricity: {
      get() {
        return Number(this.value.is_used_okigas_electricity);
      },
      set(is_used_okigas_electricity) {
        const value = structuredClone(this.value);
        value.is_used_okigas_electricity = is_used_okigas_electricity;
        this.updateValue(value);
      },
    },
    is_suspend_electricity: {
      get() {
        return Number(this.value.is_suspend_electricity);
      },
      set(is_suspend_electricity) {
        const value = structuredClone(this.value);
        value.is_suspend_electricity = is_suspend_electricity;
        this.updateValue(value);
      },
    },
    suspend_date: {
      get() {
        return this.value.suspend_date;
      },
      set(suspend_date) {
        const value = structuredClone(this.value);
        value.suspend_date = suspend_date;
        this.updateValue(value);
      },
    },
    is_continuous_use_electricity: {
      get() {
        return Number(this.value.is_continuous_use_electricity);
      },
      set(is_continuous_use_electricity) {
        const value = structuredClone(this.value);
        value.is_continuous_use_electricity = is_continuous_use_electricity;
        this.updateValue(value);
      },
    },
    electricity_start_date: {
      get() {
        return this.value.electricity_start_date;
      },
      set(electricity_start_date) {
        const value = structuredClone(this.value);
        value.electricity_start_date = electricity_start_date;
        this.updateValue(value);
      },
    },
    last_gas_payment_type: {
      get() {
        return Number(this.value.last_gas_payment_type);
      },
      set(last_gas_payment_type) {
        const value = structuredClone(this.value);
        value.last_gas_payment_type = last_gas_payment_type;
        this.updateValue(value);
      },
    },
    okigas_electricity_new_use: {
      get() {
        return Number(this.value.okigas_electricity_new_use);
      },
      set(okigas_electricity_new_use) {
        const value = structuredClone(this.value);
        value.okigas_electricity_new_use = okigas_electricity_new_use;
        this.updateValue(value);
      },
    },
    new_electricity_start_date: {
      get() {
        return this.value.new_electricity_start_date;
      },
      set(new_electricity_start_date) {
        const value = structuredClone(this.value);
        value.new_electricity_start_date = new_electricity_start_date;
        this.updateValue(value);
      },
    },
    is_electricity_charge_notification: {
      get() {
        return Number(this.value.is_electricity_charge_notification);
      },
      set(is_electricity_charge_notification) {
        const value = structuredClone(this.value);
        value.is_electricity_charge_notification = is_electricity_charge_notification;
        this.updateValue(value);
      },
    },
    agency_number: {
      get() {
        return this.value.agency_number;
      },
      set(agency_number) {
        const value = structuredClone(this.value);
        value.agency_number = agency_number;
        this.updateValue(value);
      },
    },
    qr_parameter: {
      get() {
        return this.value.qr_parameter;
      },
      set(qr_parameter) {
        const value = structuredClone(this.value);
        value.qr_parameter = qr_parameter;
        this.updateValue(value);
      },
    },

    // 閉栓申し込み詳細
    isUsedOkigasElectricity() {
      return this.is_used_okigas_electricity === 0;
    },
    isSuspendElectricity() {
      return this.is_suspend_electricity === 1;
    },
    isContinuousUseElectricity() {
      return this.is_continuous_use_electricity === 1;
    },
    isOkigasElectricityNewUse() {
      return this.okigas_electricity_new_use === 1;
    },
  },
  watch: {
    // v-showで出しわけている要素のバリデーション
    isUsedOkigasElectricity() {
      this.$emit('validate');
    },
    isSuspendElectricity() {
      this.$emit('validate');
    },
    isContinuousUseElectricity() {
      this.$emit('validate');
    },
    isOkigasElectricityNewUse() {
      this.$emit('validate');
    },

  },
  async created() {
    // 受付可能範囲の取得
    const responseGetAcceptableRangeSetting = await this.getAcceptableRangeSetting({
      applicationType: this.openFaucet
    });
    const acceptableRangeSetting = responseGetAcceptableRangeSetting.data.okigas_acceptable_range_setting;

    // 受付可能範囲の計算
    const today = moment().format('YYYY-MM-DD');
    const accRngDay = acceptableRangeSetting.acceptable_range_day_count;
    const accRngMonth = acceptableRangeSetting.acceptable_range_month_count;
    const accRngPrtSet = acceptableRangeSetting.acceptable_range_partial_setting;
    const accRngPrtSetToday = accRngPrtSet.find((obj) => obj.date === today);

    // 今日から{accRngDay}日後が最小値
    this.accRngMinDate = (accRngPrtSetToday)
      ? moment(accRngPrtSetToday.date).add((accRngPrtSetToday.day_count + 1), 'd').format("YYYY-MM-DD")
      : moment(today).add(accRngDay, 'd').format("YYYY-MM-DD");

    // 受付可能な最小値から{accRngMonth}ヶ月後が最大値
    this.accRngMaxDate = moment(this.accRngMinDate).add(accRngMonth, 'M').format("YYYY-MM-DD");

    // 受付可能枠数を取得
    const datesMinMax = this.getMinMaxDates([this.accRngMinDate, this.accRngMaxDate, this.value.gas_last_use_date]); // 受付可能範囲にガスのご利用停止希望日も範囲に含め、受付設定を取得する
    const responseGetReservableData = await this.getReservableData({
      applicationType: this.openFaucet,
      startDate: datesMinMax.minDate,
      endDate: datesMinMax.maxDate
    });
    this.receptionSetting = responseGetReservableData.data.okigas_reception_setting;
  },
  methods: {
    // ストア
    ...mapMutations({
    }),
    ...mapActions({
      getAcceptableRangeSetting: GET_ACCEPTABLE_RANGE_SETTING,
      getReservableData: GET_RESERVABLE_DATA,
    }),

    // 開栓お申し込み詳細
    updateValue(value) {
      this.$emit('input', value);
    },
  }
});
</script>

<template>
  <v-container class="pa-0">
    <v-card outlined class="mx-auto my-4 pa-4">
      <v-row class="align-center">
        <v-col cols="auto">
          <div>受付状態:</div>
        </v-col>
        <v-col cols="auto">
          <CustomChipGruop
            v-model="status"
            :options="STATUS_ENUM"
            :currentStatus="currentStatus"
          />
        </v-col>
      </v-row>
      <v-row class="justify-space-between">
        <v-col cols="auto">
          <div class="header font-weight-bold">
            「お引っ越しによるご利用停止」のお申し込み
          </div>
        </v-col>
        <v-col cols="auto">
          <div v-if="value.cancel_at">キャンセル日：{{ value.cancel_at }}</div>
          <div>申し込み日：{{ value.created_at }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto">
          <div>受付番号</div>
        </v-col>
        <v-col cols="auto">
          <div>{{ value.reception_no }}</div>
        </v-col>
      </v-row>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">1. お申し込み者情報</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="applicant_last_name"
            title="お申し込み者(姓)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9,
              IS_ONLY_CHAR,
            ]"
          />
          <CustomTextField
            v-model="applicant_first_name"
            title="お申し込み者(名)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10,
              IS_ONLY_CHAR,
            ]"
          />
          <CustomTextField
            v-model="applicant_tel_number"
            title="お申し込み者電話番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_10_OR_11
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">2. ご利用お申し込み場所の情報</div>
            </v-col>
          </v-row>
          <CustomRadioGroup
            v-model="gas_kind"
            :options="GAS_KIND_ENUM"
            title="ご利用場所のガス種を選択してください"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <CustomTextField
            v-model="last_name"
            title="お名前(姓)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9
            ]"
          />
          <CustomTextField
            v-model="first_name"
            title="お名前(名)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10
            ]"
          />
          <CustomTextField
            v-model="last_name_kana"
            title="お名前(セイ)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_9,
              IS_FURIGANA,
            ]"
          />
          <CustomTextField
            v-model="first_name_kana"
            title="お名前(メイ)"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_10,
              IS_FURIGANA,
            ]"
          />
          <CustomTextField
            v-model="postal_code"
            title="郵便番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_7
            ]"
          />
          <CustomTextField
            v-model="pref"
            title="都道府県"
            required
            :rules="[
              REQUIRED,
              MIN_LENGTH_1,
              MAX_LENGTH_4
            ]"
          />
          <CustomTextField
            v-model="municipality"
            title="市町村"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="address1"
            title="町字"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="address2"
            title="番地"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="building"
            title="建物名"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="room_number"
            title="部屋番号"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="tel_number"
            title="電話番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_10_OR_11
            ]"
          />
          <CustomTextField
            v-model="mail"
            title="メールアドレス"
            required
            :rules="[
              REQUIRED,
              IS_EMAIL,
              MAX_LENGTH_320
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">3. ガスのご利用停止希望⽇時について</div>
            </v-col>
          </v-row>
          <ApplicationReserveDatePicker
            v-model="gas_last_use_date"
            :min="accRngMinDate"
            :max="accRngMaxDate"
            :receptionSetting="receptionSetting"
            title="ガス最終利用⽇"
            required
            :rules="[
              REQUIRED
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">4. ご利用場所のガス契約情報</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="charge_number"
            title="ガス料金番号"
            :rules="[
              IS_NUMBER_STRING,
              MAX_LENGTH_10
            ]"
          />
          <CustomImage
            :src="value.meter_reading_slip_s3_url"
            title="検針票の画像"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">5. 引っ越し先の情報</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="moving_postal_code"
            title="郵便番号"
            required
            :rules="[
              REQUIRED,
              IS_NUMBER_STRING,
              ONLY_LENGTH_7
            ]"
          />
          <CustomTextField
            v-model="moving_pref"
            title="都道府県"
            required
            :rules="[
              REQUIRED,
              MIN_LENGTH_1,
              MAX_LENGTH_4
            ]"
          />
          <CustomTextField
            v-model="moving_municipality"
            title="市町村"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="moving_address1"
            title="町字"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="moving_address2"
            title="番地"
            required
            :rules="[
              REQUIRED,
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="moving_building"
            title="建物名"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
          <CustomTextField
            v-model="moving_room_number"
            title="部屋番号"
            :rules="[
              MAX_LENGTH_32
            ]"
          />
        </v-container>
      </v-card>

      <v-card class="mt-4" v-if="gas_kind !== 'city_gas'">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">6. LPガスの保証金預かりの返金について</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="deposit_number"
            title="保証金預かりNO"
            :rules="[
              IS_NUMBER_STRING,
              MAX_LENGTH_10
            ]"
          />
          <CustomImage
            :src="value.deposit_s3_url"
            title="保証金預かり証"
          />
          <CustomRadioGroup
            v-model="isBankDesignation"
            :options="IS_BANK_DESIGNATION_ENUM"
            title="保証金の受け取り口座指定について"
            :rules="[]"
          />
          <template v-if="isBankDesignation === 2">
            <CustomTextField
              v-model="account_holder"
              title="口座名義"
              :rules="[
                MAX_LENGTH_32,
                IS_KATAKANA
              ]"
            />
            <CustomRadioGroup
              v-model="bank_type"
              :options="BANK_TYPE_ENUM"
              title=""
              :rules="[]"
            />
          </template>
          <template v-if="bank_type === 0 && isBankDesignation === 2">
            <CustomTextField
              v-model="financial_name"
              required
              title="銀行・金庫・農協の名称"
              :rules="[
                REQUIRED,
                MAX_LENGTH_32
              ]"
            />
            <CustomRadioGroup
              v-model="account_type"
              :options="ACCOUNT_TYPE_ENUM"
              required
              title="口座の種類"
              :rules="[
                REQUIRED,
              ]"
            />
            <CustomTextField
              v-model="branch_name"
              required
              title="支店名"
              :rules="[
                REQUIRED,
                MAX_LENGTH_32
              ]"
            />
            <CustomTextField
              v-model="branch_office_number"
              required
              title="支店番号"
              :rules="[
                REQUIRED,
                IS_NUMBER_STRING,
                ONLY_LENGTH_3
              ]"
            />
            <CustomTextField
              v-model="bank_account_number"
              required
              title="口座番号"
              :rules="[
                REQUIRED,
                IS_NUMBER_STRING,
                ONLY_LENGTH_7
              ]"
            />
          </template>
          <template v-else-if="bank_type === 1 && isBankDesignation == 2">
            <v-row>
              <v-col>
                <CustomTextField
                  v-model="yucho_symbol"
                  required
                  title="記号"
                  :rules="[
                    REQUIRED,
                    IS_NUMBER_STRING,
                    ONLY_LENGTH_5
                  ]"
                />
              </v-col>
              <v-col>
                <CustomTextField
                  v-model="yucho_number"
                  required
                  title="番号"
                  :rules="[
                    REQUIRED,
                    IS_NUMBER_STRING,
                    MAX_LENGTH_8,
                    ZEROUME_LENGTH_8
                  ]"
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">{{gas_kind === 'city_gas' ? '6. ' : '7. '}}沖縄ガスのでんきのご利用の停止およびお引っ越し先のご利用について</div>
            </v-col>
          </v-row>
          <CustomRadioGroup
            v-model="is_used_okigas_electricity"
            :options="IS_USED_OKIGAS_ELECTRICITY_ENUM"
            title="「沖縄ガスのでんき」のご利用について"
            required
            :rules="[
              REQUIRED
            ]"
          />
          <div v-show="isUsedOkigasElectricity">
            <CustomRadioGroup
              v-model="is_suspend_electricity"
              :options="IS_SUSPEND_ELECTRICITY_ENUM"
              title="ガスのご利用を停止する場所で「沖縄ガスのでんき」のご利用を停止しますか？"
              required
              :rules="[
                requiredOnlyIsUsedOkigasElectricity
              ]"
            />
            <div v-show="isSuspendElectricity">
              <CustomDatePicker
                v-model="suspend_date"
                :min="accRngMinDate"
                :max="accRngMaxDate"
                title="でんきのご利用停止日"
                :rules="[
                  requiredOnlyIsSuspendElectricity
                ]"
              />
            </div>
            <CustomRadioGroup
              v-model="is_continuous_use_electricity"
              :options="IS_CONTINUOUS_USE_ELECTRICITY_ENUM"
              title="引っ越し先で、「沖縄ガスのでんき」を継続利用を申し込みますか？"
              required
              :rules="[
                requiredOnlyIsUsedOkigasElectricity
              ]"
            />
            <div v-show="isContinuousUseElectricity">
              <CustomDatePicker
                v-model="electricity_start_date"
                :min="accRngMinDate"
                :max="accRngMaxDate"
                title="でんきのご利用開始日"
                :rules="[
                  requiredOnlyIsContinuousUseElectricity
                ]"
              />
            </div>
          </div>
          <div v-show="!isUsedOkigasElectricity">
            <CustomRadioGroup
              v-model="okigas_electricity_new_use"
              :options="OKIGAS_ELECTRICITY_NEW_USE_ENUM"
              title="引っ越し先で、「沖縄ガスのでんき」を新規で申し込みますか？"
              required
              :rules="[
                requiredOnlyIsNotUsedOkigasElectricity
              ]"
            />
            <div v-show="isOkigasElectricityNewUse">
              <CustomDatePicker
                v-model="new_electricity_start_date"
                :min="accRngMinDate"
                :max="accRngMaxDate"
                title="でんきのご利用開始日"
                :rules="[
                  requiredOnlyIsOkigasElectricityNewUse
                ]"
              />
              <CustomRadioGroup
                v-model="is_electricity_charge_notification"
                :options="IS_ELECTRICITY_CHARGE_NOTIFICATION_ENUM"
                title="「でんき料金のお知らせ」の郵送の要・不要"
                required
                :rules="[
                  requiredOnlyIsOkigasElectricityNewUse
                ]"
              />
            </div>
          </div>
        </v-container>
      </v-card>

      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-col>
              <div class="font-weight-bold">{{gas_kind === 'city_gas' ? '7. ' : '8. '}}その他（代理店記入）</div>
            </v-col>
          </v-row>
          <CustomTextField
            v-model="agency_number"
            title="代理店番号"
            :rules="[
              MAX_LENGTH_20_NO_ERR_MSG
            ]"
          />
          <CustomTextField
            v-model="qr_parameter"
            title="QRコード"
            :rules="[]"
          />
        </v-container>
      </v-card>
    </v-card>
  </v-container>
</template>
